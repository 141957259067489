import React, { useEffect, useState } from "react";
import ItemFormSuggestionTable from "./ItemFormSuggestionTable";
import { Popover } from "@mui/material";

const ItemFormSuggestionPickerBase = ({
  anchorEl,
  selectSuggestionFn,
  instrumentSuggestions,
  meta,
  onOpen,
  PopoverStyle = {},
  shouldShow = true,
}) => {
  const [open, setOpen] = useState(false);
  const [currentAnchorEl, setCurrentAnchorEl] = useState(null);

  useEffect(() => {
    if (anchorEl && currentAnchorEl !== anchorEl) {
      setCurrentAnchorEl(anchorEl);
      setOpen(true);
    }
    if (!anchorEl && currentAnchorEl && !open) {
      setCurrentAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  useEffect(() => {
    if (instrumentSuggestions?.length >= 1 && shouldShow) {
      setOpen(true);
    }
  }, [instrumentSuggestions, shouldShow]);

  useEffect(() => {
    onOpen?.(open);
  }, [open, onOpen]);

  const closePopover = () => {
    setOpen(false);
  };

  if (instrumentSuggestions?.length < 1) {
    return null;
  }

  return (
    <Popover
      data-testid="suggestion-picker-popover"
      open={open}
      onClose={closePopover}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          ...PopoverStyle,
        },
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <ItemFormSuggestionTable
        meta={meta}
        instrumentSuggestions={instrumentSuggestions}
        onSelect={(instrument) => {
          selectSuggestionFn(instrument);
          closePopover();
        }}
      />
    </Popover>
  );
};

export default ItemFormSuggestionPickerBase;
