import React from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { OwcButton } from "@one/react";

const DialogActionsRow = ({ handleClose, onCancel, state, loading, onSave }) => {
  const { trackEvent } = useMatomo();

  const handlerOnEditBooking = () => {
    // Track click on button
    trackEvent({ category: "Edited Bookings", action: "click-event" });
    onSave({ ...state });
    handleClose();
  };

  return (
    <div style={{ display: "flex", marginLeft: "auto" }}>
      <OwcButton
        variant="secondary"
        onClick={() => {
          handleClose();
          if (onCancel) {
            onCancel();
          }
        }}
        data-testid="booking-calendar-edit-modal-cancel-button"
        style={{ marginRight: 32 }}
      >
        Cancel
      </OwcButton>
      <OwcButton
        variant="primary"
        data-testid="booking-calendar-edit-modal-save-button"
        disabled={!state.description || loading}
        onClick={() => {
          handlerOnEditBooking();
        }}
      >
        Save booking
      </OwcButton>
    </div>
  );
};
export default DialogActionsRow;
