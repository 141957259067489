import styled, { css } from "styled-components";
import { useContext } from "react";
import { ReservationPopoverContext } from "../../../booking-calendars/reservation-popover/context";
import { parse, setHours } from "date-fns";
import { CALENDAR_DAY_VIEW_END_HOUR, CALENDAR_DAY_VIEW_START_HOUR } from "../../../../constants";
import { changeDateFormat } from "../../../../utils/helpers/text";

const allDayStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const TitleForEvents = styled.div`
  text-transform: capitalize;
  font-weight: 500;
`;
const EventStyled = styled.div`
  height: 100%;
  ${(props) => props.allDay && allDayStyles}
`;

const CalendarEventComponent = ({ event, instrument }) => {
  const { handleOpen, setEventID, setHideFooter } = useContext(ReservationPopoverContext);
  const dateFrom = setHours(parse(event.resource?.date, "yyyy-MM-dd", new Date()), CALENDAR_DAY_VIEW_START_HOUR);
  const dateTo = setHours(parse(event.resource?.date, "yyyy-MM-dd", new Date()), CALENDAR_DAY_VIEW_END_HOUR);
  return (
    <>
      <EventStyled
        data-testid="calendar-event"
        allDay={event.allDay}
        id={event.resource?.id}
        onClick={(domEvent) => {
          setEventID(event.resource?.id);
          setHideFooter(true);
          handleOpen(
            domEvent,
            {
              isPending: false,
              dateFrom: event.allDay ? dateFrom : event.start,
              dateTo: event.allDay ? dateTo : event.end,
              availability: event.resource?.availability,
              description: event?.description,
              project: event?.project,
              instrument: instrument,
              reservedForEmail: event.resource?.reservedForEmail ?? "",
            },
            []
          );
        }}
      >
        {!event.allDay && (
          <>
            {changeDateFormat(changeDateFormat(event.start, "ISO"), "h:mm")} -{" "}
            {changeDateFormat(changeDateFormat(event.end, "ISO"), "h:mm")}
          </>
        )}
        {event.allDay && (
          <>
            All day <br />
          </>
        )}
        <TitleForEvents> {event.resource?.availability}</TitleForEvents>
      </EventStyled>
    </>
  );
};

export default CalendarEventComponent;
