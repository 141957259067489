import { GroupAssign, Equipment } from "../../booking-table/CustomCells";
import Actions from "./Actions";
import DATA_LABEL_TABLE from "../../../../utils/constants/dataLabelTable";

export const GroupTableMeta = {
  fields: {
    equipmentModel: {
      component: Equipment,
      text: DATA_LABEL_TABLE.ADMIN_ASSIGN_GROUP_TABLE.shortIdentifier,
      headProps: {
        style: {
          flex: "0 1 30%",
        },
      },
      cellProps: {
        style: {
          flex: "0 1 30%",
        },
      },
    },

    users: {
      component: GroupAssign,
      text: DATA_LABEL_TABLE.ADMIN_ASSIGN_GROUP_TABLE.users,
      headProps: {
        style: {
          flex: "0 1 40%",
        },
      },
      cellProps: {
        style: {
          flex: "0 1 40%",
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: 500,
          whiteSpace: "nowrap",
          paddingRight: 15,
        },
      },
    },
    groupName: {
      text: DATA_LABEL_TABLE.ADMIN_ASSIGN_GROUP_TABLE.booking_group,
      headProps: {
        style: {
          flex: "0 1 20%",
        },
      },
      cellProps: {
        style: {
          flex: "0 1 20%",
        },
      },
    },
    action: {
      component: Actions,
      headProps: {
        style: {
          flex: "0 1 10%",
        },
      },
      cellProps: {
        style: {
          flex: "0 1 10%",
        },
      },
    },
  },
};

export default GroupTableMeta;
