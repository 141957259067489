import styled from "styled-components";
import CalendarTypeWrapper from "../../../booking-calendars/calendar-type-context/CalendarTypeWrapper";
import CalendarForDetailedInfo from "./CalendarForDetailedInfo";
import TopBarForDetailedInfoCalendar from "./TopBarForDetailedInfoCalendar";

const CalendarSectionForDetailedInfoStyled = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;

const CalendarSectionForDetailedInfo = ({ instrument }) => {
  return (
    <CalendarTypeWrapper defaultType="month">
      <CalendarSectionForDetailedInfoStyled data-testid="detailed-info-for-instrument-calendar-section">
        <TopBarForDetailedInfoCalendar instrument={instrument} />
        <CalendarForDetailedInfo instrument={instrument} />
      </CalendarSectionForDetailedInfoStyled>
    </CalendarTypeWrapper>
  );
};

export default CalendarSectionForDetailedInfo;
