import { endOfDay, endOfWeek, startOfDay, startOfWeek, sub } from "date-fns";
import { useState } from "react";
import { CalendarTypeContext } from "./context";
import { GRID_VIEW } from "../../../constants";

const CalendarTypeWrapper = ({ children, defaultType = "week" }) => {
  const [type, setType] = useState(defaultType);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(GRID_VIEW);
  const [dateRangePeriod, setDateRangePeriod] = useState([new Date(), new Date()]);
  const [myBookingFromDate, setMyBookingFromDate] = useState(
    startOfWeek(date, {
      weekStartsOn: 1,
    })
  );
  const [myBookingToDate, setMyBookingToDate] = useState(
    sub(
      endOfWeek(date, {
        weekStartsOn: 1,
      }),
      {
        days: 2,
      }
    )
  );
  const [isReverseOrder, setIsReverseOrder] = useState(false);

  const start = (() => {
    switch (type) {
      case "custom":
        return startOfDay(myBookingFromDate);
      case "7week":
      case "week":
        return startOfWeek(date, {
          weekStartsOn: 1,
        });
      case "month":
      case "day":
      default:
        return startOfDay(date);
    }
  })();
  const end = (() => {
    switch (type) {
      case "custom":
        return endOfDay(myBookingToDate);
      case "week":
        return sub(
          endOfWeek(date, {
            weekStartsOn: 1,
          }),
          {
            days: 2,
          }
        );
      case "7week":
        return endOfWeek(date, {
          weekStartsOn: 1,
        });
      case "month":
      case "day":
      default:
        return endOfDay(date);
    }
  })();

  return (
    <CalendarTypeContext.Provider
      value={{
        type,
        setType,
        date,
        setDate,
        start,
        end,
        view,
        setView,
        myBookingFromDate,
        setMyBookingFromDate,
        myBookingToDate,
        setMyBookingToDate,
        isReverseOrder,
        setIsReverseOrder,
        dateRangePeriod,
        setDateRangePeriod,
      }}
    >
      {children}
    </CalendarTypeContext.Provider>
  );
};

export default CalendarTypeWrapper;
