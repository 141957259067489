import React from "react";
import styled from "styled-components";
import PaginationWrapper from "../../components/shared/pagination/PaginationWrapper";
import { useSelector } from "react-redux";
import SelectItemWrapper from "../../components/shared/SelectItemWrapper";
import SelectItemsContextWrapper from "../../components/shared/SelectItemsContextWrapper";
import CustomFilterWrapper from "../booking-instruments/booking-instruments-custom-filter/CustomFilterWrapper";
import AdminTabs from "./AdminTabs";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const MainViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 100%;
  flex-grow: 1;
`;

export const compareBooking = (x, y) => x?.id === y?.id;
export const MainView = ({ setSubscriptionStatus, subscriptionStatus, setPopupEnableStatus }) => {
  const storeUser = useSelector((store) => store.user);
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Admin Panel",
      href: `${window.location?.origin}/AdminPanel`,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <SelectItemsContextWrapper compareItem={compareBooking}>
      <SelectItemWrapper>
        <PaginationWrapper>
          <CustomFilterWrapper initialValues={{ site: storeUser?.site }}>
            <MainViewStyled>
              <AdminTabs
                setSubscriptionStatus={setSubscriptionStatus}
                subscriptionStatus={subscriptionStatus}
                setPopupEnableStatus={setPopupEnableStatus}
              />
            </MainViewStyled>
          </CustomFilterWrapper>
        </PaginationWrapper>
      </SelectItemWrapper>
    </SelectItemsContextWrapper>
  );
};

export default MainView;
