import SkeletonForTestAndDocs from "../SkeletonForTestAndDocs";
import InstalledTestTable from "./InstalledTestTable";

const InstalledTestsMainPage = ({ instrument }) => {
  return (
    <SkeletonForTestAndDocs
      nameForDataTestid={"installed-tests"}
      shortIdentifier={instrument?.shortIdentifier}
      titleForTable={"Installed tests (assays)"}
    >
      <InstalledTestTable installedTests={instrument?.installedTests || []} />
    </SkeletonForTestAndDocs>
  );
};

export default InstalledTestsMainPage;
