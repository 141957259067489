import BorderAllIcon from "@mui/icons-material/BorderAll";
import BooleanButtons from "../../../components/shared/BooleanButtons";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { GRID_VIEW, LIST_VIEW } from "../../../constants";

const MyBookingViewChanger = ({ view, setView }) => {
  const handleChange = (view) => {
    setView(view);
  };
  return (
    <BooleanButtons
      trueProps={{
        "data-testid": "button-for-my-booking-grid-view",
      }}
      falseProps={{
        "data-testid": "button-for-my-booking-list-view",
      }}
      value={view}
      trueLabel={<FormatListBulletedIcon />}
      falseLabel={<BorderAllIcon />}
      onChange={(isCard) => handleChange(isCard ? LIST_VIEW : GRID_VIEW)}
      color="primary"
    />
  );
};
export default MyBookingViewChanger;
