import * as types from "./actionTypes";

export const loadBookingInstruments = (bookingInstruments) => ({
  type: types.LOAD_BOOKING_INSTRUMENTS,
  payload: bookingInstruments,
});
export const updateSearchDropDownFilter = (payload) => ({
  type: types.UPDATE_SEARCH_DROP_DOWN_FILTER,
  payload: payload,
});
export const loadUnfilteredBookingInstruments = (unfilteredbookingInstruments) => ({
  type: types.LOAD_UNFILTERED_BOOKING_INSTRUMENTS,
  payload: unfilteredbookingInstruments,
});
