import moment from "moment";
import { useContext, useEffect } from "react";
import { CalendarTypeContext } from "../../features/booking-calendars/calendar-type-context/context";
import { CustomFilterContext } from "../../features/booking-instruments/booking-instruments-custom-filter/context";

export const useUpdateBookingData = () => {
  const { start, end, type, date, myBookingFromDate, myBookingToDate, isReverseOrder, dateRangePeriod } =
    useContext(CalendarTypeContext);
  const { dispatchAction } = useContext(CustomFilterContext);
  useEffect(() => {
    switch (type) {
      case "day":
        dispatchAction({
          type: "setDateRange",
          payload: {
            dateFrom: moment(date).format("YYYY-MM-DD"),
            dateTo: moment(date).format("YYYY-MM-DD"),
          },
        });
        break;
      case "month":
        dispatchAction({
          type: "setDateRange",
          payload: {
            dateFrom: moment(date).startOf("month").format("YYYY-MM-DD"),
            dateTo: moment(date).endOf("month").format("YYYY-MM-DD"),
          },
        });
        break;
      case "custom":
        dispatchAction({
          type: "setDateRange",
          payload: {
            dateFrom: moment(start).format("YYYY-MM-DD"),
            dateTo: moment(end).format("YYYY-MM-DD"),
            sortDirection: isReverseOrder,
          },
        });

        break;
      case "7week":
      case "week":
      default:
        const localStartDate = dateRangePeriod[0].getTime() < start.getTime() ? dateRangePeriod[0] : start;
        const localEndDate = dateRangePeriod[1].getTime() > end.getTime() ? dateRangePeriod[1] : end;
        dispatchAction({
          type: "setDateRange",
          payload: {
            dateFrom: moment(localStartDate).format("YYYY-MM-DD"),
            dateTo: moment(localEndDate).format("YYYY-MM-DD"),
          },
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, date, dateRangePeriod, myBookingFromDate, myBookingToDate, isReverseOrder]);
};
