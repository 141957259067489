import * as types from "./actionTypes";

export const loadRoomList = (list) => ({
  type: types.LOAD_ROOM_LIST,
  payload: list,
});
export const loadBuildingList = (list) => ({
  type: types.LOAD_BUILDING_LIST,
  payload: list,
});

export const loadAddReasonList = (list) => ({
  type: types.LOAD_ADDREASON_LIST,
  payload: list,
});
