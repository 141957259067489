import { CALENDAR_WIDGET } from "../../constants";
const useStyleCss = (initialState) => {
  const { widgetName = "", widgetStyle = "", defaultStyle = "" } = initialState;
  switch (widgetName) {
    case CALENDAR_WIDGET:
      return widgetStyle;

    default:
      return defaultStyle;
  }
};

export default useStyleCss;
