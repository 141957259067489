import React from "react";
import { ConfirmDialog } from "../../../../components/shared";
import { CreateFieldStyled } from "./GroupMainPageStyle";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../../../booking-admin/reasons/helpers";
import { OwcButton, OwcInput } from "@one/react";
import { OwcSelectDropdown, OwcListItem, OwcCheckbox } from "@one/react";

const GroupDialog = ({ open, isEditMode, onCancel, submit, userList, loading }) => {
  const formik = useFormikContext();
  const classes = {};
  const isEdit = isEditMode;
  console.log(formik, "valueStore");
  return (
    <ConfirmDialog
      open={open}
      close={onCancel}
      approveText={isEditMode ? "Update" : "Create"}
      approveColor="primary"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onCancel={onCancel}
      isDivider
      size="md"
      testid={"import-file-dialog"}
      title={isEditMode ? "Edit booking group" : "Create booking group"}
      renderActions={({ cancelText, approveText }) => (
        <div style={{ paddingBottom: 8, display: "flex", marginLeft: "auto" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={onCancel}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={() => {
              submit(formik.values);
            }}
            variant="primary"
            disabled={loading || formik.values?.groupName.trim() === "" || !(formik.isValid && formik.dirty)}
          >
            {approveText}
          </OwcButton>
        </div>
      )}
    >
      <CreateFieldStyled>
        <OwcInput
          data-testid={`text-field-group-name`}
          label="Enter booking group name *"
          className="TextFieldInput"
          size="small"
          style={{ marginRight: "20px" }}
          name="groupName"
          value={formik.values?.groupName}
          onInputChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isEditMode}
          required
          {...commonPropsForInputsWithoutValue(classes, formik, "groupName", "groupName", "text-field", isEdit)}
        >
          {formik.errors["groupName"] && formik.touched["groupName"] === true && (
            <>
              <span slot="error-text">{formik.errors["groupName"]}</span>
            </>
          )}
        </OwcInput>
        <OwcSelectDropdown
          multiple
          label="Select user"
          dataTestid={`users-inputs-users-input`}
          style={{ width: 250 }}
          required={true}
          name="users"
          onSelectChange={(e) => {
            let selected = e.detail.map((v) => userList[v]);
            formik.setFieldValue("users", selected, true);
          }}
        >
          {userList.map((list) => {
            return (
              <OwcListItem
                selected={formik.values?.users === null ? false : (formik.values?.users).includes(list) ? true : false}
                data-testid={"admin-panel-modal-select-run-mode-menu-item-" + list.value}
                key={list.value}
                value={list.value}
              >
                <OwcCheckbox>{list.value}</OwcCheckbox>
              </OwcListItem>
            );
          })}
        </OwcSelectDropdown>
      </CreateFieldStyled>
    </ConfirmDialog>
  );
};

export default GroupDialog;
