import * as yup from "yup";
const getCustomNullable = (schema, changeTo = "") => schema.nullable().transform((value) => value ?? changeTo);
export const groupValidationSchema = yup.object({
  groupName: yup
    .string("Enter group name")
    .min(3, "Atleast three characters is required")
    .required("Group name is required")
    .typeError("Invalid type")
    .nullable(),
  users: yup
    .array(
      yup.object({
        value: getCustomNullable(
          yup.string("Select user ").typeError("Invalid type").required("User is required").nullable()
        ),
      })
    )
    .min(1, "User is required")
    .required("User is required")
    .nullable(),
});
