import { OwcChip } from "@one/react";
import { getBookingCategory } from "./helpFunctions";
import { IconStatus } from "../../constants";

export const BookingTypeChip = ({ item }) => {
  const bookingIcon = IconStatus.find((icon) => icon.text === getBookingCategory(item.type));

  return (
    <OwcChip
      style={{
        color: bookingIcon.color,
        backgroundColor: bookingIcon.backgroundColor,
      }}
      round
    >
      <strong> {getBookingCategory(item.type)}</strong>
    </OwcChip>
  );
};
