import React from "react";
import styled from "styled-components";
import { ReasonContext } from "./context";
import { PaginationContextReason } from "./PaginationContextReason";
import { useContext } from "react";
import ReasonMainView from "./ReasonMainView";
import { OwcButton } from "@one/react";

export const TopBarButtons = styled.div`
  display: flex;
  align-items: center;
`;

const TopBarButtonReason = () => {
  const { setDialogOpen, setIsEditMode } = useContext(ReasonContext);
  const { dispatchAction } = useContext(PaginationContextReason);

  return (
    <TopBarButtons>
      <OwcButton
        variant="primary"
        key="create-reason-button"
        onClick={() => {
          setIsEditMode(false);
          setDialogOpen(true);
          dispatchAction({
            type: "clearAll",
          });
        }}
      >
        Add reason
      </OwcButton>
      <ReasonMainView />
    </TopBarButtons>
  );
};

export default TopBarButtonReason;
