import { useState, useCallback } from "react";
import { intersectionWith, find, reject } from "lodash";

import { SelectItemsContext } from "../../contexts";

export const SelectItemsWrapper = ({ children, initialSelected = [], compareItem = (x, y) => x === y }) => {
  const [selected, setSelected] = useState(initialSelected);

  const onSelect = useCallback((item) => {
    setSelected((prev) =>
      prev.some((i) => compareItem(i, item)) ? prev.filter((i) => !compareItem(i, item)) : [...prev, item]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAllSelect = (data) => {
    setSelected((prev) => {
      const unCheckedRows = data.filter((item) => {
        const selectedItem = find(prev, {
          inventoryId: item.inventoryId,
        });
        return selectedItem === undefined;
      });

      const checkedRows = data.filter((item) =>
        find(prev, {
          inventoryId: item.inventoryId,
        })
      );
      // to check and reatain all the values
      if (checkedRows.length === 0 && unCheckedRows.length === data.length) {
        return [...prev, ...data];
      }
      // uncheck all and retain all the values
      if (unCheckedRows.length === 0 && checkedRows.length === data.length) {
        const previousDatas = prev.filter((item) => {
          const dataTobeRemoved = find(checkedRows, {
            inventoryId: item.inventoryId,
          });
          return dataTobeRemoved === undefined;
        });

        return previousDatas;
      }
      // uncheck all from intermediate state
      if (checkedRows.length > 0 && data.length !== checkedRows.length) {
        const checkedRowsRemoved = prev.filter((item) => {
          const dataTobeRemoved = find(checkedRows, {
            inventoryId: item.inventoryId,
          });
          return dataTobeRemoved === undefined;
        });
        return [...checkedRowsRemoved];
      }

      // check all from intermediate state for less than 30 records
      const checkedRowsRemoved = prev.filter((item) => {
        const dataTobeRemoved = find(checkedRows, {
          inventoryId: item.inventoryId,
        });
        return dataTobeRemoved === undefined;
      });
      return [...checkedRowsRemoved, ...data];
    });
  };

  const isSelected = useCallback(
    (item) => selected.some((i) => compareItem(i, item)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const isAllSelected = useCallback(
    (data) => intersectionWith(data, selected, compareItem).length === data.length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const clearSelections = useCallback(() => {
    setSelected([]);
  }, []);

  const removeFromSelection = useCallback((item) => {
    setSelected((prev) => {
      const updatedArray = reject(prev, { inventoryId: item.inventoryId });
      return [...updatedArray];
    });
  }, []);

  return (
    <SelectItemsContext.Provider
      value={{
        selectedCount: selected.length,
        selected,
        onSelect,
        onAllSelect,
        isAllSelected,
        isSelected,
        clearSelections,
        removeFromSelection,
      }}
    >
      {children}
    </SelectItemsContext.Provider>
  );
};

export default SelectItemsWrapper;
