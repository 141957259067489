import { Auth } from "aws-amplify";
import { saveLastFilter } from "../features/user/saveLastFilter";

export async function signOut(client, user) {
  try {
    try {
      await saveLastFilter(client, user);
    } catch (err) {
      console.log("err saving filter: ", err);
    }
    await Auth.signOut({ global: true });
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}
