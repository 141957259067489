import styled from "styled-components";
import { entryType, GXP_DROP_CHECK } from "../../../../constants";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { replaceNullWithHyphen } from "../../../../utils/helpers/text";
import { NormalInfoRow, TitleInfoRow } from "../../../booking-calendars/describe-task-page/InfoAboutInstrumentRow";

const InfoInstrumentRow = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 19px 16px;
`;
const DetailsColumnTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #333333;
`;

const DetailsColumnForDetailedColumn = ({ equipment }) => {
  const equipmentCol = [
    "equipmentNickName",
    "equipmentModel",
    "location",
    "serialNumber",
    "materialNumber",
    "systemStatus",
    "equipmentId",
    "instrumentGTIN",
    "belongingToGroup",
    "responsiblePerson",
    "configurationBaseline",
    "softwareVersion",
    "qualificationStatus",
  ];
  const clusterCol = [
    "siteName",
    "location",
    "equipmentCategory",
    "equipmentModel",
    "variant",
    "equipmentNickName",
    "responsiblePerson",
    "qualificationStatus",
    "softwareVersion",
    "systemStatus",
  ];
  const column = equipment?.entryType === entryType?.cluster ? clusterCol : equipmentCol;

  const replaceNullWithValue = (field, equipment) => {
    if (GXP_DROP_CHECK.includes(field)) {
      return equipment?.displayQualificationStatus || "-";
    }
  };

  return (
    <div>
      <DetailsColumnTitle data-testid="detailed-info-for-instrument-info-section-details-info-title">
        {equipment?.entryType === entryType?.cluster ? "Cluster" : "Equipment"}
        details
      </DetailsColumnTitle>
      <InfoInstrumentRow data-testid="detailed-info-for-instrument-info-section-details-info-columns">
        {column.map((columnInfo) => (
          <>
            <TitleInfoRow data-testid={`label-for-${DATA_MODEL_TABLE[columnInfo].value}`}>
              {DATA_MODEL_TABLE[columnInfo].value}
            </TitleInfoRow>
            <NormalInfoRow data-testid={`info-for-${DATA_MODEL_TABLE[columnInfo].value}`}>
              <span>
                {columnInfo === "qualificationStatus"
                  ? replaceNullWithValue(columnInfo, equipment)
                  : replaceNullWithHyphen(equipment[columnInfo])}
              </span>
            </NormalInfoRow>
          </>
        ))}
      </InfoInstrumentRow>
    </div>
  );
};

export default DetailsColumnForDetailedColumn;
