import React from "react";
import NoConnectionIcon from "../icons/NoConnectionIcon";
import styles from "./NotFoundScreen.module.scss";
import { OwcButton } from "@one/react/Components/OwcButton"; // other import way
import { OwcTypography } from "@one/react/Components/OwcTypography";

const NoConnectionScreen = ({
  text = "We are having difficulty connecting to the server. Check your connection or try again later.",
  title = "No connection",
  onCallback,
}) => {
  return (
    <div className={styles.ErrorScreen} data-testid="main-page-not-authenticated-with-error-conection">
      <NoConnectionIcon />
      <OwcTypography style={{ color: "inherit" }} element="h2" variant="title2">
        {title}
      </OwcTypography>
      <OwcTypography style={{ color: "inherit" }} element="h5" variant="title5">
        {text}
      </OwcTypography>
      <OwcButton data-testid="main-page-action-button-error-conection" variant="primary" onClick={onCallback}>
        Retry
      </OwcButton>
    </div>
  );
};

export default NoConnectionScreen;
