import React from "react";
import DATA_LABEL_TABLE from "../../utils/constants/dataLabelTable";
import { OwcBreadcrumb, OwcBreadcrumbItem } from "@one/react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BreadcrumbBar = ({ shortIdentifier }) => {
  const history = useHistory();
  return (
    <div style={{ margin: 15 }}>
      <OwcBreadcrumb>
        <OwcBreadcrumbItem onClick={() => history.push("/")}>
          {DATA_LABEL_TABLE.BREADCUMB_EQUIPMENT_LIST_LABEL}
        </OwcBreadcrumbItem>
        <OwcBreadcrumbItem url="/target-url">
          {DATA_LABEL_TABLE.BREADCUMB_LABEL} : {shortIdentifier}
        </OwcBreadcrumbItem>
      </OwcBreadcrumb>
    </div>
  );
};

export default BreadcrumbBar;
