import { emptyFilters } from "../../features/booking-instruments/booking-instruments-filter-context/emptyFilters";

export const emptyLastFilter = {
  query: "",
  filters: emptyFilters,
  sort: {
    reverse: false,
    orderBy: ["equipmentNickName"],
  },
  view: "list",
};

const filterNull = (fliters) => {
  const result = {};

  Object.keys(fliters).forEach((key) => {
    if (Array.isArray(fliters[key])) {
      result[key] = fliters[key].filter((v) => v);
    } else if (fliters[key]) {
      result[key] = fliters[key];
    } else {
      result[key] = "";
    }
  });

  return result;
};

export const stringifyFilter = (lastFilter) =>
  encodeURI(
    JSON.stringify({
      ...lastFilter,
      filters: filterNull(lastFilter.filters),
    })
  );

export const parseFilter = (lastFilterString) => {
  const lastFilter = JSON.parse(decodeURI(lastFilterString));

  return {
    ...lastFilter,
    filters: filterNull(lastFilter.filters),
  };
};
