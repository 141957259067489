import * as types from "./actionTypes";
import initialState from "./initialState";

export default function reducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case types.LOAD_BOOKING_INSTRUMENTS:
      return { ...state, ...payload };
    case types.LOAD_UNFILTERED_BOOKING_INSTRUMENTS:
      return { ...state, ...payload };
    case types.UPDATE_SEARCH_DROP_DOWN_FILTER:
      return {
        ...state,
        searchDropDownFilter: payload,
      };
    default:
      return state;
  }
}
