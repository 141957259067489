import { useContext, useState } from "react";
import ConfirmDialog from "../components/shared/ConfirmDialog";
import { DROP_BOOKING } from "../gql/bookingapi";
import { useSelector } from "react-redux";
import { withApollo } from "react-apollo";
import { PaginationContext } from "../components/shared/pagination/PaginationContext";

const handleCancelFactory = async ({ client, user, reasonValue, force, item, setStatus, dispatchAction }) => {
  try {
    setStatus(true);
    await client.mutate({
      mutation: DROP_BOOKING,
      variables: {
        createdBy: user.user,
        remark: reasonValue,
        force: force,
        bookingId: item,
      },
    });
  } catch (e) {
    setStatus(false);
    dispatchAction({
      type: "clearAll",
    });
  }
};

const DeleteBooking = ({
  client,
  setIsDropBooking,
  setIsDialogOpen,
  isDialogOpen = false,
  force = false,
  item,
  status,
  setStatus,
}) => {
  const [reasonValue, setReasonValue] = useState(null);
  const user = useSelector((store) => store.user);
  const { dispatchAction } = useContext(PaginationContext);

  const handlerOnDropBooking = async () => {
    dispatchAction({
      type: "fetching",
      payload: true,
    });
    handleCancelFactory({
      client,
      user,
      reasonValue,
      force,
      item,
      setStatus,
      dispatchAction,
    });
    setReasonValue("");
  };

  const onReasonChanged = (reason) => {
    setReasonValue(reason);
  };

  const handleOnclose = () => {
    setIsDropBooking(false);
    setIsDialogOpen(false);
    setReasonValue("");
    return true;
  };
  const handlerOnCancelDeleteBooking = () => {
    setIsDropBooking(false);
    setIsDialogOpen(false);
    setReasonValue("");
  };

  return (
    <ConfirmDialog
      approveText="Confirm"
      approveColor="primary"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onApprove={() => handlerOnDropBooking()}
      title="Cancel booking"
      content={`Are you sure to cancel bookings?`}
      showReasonDD={true}
      reasonValue={reasonValue}
      onReasonChanged={onReasonChanged}
      disableBooking={!reasonValue}
      close={handleOnclose}
      open={isDialogOpen}
      onCancel={handlerOnCancelDeleteBooking}
      fullWidth={false}
      maxWidth="sm"
      reasonDropdowmLabel={`Please add reason for cancel booking`}
      loading={status}
    />
  );
};

export default withApollo(DeleteBooking);
