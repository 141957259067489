/**
 * Checks if the current date falls between the given start date and end date.
 * @param {string|Date} startDate - The start date in string or Date format.
 * @param {string|Date} endDate - The end date in string or Date format.
 * @returns {boolean} - True if the current date is between the start and end dates, false otherwise.
 */
export const isCurrentDateBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const now = new Date();

  return start < now && now < end;
};

/**
 * Check if the current date is before a given date.
 *
 * @param {string|number|Date} date - The date to compare with the current date.
 * @returns {boolean} - Returns true if the current date is before the startDate. Otherwise, returns false.
 * @throws {Error} - Throws an error if startDate is not a valid date.
 */
export const isCurrentDateBeforeDate = (date) => {
  return new Date() < new Date(date);
};

/**
 * Checks if a given date is after the current date and time.
 *
 * @param {String|Date} date - The date to compare with the current date and time.
 * @returns {Boolean} - True if the given date is after the current date and time, otherwise false.
 * @throws {Error} - If an error occurs while comparing the dates.
 */
export const isCurrentDateAfterDate = (date) => {
  return new Date() > new Date(date);
};

/**
 * Calculates the number of milliseconds between two dates.
 *
 * @param {Date} dateFrom - The starting date.
 * @param {Date} dateTo - The ending date.
 * @returns {number} The number of milliseconds between the two dates.
 */
export const milliSecondsBetweenDates = (dateFrom, dateTo) => {
  return Math.ceil(Math.abs(Number(dateTo) - Number(dateFrom)));
};
