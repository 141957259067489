import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Query } from "react-apollo";
import { LIST_BOOKING_COMMAND } from "../../../gql/bookingapi/queries";
import { ON_UPDATE_BOOKING_COMMAND } from "../../../gql/bookingapi/subscriptions";
import ConnectSubscription from "../../../components/shared/ConnectSubscription";
import styled from "styled-components";
import Refetch from "./Refetch";
import { OwcButton, OwcTypography } from "@one/react";

const ErrorStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

export const Error = ({ refetch }) => {
  return (
    <ErrorStyled data-testid="bookingNotificationStatus-error">
      <OwcTypography variant="title6" gutterBottom>
        Cannot connect to result
      </OwcTypography>
      <OwcButton data-testid="bookingNotificationStatus-error-retry" variant="primary" onClick={() => refetch()}>
        Retry
      </OwcButton>
    </ErrorStyled>
  );
};

const getUserId = async (callback) => {
  const { username: id } = await Auth.currentAuthenticatedUser();
  callback(id);
  return id;
};

const BookingNotificationStatus = ({
  query = LIST_BOOKING_COMMAND,
  subscription = ON_UPDATE_BOOKING_COMMAND,
  onTimeoutCallback,
  onBookingCallback,
  tryTimes,
  timeout,
}) => {
  const [user, setUser] = useState("");
  useEffect(() => {
    getUserId(setUser);
  }, []);
  if (!user) return null;
  return (
    <Query query={query} fetchPolicy="cache-and-network">
      {({ loading, error, subscribeToMore, refetch }) => {
        if (loading) return null;
        if (error !== undefined) return <Error refetch={refetch} />;
        return (
          <>
            <Refetch refetch={refetch} />
            <ConnectSubscription
              subscribeToMore={subscribeToMore}
              documentSubscription={subscription}
              dataCallback={onBookingCallback}
              timeoutCallback={onTimeoutCallback}
              variables={{ createdBy: user?.toLowerCase() }}
              tryTimes={tryTimes}
              timeout={timeout}
              refetch={refetch}
            />
          </>
        );
      }}
    </Query>
  );
};

export default BookingNotificationStatus;
