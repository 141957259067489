import { useContext } from "react";
import { SelectItemContext } from "../../contexts";
import NotificationEdit from "../../icons/NotificationEdit";
import { OwcButton } from "@one/react";
const NotificationsButton = ({ instrument }) => {
  const { setItem } = useContext(SelectItemContext);
  return (
    <OwcButton
      variant="secondary"
      data-testid="detailed-info-for-instrument-book-notification"
      onClick={() => {
        setItem(instrument);
      }}
    >
      <NotificationEdit />
    </OwcButton>
  );
};

export default NotificationsButton;
