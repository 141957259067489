import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "underscore";
import ItemFormSuggestionPickerBase from "./ItemFormSuggestionPickerBase";
import { DEBOUNCE_DELAY } from "../../../constants";
import { OwcIconButton, OwcInput } from "@one/react";

const SuggesterField = ({
  meta,
  formik,
  label,
  name,
  isDisabled,
  searchItemList,
  selectSuggestionFn,
  itmesOnSearchFieldFilter,
  waitBeforeSuggestTime = DEBOUNCE_DELAY,
  isEditMode,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [focusedOn, setFocusedOn] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userInputValue, setUserInputValue] = useState("");

  const handleChange = (event) => {
    setUserInputValue(event.target.value);
  };

  const handleFocusFactory = (on) => (event) => {
    setFocusedOn(on);
    setAnchorEl(event.target);
  };

  const searchData = useMemo(
    () =>
      debounce(async () => {
        const userInput = formik.values[name];
        if (userInput) {
          const filteredItemList = itmesOnSearchFieldFilter(searchItemList, userInput);
          setFilteredSuggestions(filteredItemList);
        } else {
          setFilteredSuggestions([]);
        }
      }, waitBeforeSuggestTime),
    [formik.values, itmesOnSearchFieldFilter, name, searchItemList, waitBeforeSuggestTime]
  );

  useEffect(() => {
    searchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInputValue]);

  const selectSuggestion = (instrument) => {
    selectSuggestionFn(instrument);
    setFilteredSuggestions([]);
  };

  const onClear = () => {
    formik.values[name] = "";
    setFilteredSuggestions([]);
    formik.setFieldTouched(name);
  };

  return (
    <>
      <OwcInput
        data-testid={`suggester-search-field-${name}`}
        label={label}
        className="TextFieldInput"
        name={name}
        id={name}
        value={formik.values[name]}
        onInputChange={(event) => {
          formik.setFieldValue([name], event.detail);
          handleChange(event);
          formik.handleChange(event);
        }}
        onBlur={formik.handleBlur}
        onFocus={handleFocusFactory(name)}
        disabled={isDisabled}
        required
        error={formik.errors[name]}
      >
        {formik.values[name] && (
          <OwcIconButton
            data-testid="clear-search-button"
            type="filled"
            icon="circle_clear"
            flat
            onClick={() => onClear()}
          />
        )}
        {formik.errors[name] && formik.touched[name] === true && (
          <>
            <span slot="error-text">{formik.errors[name]}</span>
          </>
        )}
      </OwcInput>
      <ItemFormSuggestionPickerBase
        anchorEl={anchorEl}
        meta={meta}
        name={name}
        PopoverStyle={{
          width: "auto",
          minWidth: 576,
          marginLeft: -10,
          maxHeight: 285,
        }}
        formik={formik}
        focusedOn={focusedOn}
        selectSuggestionFn={selectSuggestion}
        instrumentSuggestions={filteredSuggestions}
      />
    </>
  );
};

export default SuggesterField;
