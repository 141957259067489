import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Search from "../../../../components/shared/Search";

import { GroupContext } from "./context";
import { useFormikContext } from "formik";
import { PaginationContext } from "../../../../components/shared/pagination/PaginationContext";
import { OwcButton } from "@one/react";

export const TopBarStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopBar = ({ setSearchGroup }) => {
  const { setDialogOpen, setIsEditMode } = useContext(GroupContext);
  const { dispatchAction } = useContext(PaginationContext);
  const [searchQuery, setSearchQuery] = useState("");
  const formik = useFormikContext();
  useEffect(() => {
    dispatchAction({
      type: "clearAll",
    });
    setSearchGroup(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  return (
    <>
      <TopBarStyle>
        <Search setQuery={setSearchQuery} label={"Search for booking group"} />
        <OwcButton
          variant="primary"
          key="create-booking-button"
          onClick={() => {
            setIsEditMode(false);
            formik.resetForm();
            formik.setFieldValue("groupName", "", true);
            formik.setFieldValue("users", null, true);
            setDialogOpen(true);
          }}
        >
          Create booking group
        </OwcButton>
      </TopBarStyle>
    </>
  );
};

export default TopBar;
