const DATA_LABEL_TABLE = {
  LEFT_MENU_BAR: {
    main_menu_label: "Menu",
    equiment_list_menu_label: "Equipment list",
    equipment_book_menu_label: "Book equipment",
    equipment_my_booking_menu_label: "My bookings",
    equipment_admin_menu_label: "Admin panel",
  },
  EQUIPMENT_LIST_PAGE: {
    search_label: "Search by ID, Model, Nickname, Serial number",
    filter_search_label: "Search by",
    overview_tab_label: "Overview",
    installed_tests_label: "Installed tests",
    linked_documents_label: "Qualification documents",
  },
  EQUIPMENT_FILTER_LABELS: {
    model_filter_label: "All models",
    availabilities_filter_label: "All availabilities",
    configuration_filter_label: "All configurations",
    version_filter_label: "All software versions",
    group_filter_label: "All groups",
    system_status_filter_label: "All system statuses",
    location_filter_label: "All locations",
    qualification_filter_label: "Qualification status",
  },
  EQUIPMENT_SORT_BY_LABEL: "Sort by",
  BREADCUMB_LABEL: "Equipment details",
  BREADCUMB_EQUIPMENT_LIST_LABEL: "Book equipment",
  MY_BOOKING_PAGE: {
    day: "day",
    days_5: "5-days",
    days_7: "7-days",
    short_identifier_hour_text: "Short identifier / Hour",
  },
  POPUP_BOOKING_PAGE: {
    configuration: "Configuration",
    software_version: "Software version",
    location: "Location",
  },
  ADMIN_TABS: {
    drop_booking: "Drop booking",
    reason_management: "Reason management",
    booking_access_control: "Booking access control",
  },
  BOOKING_FILTER: {
    book_for: "Book for",
    booking_type: "Booking type",
    date_from: "Date from",
    date_to: "Date to",
    status: "Status",
    building: "Building",
    room: "Room",
  },
  ADMIN_ASSIGN_GROUP_TABLE: {
    shortIdentifier: "Equipment",
    users: "Users",
    booking_group: "Booking group",
  },
};

export default DATA_LABEL_TABLE;
