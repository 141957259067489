const MyBookingsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 9H21" stroke="#706B69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19.5 5H4.5C3.67157 5 3 5.67157 3 6.5V19.5C3 20.3284 3.67157 21 4.5 21H19.5C20.3284 21 21 20.3284 21 19.5V6.5C21 5.67157 20.3284 5 19.5 5Z"
      stroke="#706B69"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.5 7V3M17.5 7V3" stroke="#706B69" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12 19C14.2091 19 16 17.2091 16 15C16 12.7909 14.2091 11 12 11C9.79086 11 8 12.7909 8 15C8 17.2091 9.79086 19 12 19Z"
      stroke="#706B69"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13.5V16.5M13.5 15H10.5"
      stroke="#706B69"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MyBookingsIcon;
