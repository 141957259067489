import { RocheInputAdornment } from "@one/react-kit";
import styled from "styled-components";
import React from "react";
import { IconButton } from "@mui/material";

export const Input = styled.div`
  margin-bottom: 20px;
  margin-right: -5px;
`;

export const hasFormikObjectError = (formik, fieldName) => {
  return (
    (formik.touched[fieldName]?.value || formik.touched[fieldName] === true) &&
    Boolean(formik.errors[fieldName]?.value ?? formik.errors[fieldName])
  );
};
export const getFormikObjectError = (formik, fieldName) => {
  return (
    (formik.touched[fieldName]?.value || formik.touched[fieldName] === true) &&
    (formik.errors[fieldName]?.value ?? formik.errors[fieldName])
  );
};

const commonPropsForInputs =
  ({
    fieldKeyFn = (key) => `${key}.value`,
    valueFn = (formik, key) => formik.values?.[key]?.value,
    errorFn = (formik, key) => hasFormikObjectError(formik, key),
    helperTextFn = (formik, key) => getFormikObjectError(formik, key),
  } = {}) =>
  (
    classes,
    formik,
    key,
    dataTestIdKey,
    dataTestIdForFormKey,
    isEdit = false,
    showBadge = false,
    isUpdateBadge = false
  ) => ({
    InputProps: {
      endAdornment: (
        <RocheInputAdornment position="end">
          {valueFn(formik, key)?.length > 0 && !isEdit && (
            <>
              <IconButton
                classes={{
                  root: classes.clearInputButton,
                }}
                data-testid={`${dataTestIdForFormKey}-clear-button-${dataTestIdKey}-input`}
                edge="end"
                onClick={() => formik.setFieldValue(fieldKeyFn(key), "", true)}
              >
                <i className="one-icons">clear</i>
              </IconButton>
            </>
          )}
        </RocheInputAdornment>
      ),
    },
    error: errorFn(formik, key),
    helperText: helperTextFn(formik, key),
    FormHelperTextProps: {
      "data-testid": `${dataTestIdForFormKey}-helper-text-${dataTestIdKey}-input`,
    },
  });

export const commonPropsForInputsWithValue = commonPropsForInputs();
export const commonPropsForInputsWithoutValue = commonPropsForInputs({
  fieldKeyFn: (key) => key,
  valueFn: (formik, key) => formik.values?.[key],
  errorFn: (formik, key) => formik.touched[key] && Boolean(formik.errors[key]),
  helperTextFn: (formik, key) => formik.touched[key] && formik.errors[key],
});
