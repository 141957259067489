import { OwcChip } from "@one/react";
import { equipmentStatus, statusChip } from "../../constants";

const EquipmentStatusRender = (params) => {
  const status = statusChip[params.value];
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        {params.value !== null ? (
          <OwcChip
            outlined={true}
            style={{
              minHeight: "22px",
              color:
                status === equipmentStatus?.deleted?.value
                  ? "var(--one-color-red-a-300)"
                  : status === equipmentStatus?.pending?.value
                  ? "var(--one-color-yellow-a-300)"
                  : "var(--one-color-cobas-green-600)",
            }}
          >
            <strong> {status}</strong>
          </OwcChip>
        ) : (
          "-"
        )}
      </div>
    </>
  );
};
export default EquipmentStatusRender;
