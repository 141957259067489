import { OwcButton } from "@one/react/Components/OwcButton";
import React, { useEffect, useState } from "react";
import { snackbarService } from "@one/web-components";
import { DURATION } from "../../../constants";
import { CHECK_IN_BOOKING } from "../../../gql/bookingapi";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { CheckinIcon } from "../icons/CheckinIcon";
import { BUTTON_STATE, COLORS } from "./constants";
import {
  isCurrentDateAfterDate,
  isCurrentDateBeforeDate,
  isCurrentDateBetweenDates,
  milliSecondsBetweenDates,
} from "./helpers";
import { isToday } from "date-fns";
import { OwcIcon } from "@one/react";
import { THEME } from "@digitallab/grid-common-components";

/**
 * Represents a check-in button component.
 *
 * @param {Object} client - The client object.
 * @param {Object} item - The item object.
 * @param {Object} user - Logged in user.
 * @returns {false|React.JSX.Element} The check-in button component.
 */
export const CheckInButton = ({ client, item, user }) => {
  const [worker, setWorker] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [buttonState, setButtonState] = useState(BUTTON_STATE.CHECKIN_NOT_AVAILABLE);
  const [color, setColor] = useState(COLORS.DISABLED);

  useEffect(() => {
    const init = async () => {
      await initData();
      await initWorker();
    };
    if (item.checkInRequired) {
      init();
    }
    return () => {
      terminateWorkerIfExist();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const terminateWorkerIfExist = () => {
    worker && worker.terminate();
  };

  /**
   * Checks if the given value is a valid number.
   *
   * @param {any} timestamp - The value to be checked.
   * @returns {boolean} - True if the value is a valid number, false otherwise.
   */
  const isValidNumber = (timestamp) => Number.isFinite(parseFloat(timestamp.toString()));

  /**
   * Initializes the worker and sets up the event listener for messages from the worker.
   *
   * @async
   */
  const initWorker = async () => {
    const countdownWorker = new Worker("web-worker/countdownWorker.mjs", { type: "module" });
    setWorker(countdownWorker);
    return (countdownWorker.onmessage = async (event) => {
      if (event.data.type === "timeoutDone") {
        if (buttonState === BUTTON_STATE.CHECKIN_DISABLED) {
          setButtonState(BUTTON_STATE.CHECKIN_AVAILABLE);
          await startTimeoutWorker(milliSecondsBetweenDates(new Date(item?.checkInEndsAt * 1000), new Date()));
        } else {
          setButtonState(BUTTON_STATE.CHECKIN_NOT_AVAILABLE);
        }
      }
    });
  };

  /**
   * Convert Unix timestamp to ISO string format.
   *
   * @param {number} timestamp - The Unix timestamp in seconds.
   * @returns {string|null} The ISO string representation of the timestamp, or null if the input is invalid.
   */
  const convertUnixTimestampToISOString = (timestamp) => {
    return isValidNumber(timestamp) ? new Date(timestamp * 1000).toISOString() : null;
  };

  /**
   * Updates the state and disabled status of a button.
   *
   * @param {string} state - The new state of the button.
   * @param {string} newColor - The new color of the text.
   * @param {boolean} isDisabled - Whether the button should be disabled or not.
   * @returns {void}
   */
  const updateButtonState = (state, newColor, isDisabled) => {
    setButtonState(state);
    setColor(newColor);
    setDisabled(isDisabled);
  };

  /**
   * Stops the worker timeout.
   *
   * @function clearWorker
   * @returns {void}
   */
  const clearWorker = () => {
    worker && worker.postMessage({ type: "stopTimeout" });
  };

  /**
   * Sends a message to a worker.
   *
   * @param {string} type - The type of the message.
   * @param {number} time - The time value associated with the message.
   * @returns {void}
   */
  const sendWorkerMessage = (type, time) => {
    if (worker) {
      worker.postMessage({ type, time });
    }
  };

  /**
   * Starts a timeout worker with the given count.
   *
   * @param {number} count - The duration of the timeout in milliseconds.
   */
  const startTimeoutWorker = (count) => {
    clearWorker();

    if (isValidNumber(count)) {
      sendWorkerMessage("startTimeout", count);
    }
  };

  /**
   * Initializes data for a check-in button.
   *
   * The initData function performs the following tasks:
   *   1. Converts the Unix timestamp of the check-in start date to an ISO string format.
   *   2. Converts the Unix timestamp of the check-in end date to an ISO string format.
   *   3. Checks if the current date is before the check-in start date and updates the button state accordingly.
   *   4. If the check-in start date is today, starts a timeout worker to countdown the time until the check-in starts.
   *   5. Checks if the current date is between the check-in start date and the check-in end date and updates the button
   *      state accordingly.
   *   6. If the check-in has been done, updates the button state to indicate that the check-in is done.
   *   7. If the current date is after the check-in end date, updates the button state to indicate that the check-in is
   *      not available.
   *
   * @async
   * @function initData
   */
  const initData = async () => {
    let checkinStartDateISOString = convertUnixTimestampToISOString(item.checkInStartsAt);
    let checkinEndDateISOString = convertUnixTimestampToISOString(item.checkInEndsAt);

    if (isCurrentDateBeforeDate(checkinStartDateISOString)) {
      updateButtonState(BUTTON_STATE.CHECKIN_DISABLED, COLORS.DISABLED, true);
      if (isToday(new Date(checkinStartDateISOString))) {
        startTimeoutWorker(milliSecondsBetweenDates(new Date(checkinStartDateISOString), new Date()));
      }
    } else if (isCurrentDateBetweenDates(checkinStartDateISOString, checkinEndDateISOString)) {
      if (item?.checkInDone) {
        updateButtonState(BUTTON_STATE.CHECKIN_DONE, COLORS.ENABLED, false);
      } else {
        updateButtonState(BUTTON_STATE.CHECKIN_AVAILABLE, COLORS.ENABLED, false);
        startTimeoutWorker(milliSecondsBetweenDates(new Date(checkinEndDateISOString), new Date()));
      }
    } else if (isCurrentDateAfterDate(checkinEndDateISOString)) {
      updateButtonState(BUTTON_STATE.CHECKIN_NOT_AVAILABLE, COLORS.DISABLED, true);
    }
  };

  /**
   * Handles click events on a button.
   * If the button state is "CHECKIN_AVAILABLE", it calls the "checkInBooking" function asynchronously.
   *
   * @function
   * @async
   * @name handleClick
   * @returns {Promise<void>}
   */
  const handleClick = async () => {
    if (buttonState === BUTTON_STATE.CHECKIN_AVAILABLE) {
      await checkInBooking();
    }
  };

  /**
   * Performs a check-in for a booking.
   *
   * @async
   * @function checkInBooking
   * @returns {Promise<void>} Returns a Promise that resolves when the check-in is completed or rejects if an error occurs.
   * @throws {Error} Throws an error if an error occurs during check-in.
   */
  const checkInBooking = async () => {
    try {
      await client.mutate({
        mutation: CHECK_IN_BOOKING,
        variables: {
          bookingId: item.id,
          createdBy: user.user,
        },
      });
      await displaySuccessSnackbar();
      setButtonState(BUTTON_STATE.CHECKIN_DONE);
    } catch (error) {
      await displayErrorSnackbar(error);
    }
  };

  /**
   * Displays a success snackbar.
   *
   * @function
   * @name displaySuccessSnackbar
   * @returns {void}
   *
   * @description
   * This function is used to display a success snackbar using the snackbarService.
   * The snackbar will show a success message indicating that the user has successfully checked in to their booking.
   * The duration of the snackbar can be customized by setting the DURATION constant.
   */
  const displaySuccessSnackbar = () => {
    snackbarService.show({
      type: "success",
      message: "You successfully checked in to your booking",
      duration: DURATION,
    });
  };

  /**
   * Display an error snackbar.
   *
   * @function displayErrorSnackbar
   * @description This function shows an error snackbar using the snackbarService.
   *
   * @returns {void}
   */
  const displayErrorSnackbar = () => {
    snackbarService.show({
      type: "error",
      message: "There was an error while checking in",
      duration: DURATION,
    });
  };

  return (
    buttonState !== BUTTON_STATE.CHECKIN_NOT_AVAILABLE && (
      <OwcButton
        style={{
          backgroundColor: "transparent",
        }}
        data-testid="checkin_button"
        variant={"tertiary"}
        flat={true}
        disableRipple={true}
        onClick={handleClick}
        disabled={disabled}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: color,
          }}
        >
          {buttonState === BUTTON_STATE.CHECKIN_DONE ? (
            <OwcIcon
              style={{
                color: THEME["one-color-cobas-green-400"],
              }}
              name="circle_confirm"
              type="filled"
            />
          ) : (
            <CheckinIcon color={color} />
          )}
          <span
            style={{
              alignSelf: "center",
              color: color,
            }}
          >
            &nbsp;{buttonState === BUTTON_STATE.CHECKIN_DONE ? "Checked in" : "Check in"}
          </span>
        </div>
      </OwcButton>
    )
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default compose(connect(mapStateToProps, {}), withApollo)(CheckInButton);
