import styled from "styled-components";
import { useContext } from "react";
import useStyle from "../../../utils/hooks/useStyle";
import { WidgetContext } from "../../../contexts";
import { OwcButton } from "@one/react";

const TodayButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--one-color-background-brand-1) !important;
  margin-right: 16px;
  margin-left: 42px;
`;

const CWTodayButtonStyled = styled(TodayButtonStyled)`
  margin-left: 7px;
  height: 36px;
  width: 70px;
  padding-top: 7px;
`;

const TodayButton = ({ setDate }) => {
  const { widgetName } = useContext(WidgetContext);
  const CustomButtonStyle = useStyle({
    widgetName,
    widgetStyle: CWTodayButtonStyled,
    defaultStyle: TodayButtonStyled,
  });
  return (
    <CustomButtonStyle>
      <OwcButton variant="secondary" data-testid="booking-calendar-today-button" onClick={() => setDate(new Date())}>
        Today
      </OwcButton>
    </CustomButtonStyle>
  );
};

export default TodayButton;
