import CustomList from "../../../components/shared/List";
import { FixTableMargin } from "../Installed-tests/InstalledTestTable";
import { useEffect, useState } from "react";
import { sortData } from "../../../utils/helpers/text";

const LinkedDocumentsTable = ({ linkedDocuments }) => {
  const [orderBy, setOrderBy] = useState("name");
  const [isReverseOrder, setIsReverseOrder] = useState(false);
  const [sortedLinkedDocuments, setSortedLinkedDocuments] = useState([]);

  useEffect(() => {
    const sorted = sortData(linkedDocuments, [orderBy], isReverseOrder);
    setSortedLinkedDocuments(sorted);
  }, [linkedDocuments, orderBy, isReverseOrder]);

  const onRequestSort = (key, reverse) => {
    setOrderBy(key);
    setIsReverseOrder(reverse);
  };

  return (
    <FixTableMargin>
      <CustomList
        orderBy={orderBy}
        isReverseOrder={isReverseOrder}
        onRequestSort={onRequestSort}
        data={sortedLinkedDocuments}
        meta={{
          fields: {
            name: {
              text: "Name",
              sortable: true,
              headProps: {
                style: {
                  flex: "0 1 80%",
                },
              },
              cellProps: {
                style: {
                  flex: "0 1 80%",
                },
              },
            },
            documentId: {
              text: "Document id",
              headProps: {
                style: {
                  flex: "0 1 20%",
                },
              },
              cellProps: {
                style: {
                  flex: "0 1 20%",
                },
              },
            },
          },
        }}
      />
    </FixTableMargin>
  );
};

export default LinkedDocumentsTable;
