import React, { useContext } from "react";
import DescriptionCard from "./DescriptionCard";
import { BackendInfoContext } from "../../features/backend-info-context/context";

const ReturnItems = ({ items }) => {
  return items?.map((item, id) =>
    item ? <DescriptionCard title={`Backend ${id + 1}`} key={item?.id} {...item} /> : null
  );
};

const BackendInfo = () => {
  const { backendInfo } = useContext(BackendInfoContext);
  const backendData = [backendInfo];
  return <>{backendInfo && <ReturnItems items={backendData} />}</>;
};

export default BackendInfo;
