import { OwcChip, OwcGrid, OwcTooltip, OwcTypography } from "@one/react";
import React from "react";
import { formatUserList, valueOrEmpty } from "../../../utils/helpers/text";
import { useSelector } from "react-redux";
import moment from "moment";
import CheckInButton from "../CheckInButton/CheckInButton";
import { changeActionTypeToDisplay, isActionType } from "../helpFunctions";

const PopoverBookingStatusDetail = ({
  data,
  title = "Booking details",
  dateRange = false,
  equipmentDetails = false,
}) => {
  const eventData = data?.eventData;
  const checkInRequired = eventData?.checkInRequired;
  const usersList = useSelector((state) => state.users.users);
  const user = useSelector((store) => store.user);
  let list = usersList.map((item) => ({
    ...item,
    key: item.email,
    value: formatUserList(item),
  }));

  const formatTime = (date) => {
    return moment(date).tz(user?.timezone).format("hh:mm a");
  };

  // Booked for
  const bookedFor = eventData?.reservedForEmails ?? [];
  let bookedForName = bookedFor?.map((email) => {
    return list.find((user) => user.email === email);
  });
  bookedForName = bookedForName.filter((k) => k !== undefined);

  const dateFromFormatter = moment(eventData?.start).tz(user?.timezone).format("DD-MMM-YYYY");

  const dateToFormatter = moment(eventData?.end).tz(user?.timezone).format("DD-MMM-YYYY");

  const timeFrom = moment(eventData?.start).tz(user?.timezone).format("hh:mm A");
  const timeTo = moment(eventData?.end).tz(user?.timezone).format("hh:mm A");

  const CheckinAvailability = () => {
    try {
      const formattedStartDate = dateFromFormatter;
      const formatted = {
        before: formatTime(eventData.checkInStartsAt * 1000),
        after: formatTime(eventData.checkInEndsAt * 1000),
      };
      return (
        <span>
          Check-in is available in {formattedStartDate}, between {formatted.before} - {formatted.after}
        </span>
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return (
    <div style={{ padding: "0 15px" }}>
      {checkInRequired && (
        <div
          style={{
            border: "1px solid #c2bab5",
            borderRadius: ".25rem",
            padding: ".5rem",
            backgroundColor: "#fafafa",
          }}
        >
          <div className={"d-flex"}>
            <div className={"left_flex"}>
              <strong
                style={{
                  fontSize: "16px",
                }}
              >
                This booking event requires check in
              </strong>
              <br />
              <span
                style={{
                  fontSize: "14px",
                }}
              >
                {<CheckinAvailability />}
              </span>
            </div>
            <div className={"right_flex"}>
              <CheckInButton key={data?.eventData?.id} item={data?.eventData} />
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: equipmentDetails ? "50%" : "100%",
          }}
        >
          <OwcTypography variant="button" style={{ margin: "16px 0" }}>
            {title}
          </OwcTypography>
          <div>
            <OwcGrid container columns={1} vGutter={{ m: 10, l: 15, xl: 20 }}>
              {!dateRange && (
                <>
                  <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 12 }}>
                    <OwcTypography variant="caption">Date range</OwcTypography>

                    <OwcTypography variant="button" id={"date_range_display"}>
                      {`${dateFromFormatter} - ${dateToFormatter}`}
                    </OwcTypography>
                    <OwcTooltip anchor={"date_range_display"} offset={[0, 0]}>
                      {`${dateFromFormatter} - ${dateToFormatter}`}
                    </OwcTooltip>
                  </OwcGrid>
                  <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 12 }}>
                    <OwcTypography variant="caption">Time</OwcTypography>

                    <OwcTypography variant="button" id={"time_range_display"}>
                      {`${timeFrom} - ${timeTo}`}
                    </OwcTypography>
                    <OwcTooltip anchor={"time_range_display"} offset={[0, 0]}>
                      {`${timeFrom} - ${timeTo}`}
                    </OwcTooltip>
                  </OwcGrid>
                </>
              )}
              {dateRange && (
                <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 12 }}>
                  <OwcTypography variant="caption">Date and time range</OwcTypography>

                  <OwcTypography variant="button" id={"date_time_range_display"}>
                    {`${dateFromFormatter}, ${timeFrom} - ${dateToFormatter}, ${timeTo}`}
                  </OwcTypography>
                  <OwcTooltip anchor={"date_time_range_display"} offset={[0, 0]}>
                    {`${dateFromFormatter}, ${timeFrom} - ${dateToFormatter}, ${timeTo}`}
                  </OwcTooltip>
                </OwcGrid>
              )}

              <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 12 }}>
                <OwcTypography variant="caption">Description</OwcTypography>

                <OwcTypography variant="button" id={`attr_des${eventData?.description}`}>
                  {valueOrEmpty(eventData?.description, false, "-")}
                </OwcTypography>
                <OwcTooltip anchor={`attr_des${eventData?.description}`}>
                  {valueOrEmpty(eventData?.description, false, "-")}
                </OwcTooltip>
              </OwcGrid>

              <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 12 }}>
                <OwcTypography variant="caption">Project</OwcTypography>

                <OwcTypography variant="button" id={`attr_${eventData?.project}`}>
                  {valueOrEmpty(eventData?.project, false, "-")}
                </OwcTypography>
                <OwcTooltip anchor={`attr_${eventData?.project}`}>
                  {valueOrEmpty(eventData?.project, false, "-")}
                </OwcTooltip>
              </OwcGrid>
              {isActionType(eventData.type) && (
                <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 16 }}>
                  <OwcTypography variant="caption">Action type</OwcTypography>

                  <OwcTypography variant="button" id={`attr_${eventData?.type}`}>
                    {valueOrEmpty(changeActionTypeToDisplay(eventData?.type), false, "-")}
                  </OwcTypography>
                  <OwcTooltip anchor={`attr_${eventData?.type}`}>
                    {valueOrEmpty(changeActionTypeToDisplay(eventData?.type), false, "-")}
                  </OwcTooltip>
                </OwcGrid>
              )}
              {bookedForName?.length > 0 && (
                <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 12 }}>
                  <OwcTypography variant="caption" style={{ padding: "3px 0" }}>
                    Booking for
                  </OwcTypography>
                  <div style={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
                    {bookedForName?.map((item) => (
                      <OwcChip outlined round icon="user" iconType="outlined">
                        {item?.value}
                      </OwcChip>
                    ))}
                  </div>
                </OwcGrid>
              )}
            </OwcGrid>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: equipmentDetails ? "50%" : "0%",
          }}
        >
          {equipmentDetails && (
            <>
              <OwcTypography variant="button" style={{ margin: "16px 0" }}>
                Equipment details
              </OwcTypography>
              <OwcGrid item l={1} m={1} xs={1} s={1} style={{ marginBottom: 12 }}>
                <OwcTypography variant="caption">Short ID(s)</OwcTypography>

                <OwcTypography variant="button">
                  {(data?.equipmentData?.bookedEquipments || []).map((bookedEquipment) => (
                    <>
                      {bookedEquipment}
                      <br />
                    </>
                  ))}
                </OwcTypography>
              </OwcGrid>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopoverBookingStatusDetail;
