import { THEME } from "@digitallab/grid-common-components";

export const COLORS = Object.freeze({
  DISABLED: THEME["one-color-cobas-gray-500"],
  ENABLED: THEME["one-color-cobas-blue-600"],
});

export const BUTTON_STATE = Object.freeze({
  CHECKIN_AVAILABLE: "AVAILABLE",
  CHECKIN_NOT_AVAILABLE: "NOT_AVAILABLE",
  CHECKIN_DONE: "DONE",
  CHECKIN_DISABLED: "DISABLED",
});
