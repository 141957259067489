import ReasonActions from "./ReasonActions";

export const ReasonTableMeta = {
  fields: {
    configText: {
      text: "Reason",
      headProps: {
        style: {
          flex: "0 1 15%",
        },
      },
      cellProps: {
        style: {
          flex: "0 1 90%",
        },
      },
    },
    action: {
      component: ReasonActions,
      headProps: {
        style: {
          flex: "0 1 15%",
        },
      },
      cellProps: {
        style: {
          flex: "0 1 15%",
        },
      },
    },
  },
};

export default ReasonTableMeta;
