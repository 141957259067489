import React, { useContext, useEffect, useState } from "react";
import BookingFilter from "./BookingFilter";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import BookingList from "./BookingList";
import styled from "styled-components";
import DeleteBooking from "../DeleteBooking";
import MyBookingMultiDeleteDialog from "../booking-instruments/my-bookings/my-booking-list-view/my-booking-multi-delete-button/MyBookingMultiDeleteDialog";
import BookingNotificationStatus from "../booking-calendars/booking-notification-status/BookingNotificationStatus";
import { timeoutCallbackFactory } from "../booking-calendars/booking-notification-status/timeoutCallbackFactory";
import { DURATION, GRID_VIEW, LIST_VIEW } from "../../constants";
import { snackbarService } from "@one/web-components";
import { debounce } from "lodash";
import { PaginationContext } from "../../components/shared/pagination/PaginationContext";
import { useSelector } from "react-redux";
import { getWeekStartAndEndInISOString } from "../../utils/helpers/text";
import BookingGrid from "./BookingGrid";
import { useParams } from "react-router-dom";

const MainViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 100%;
  flex-grow: 1;
`;

const bookingCallbackFactory = ({
  dispatchAction,
  setOpenMultiDeleteDialog,
  setStatus,
  setIsDropBooking,
  setIsDialogOpen,
  setTempRowData,
}) => {
  return debounce((data) => {
    if (data?.data?.onUpdateBookingCommand?.processingResult !== "CHECK_IN_SUCCEEDED") {
      const result =
        data?.data?.onUpdateBookingCommand?.processingResult === "ALL_SUCCEEDED"
          ? {
              type: "success",
              message: "Booking has been cancelled successfully !",
              duration: DURATION,
            }
          : {
              type: "error",
              message: "There were problems with booking, please check detailed statuses.",
              duration: DURATION,
            };
      snackbarService.show(result);
      setStatus(false);
      setOpenMultiDeleteDialog(false);
      setIsDropBooking(false);
      setIsDialogOpen(false);
      setTempRowData([]);
      dispatchAction({
        type: "clearAll",
      });
    }
  }, 2500);
};

function MyBooking({ subscriptionStatus, setSubscriptionStatus, setPopupEnableStatus }) {
  const { trackPageView } = useMatomo();
  const { EventID } = useParams();
  const [eventID, setEventID] = useState(null);

  useEffect(() => {
    trackPageView({
      documentTitle: "My bookings",
      href: `${window.location?.origin}/Mybookings`,
    });
    if (EventID) {
      setEventID(EventID);
      setView(GRID_VIEW);
    }
    // eslint-disable-next-line
  }, []);
  const user = useSelector((store) => store.user);
  const [resetAll, setResetAll] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [disableResetAll, setDisableResetAll] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([
    getWeekStartAndEndInISOString("start", user.timezone),
    getWeekStartAndEndInISOString("end", user.timezone),
  ]);

  const [tempRowData, setTempRowData] = useState([]);
  const [isDropBooking, setIsDropBooking] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openMultiDeleteDialog, setOpenMultiDeleteDialog] = useState(false);
  const [rowEquipmentId, setRowEquipmentId] = useState(null);
  const { dispatchAction } = useContext(PaginationContext);
  const [status, setStatus] = useState(false);
  const [view, setView] = useState(LIST_VIEW);

  const bookingCallback = bookingCallbackFactory({
    dispatchAction,
    setOpenMultiDeleteDialog,
    setStatus,
    setIsDropBooking,
    setIsDialogOpen,
    setTempRowData,
  });

  useEffect(() => {
    if (subscriptionStatus) {
      let result;
      if (subscriptionStatus?.processingResult === "ALL_SUCCEEDED") {
        result = {
          type: "success",
          message: "Booking confirmed successfully!",
          duration: DURATION,
        };
      } else if (
        subscriptionStatus?.processingResult === "SOME_FAILED" ||
        subscriptionStatus?.processingResult === "ALL_FAILED"
      ) {
        result = {
          type: "warning",
          message: `There was an issue with your equipment booking timeslots(s). Please check "My bookings" for more information.`,
          duration: DURATION,
        };
      } else {
        result = {
          type: "error",
          message: "There were problems with bookings, please check detailed statuses.",
          duration: DURATION,
        };
      }
      snackbarService.show(result);
      setSubscriptionStatus(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionStatus]);
  return (
    <MainViewStyled>
      <>
        <BookingFilter
          resetAll={resetAll}
          setResetAll={setResetAll}
          showAdvanceFilter={showAdvanceFilter}
          setShowAdvanceFilter={setShowAdvanceFilter}
          setSelectedEquipment={setSelectedEquipment}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          tempRowData={tempRowData}
          setTempRowData={setTempRowData}
          disableResetAll={disableResetAll}
          setDisableResetAll={setDisableResetAll}
          view={view}
          setView={setView}
        />
        {view === LIST_VIEW ? (
          <BookingList
            selectedDateRange={selectedDateRange}
            setDisableResetAll={setDisableResetAll}
            setSelectedDateRange={setSelectedDateRange}
            setSelectedEquipment={setSelectedEquipment}
            selectedEquipment={selectedEquipment}
            showAdvanceFilter={showAdvanceFilter}
            resetAll={resetAll}
            setSearchQuery={setSearchQuery}
            setIsDropBooking={setIsDropBooking}
            setIsDialogOpen={setIsDialogOpen}
            setOpenMultiDeleteDialog={setOpenMultiDeleteDialog}
            setRowEquipmentId={setRowEquipmentId}
            tempRowData={tempRowData}
            setTempRowData={setTempRowData}
            searchQuery={searchQuery}
            setResetAll={setResetAll}
            setPopupEnableStatus={setPopupEnableStatus}
          />
        ) : (
          <BookingGrid viewData={view} setViewData={setView} eventID={eventID} />
        )}

        <BookingNotificationStatus
          tryTimes={3}
          timeout={5 * 60 * 1000}
          onTimeoutCallback={timeoutCallbackFactory()}
          onBookingCallback={bookingCallback}
        />
        {isDropBooking && (
          <DeleteBooking
            setIsDropBooking={setIsDropBooking}
            setIsDialogOpen={setIsDialogOpen}
            isDialogOpen={isDialogOpen}
            item={rowEquipmentId}
            status={status}
            setStatus={setStatus}
          />
        )}

        {openMultiDeleteDialog && (
          <MyBookingMultiDeleteDialog
            openMultiDeleteDialog={openMultiDeleteDialog}
            setOpenMultiDeleteDialog={setOpenMultiDeleteDialog}
            selectedEquipment={selectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
            status={status}
            setStatus={setStatus}
          />
        )}
      </>
    </MainViewStyled>
  );
}

export default MyBooking;
