import { Auth } from "aws-amplify";
import { format } from "date-fns";
import { useCallback, useContext, useRef, useState, useEffect } from "react";
import { compose, withApollo } from "react-apollo";
import { connect, useSelector } from "react-redux";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import { DELETE_BOOKING, UPDATE_BOOKING } from "../../../gql/bookingapi/mutations";
import useDialog from "../../../utils/hooks/useDialog";
import { Availability } from "../../booking-instruments/InstrumentAvailability";
import BookingNotificationStatus from "../booking-notification-status/BookingNotificationStatus";
import { timeoutCallbackFactory } from "../booking-notification-status/timeoutCallbackFactory";
import ModalToEditReservation from "../describe-task-page/ModalToEditReservation";
import {
  addPendingReservation as addPendingReservationAction,
  removePendingReservation as removePendingReservationAction,
  removeReservation as removeReservationAction,
  updateReservation as updateReservationAction,
} from "../redux/actions";
import { ReservationPopoverContext } from "./context";
import { WidgetContext } from "../../../contexts";
import {
  CALENDAR_WIDGET,
  POPOVER_PROJECTDETAILS_EMPTY,
  POPOVER_DETAILS_EMPTY,
  POPOVER_WIDGET_DETAILS_EMPTY,
  POPOVER_WIDGET_PROJECTDETAILS_EMPTY,
  entryType,
  POPOVER_CLUSTER_DETAILS_EMPTY,
  DEFAULT_CLUSTER_POPOVER_SKELETON,
  DURATION,
} from "../../../constants";
import { changeDateFormat, capitalizeStr } from "../../../utils/helpers/text";
import moment from "moment";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { DLabEquipmentDetailsPopOver } from "@digitallab/grid-common-components";
import { getClusterPopoverInfoObject } from "../../../utils/helpers/text/index";
import { getClusterDetailsById } from "../../../utils/helpers/fetching";
import { snackbarService } from "@one/web-components";

const getValue = (value) => (!value || value === "null" ? "-" : value);

export const reservationFromDetails = (details) => ({
  date: changeDateFormat(details.dateFrom, "yyyy-MM-dd"),
  dateFrom: changeDateFormat(details.dateFrom, "ISO"),
  dateTo: changeDateFormat(details.dateTo, "ISO"),
  inventoryId: details?.instrument.inventoryId,
});

const handleSaveFactory = ({
  handleOpen,
  anchorEl,
  details,
  dataToDisplay,
  setLoading,
  operationRef,
  client,
  email,
}) => {
  return async (s) => {
    try {
      const { username: id } = await Auth.currentAuthenticatedUser();
      handleOpen(
        {
          currentTarget: anchorEl,
        },
        {
          ...details,
          reservedForEmail: s.reservedForEmail,
          description: s.description,
          availability: s.availability,
          reservation: {
            ...details.reservation,
            reservedForEmail: s.reservedForEmail,
            reservedForUser: s.reservedForUser,
            description: s.description,
            project: s.project,
            type: s.availability.toUpperCase(),
            availability: s.availability,
          },
        },
        dataToDisplay
      );
      setLoading("edit");
      operationRef.current = "edit";
      await client.mutate({
        mutation: UPDATE_BOOKING,
        variables: {
          createdBy: id,
          createdByEmail: email,
          createdByName: email,
          entries: [
            {
              inventoryId: details.reservation.inventoryId,
              reservedOnMorning: details.reservation.reservedOnMorning,
              reservedOnAfternoon: details.reservation.reservedOnAfternoon,
              dateFrom: details.reservation.dateFrom,
              dateTo: details.reservation.dateTo,
              bookingId: details.reservation.id,
              site: details.reservation.site,
              reservedForEmail: s.reservedForEmail,
              reservedForUser: s.reservedForUser,
              description: s.description,
              project: s.project,
              type: s.availability.toUpperCase(),
            },
          ],
        },
      });
    } catch (e) {
      setLoading(null);
    }
  };
};

const getReservationAvailabilityUser = (details, users) => {
  const user = users?.filter((u) => u["email"]?.toLowerCase() === details?.reservedForEmail?.toLowerCase())?.[0];
  if (details.isPending) {
    return "by You";
  }
  if (details.availability !== Availability.available) {
    return `${
      !details?.instrument?.equipmentNickName && details?.instrument?.equipmentNickName === "null"
        ? details?.instrument?.equipmentNickName
        : details?.instrument?.shortIdentifier
    } is ${details?.availability} for ${user?.name || user?.email || details?.reservedForEmail || "-"}`;
  }
  return capitalizeStr(details.availability);
};

const reservationAvailability = (details, users, isWidget) => {
  return isWidget ? `${details?.instrument.shortIdentifier}` : getReservationAvailabilityUser(details, users);
};

const getTimeRanges = (details) => {
  return details?.timeRanges?.length > 0
    ? details.timeRanges
    : [
        {
          dateFrom: details?.dateFrom,
          dateTo: details?.dateTo,
        },
      ];
};

const handleCancelFactory = ({ removePendingReservation, details, handleClose }) => {
  return () => {
    removePendingReservation({
      ...reservationFromDetails(details),
    });
    handleClose();
  };
};

const handleAddFactory = ({ details, removePendingReservation, addPendingReservation, handleClose }) => {
  return (availability) => {
    if (details.timeRanges && details.useTimeRanges) {
      details.timeRanges.forEach((timeRange) => {
        const item = {
          ...details,
          ...timeRange,
        };
        removePendingReservation({
          ...reservationFromDetails(item),
        });
        addPendingReservation({
          ...reservationFromDetails(item),
          availability: availability,
        });
      });
    } else {
      if (details.isPending) {
        removePendingReservation({
          ...reservationFromDetails(details),
        });
      }
      addPendingReservation({
        ...reservationFromDetails(details),
        availability: availability,
      });
    }
    handleClose();
  };
};

const handleReservedCancelFactory = ({ setLoading, operationRef, client, email, details, value = "" }) => {
  return async () => {
    try {
      const { username: id } = await Auth.currentAuthenticatedUser();
      setLoading("cancel");
      operationRef.current = "cancel";
      await client.mutate({
        mutation: DELETE_BOOKING,
        variables: {
          createdBy: id,
          createdByEmail: email,
          createdByName: email,
          entries: [
            {
              inventoryId: details.reservation.inventoryId,
              type: details.reservation.type,
              reservedOnMorning: details.reservation.reservedOnMorning,
              reservedOnAfternoon: details.reservation.reservedOnAfternoon,
              dateFrom: details.reservation.dateFrom,
              dateTo: details.reservation.dateTo,
              bookingId: details.reservation.id,
              site: details.reservation.site,
              remark: value,
            },
          ],
        },
      });
    } catch (e) {
      setLoading(null);
    }
  };
};

const useHandlers = ({
  removePendingReservation,
  details,
  handleClose,
  addPendingReservation,
  setLoading,
  operationRef,
  client,
  email,
  openDialog,
  value,
}) => {
  const handleCancel = handleCancelFactory({
    removePendingReservation,
    details,
    handleClose,
  });

  const handleAdd = handleAddFactory({
    details,
    removePendingReservation,
    addPendingReservation,
    handleClose,
  });

  const handleReservedCancel = handleReservedCancelFactory({
    setLoading,
    operationRef,
    client,
    email,
    details,
    value,
  });
  const handleReservedEdit = () => {
    openDialog();
  };

  return {
    handleReservedCancel,
    handleReservedEdit,
    handleCancel,
    handleAdd,
  };
};

const bookingCallbackFn = ({
  data,
  operationRef,
  details,
  removeReservation,
  handleClose,
  updateReservation,
  setLoading,
}) => {
  const result =
    data?.data?.onUpdateBookingCommand?.processingResult === "ALL_SUCCEEDED"
      ? {
          type: "success",
          message: `My booking was ${operationRef.current === "cancel" ? "removed" : "updated"} successfully!`,
          duration: DURATION,
        }
      : {
          type: "error",
          message: "There were problems with booking, please check detailed statuses.",
          duration: DURATION,
        };
  if (
    operationRef.current === "cancel" ||
    details.reservation?.reservedForUser !== data?.data?.onUpdateBookingCommand?.entries?.[0]?.reservedForUser
  ) {
    removeReservation(details.reservation);
    handleClose();
  }
  if (operationRef.current === "edit") {
    updateReservation(details.reservation);
  }
  setLoading(null);
  snackbarService.show(result);
};

const getTimeDetails = (timeRanges) => {
  return timeRanges.map((range) => {
    return `${format(range.dateFrom, "h:mm aaa")} - ${format(range.dateTo, "h:mm aaa")}`;
  });
};

const geBookingDateStr = (timeRanges) => {
  return `${changeDateFormat(timeRanges[0]?.dateFrom, "EEEE, dd-MMM-yyyy")} ${getTimeDetails(timeRanges)?.join(",")}`;
};

const getEmailHref = (details, timeRanges) => {
  return encodeURI(`mailto:${getValue(details?.reservation?.createdByEmail)}
    ?subject=Support needed &body=
    Booking date : ${geBookingDateStr(timeRanges)}
    Description : ${getValue(details?.description)}
    Model : ${getValue(details?.instrument?.equipmentModel)}
    Nickname : ${getValue(details?.instrument?.equipmentNickName)}
    Responsible person : ${getValue(details?.instrument?.responsiblePerson)}
    System status : ${getValue(details?.instrument?.systemStatus)}
    Configuration : ${getValue(details?.instrument?.configurationBaseline)}
    Software version : ${getValue(details?.reservation?.equipment?.softwareVersion)}
    Group : ${getValue(details.reservation?.equipment?.belongingToGroup)}`);
};

const getUser = (details, users) => {
  const user = users?.filter((u) => u["email"]?.toLowerCase() === details?.reservedForEmail?.toLowerCase())?.[0];
  return user?.name || user?.email || details?.reservedForEmail || "-";
};

const popOverJsonStructure = (
  details,
  users,
  isCluster,
  timeRanges,
  allowReservationEdit,
  isWidget,
  clusterSubEquipments = []
) => {
  const widgetJson = {
    item: details?.instrument || {},
    itemWithBookingDetails: details || {},
    status: details.availability,
    headerHeading: reservationAvailability(details, users, isWidget),
    clusterSubEquipmentsHeading: isCluster ? `Cluster components` : "",
    clusterSubEquipments: clusterSubEquipments,
    projectDetails: isWidget
      ? {
          bookedBy: {
            key: `${capitalizeStr(details.availability)} by`,
            value: getUser(details, users),
          },
          bookingTime: {
            key: "Booking time",
            value: geBookingDateStr(timeRanges),
          },
          ...POPOVER_WIDGET_PROJECTDETAILS_EMPTY,
        }
      : {
          ...POPOVER_PROJECTDETAILS_EMPTY,
        },
    detail: isWidget
      ? { ...POPOVER_WIDGET_DETAILS_EMPTY }
      : isCluster
      ? { ...POPOVER_CLUSTER_DETAILS_EMPTY }
      : { ...POPOVER_DETAILS_EMPTY },
    options: {
      hideFooter: true,
      hideHeader: false,
      hideBookingDetails: false,
    },
  };

  if (isWidget) {
    return widgetJson;
  }

  const equipmentJsonObj = {
    headerDetails: {
      dateDetails: changeDateFormat(timeRanges[0]?.dateFrom, "EEEE, dd-MMM-yyyy"),
      timeDetails: getTimeDetails(timeRanges),
    },
    projectHeading: `Booking details`,
    clusterSubEquipmentsHeading: isCluster ? `Cluster components` : "",
    clusterSubEquipments: clusterSubEquipments,
    detailHeading: isCluster ? `Cluster details` : `Equipment details`,
    emailHREF: getEmailHref(details, timeRanges),
    ...widgetJson,
    options: {
      hideFooter: !(allowReservationEdit && !details.isPending),
      // hideFooter: true,
      hideHeader: false,
      hideBookingDetails: false,
      showEmailIcon: !isWidget && details?.reservation && !allowReservationEdit,
    },
  };

  return equipmentJsonObj;
};

const getDetailsInfo = async (
  client,
  details,
  users,
  isCluster,
  timeRanges,
  allowReservationEdit,
  isWidget,
  setEquipmentDetails,
  setEquipmentPopOverOpen,
  setShowLoader
) => {
  if (details) {
    setShowLoader(true);
    setEquipmentPopOverOpen(true);
    let clusterSubEquipments = [];
    if (isCluster) {
      clusterSubEquipments = await getClusterDetailsById({
        client,
        clusterId: details?.instrument?.inventoryId,
      });
    }

    const equipmentInfo = getClusterPopoverInfoObject(
      popOverJsonStructure(details, users, isCluster, timeRanges, allowReservationEdit, isWidget, clusterSubEquipments)
    );
    setEquipmentDetails(equipmentInfo);
    setShowLoader(false);
  }
};

const ReservationPopoverContents = ({
  details,
  handleClose,
  timeRanges,
  dataToDisplay,
  allowReservationEdit,
  cancelDialogProps,
  handleReservedCancel,
  classes,
  openCancelDialog,
  loading,
  handleReservedEdit,
  handleCancel,
  handleAdd,
  bookingCallback,
  dialogProps,
  handleOpen,
  anchorEl,
  setLoading,
  operationRef,
  client,
  email,
  isEquipmentDetails,
  setEquipmentDetails,
  equipmentPopOverOpen,
  setEquipmentPopOverOpen,
  isEventID,
  hideFooter,
  users,
  isCluster,
  isWidget,
}) => {
  const { reasonValue, setReasonValue, showLoader, setShowLoader } = useContext(ReservationPopoverContext);
  const { open } = cancelDialogProps;
  const todayNumber = moment();
  const todayDate = todayNumber.format("YYYY-MM-DD");
  const pastDate = changeDateFormat(details.dateFrom, "yyyy-MM-dd");
  const todayTime = moment().format("HH:MM");
  const oldTime = changeDateFormat(details.dateTo, "h:mm");
  useEffect(() => {
    getDetailsInfo(
      client,
      details,
      users,
      isCluster,
      timeRanges,
      allowReservationEdit,
      isWidget,
      setEquipmentDetails,
      setEquipmentPopOverOpen,
      setShowLoader
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    setReasonValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onReasonChanged = (reason) => {
    setReasonValue(reason);
  };

  const { trackEvent } = useMatomo();

  const handlerOnDropBooking = () => {
    // Track click on button
    trackEvent({ category: "Delete Bookings", action: "click-event" });
    handleReservedCancel();
  };
  useEffect(() => {
    // if (equipmentPopOverOpen) {
    //   setTimeout(() => {
    //     handleAdd(Availability.booked);
    //   }, 1000);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentPopOverOpen]);

  return (
    <>
      {equipmentPopOverOpen && (
        <DLabEquipmentDetailsPopOver
          equipmentDetail={isEquipmentDetails.equipmentDetail}
          loading={showLoader}
          showPopup={equipmentPopOverOpen}
          anchor={isEventID}
          isEditMode={allowReservationEdit && !details.isPending}
          isPendingMode={details.isPending}
          disableFooter={loading || pastDate < todayDate || (pastDate === todayDate && oldTime < todayTime)}
          // TODO : Improved code for date compare for future
          // disableFooter={
          //   loading ||
          //   new Date().getTime() > new Date(details.dateFrom).getTime()
          // }
          handleClickOutSide={() => {
            setEquipmentPopOverOpen(false);
            setEquipmentDetails(DEFAULT_CLUSTER_POPOVER_SKELETON().equipmentDetail);
          }}
          alignment="right"
          handleAction={(event, actionType) => {
            setEquipmentPopOverOpen(false);
            switch (actionType) {
              case Availability.booked:
                handleAdd(Availability.booked);
                return;
              case Availability.maintenance:
                handleAdd(Availability.maintenance);
                return;
              case Availability.reserved:
                handleAdd(Availability.reserved);
                return;
              case "edit":
                handleReservedEdit();
                return;
              case "cancel_booking":
                openCancelDialog();
                return;
              case "cancel":
                handleCancel();
                return;
              default:
                return;
            }
          }}
        />
      )}
      {loading && (
        <BookingNotificationStatus
          onTimeoutCallback={timeoutCallbackFactory()}
          tryTimes={3}
          timeout={5 * 60 * 1000}
          onBookingCallback={bookingCallback}
        />
      )}
      <ConfirmDialog
        {...cancelDialogProps}
        approveText="Drop booking"
        approveColor="primary"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        onApprove={() => handlerOnDropBooking()}
        title="Cancel booking"
        content={`Please add reason for canceling booking`}
        showReasonDD={true}
        reasonValue={reasonValue}
        onReasonChanged={onReasonChanged}
        disableBooking={!reasonValue}
      />
      <ModalToEditReservation
        {...dialogProps}
        item={details?.reservation}
        onSave={handleSaveFactory({
          handleOpen,
          anchorEl,
          details,
          dataToDisplay,
          setLoading,
          operationRef,
          client,
          email,
        })}
      />
    </>
  );
};

const ReservationPopover = ({
  addPendingReservation,
  removeReservation,
  removePendingReservation,
  updateReservation,
  client,
}) => {
  const email = useSelector((state) => state.user.email);
  const users = useSelector((state) => state.users.users);
  const {
    anchorEl,
    handleClose,
    details,
    dataToDisplay,
    allowReservationEdit,
    handleOpen,
    reasonValue,
    isEquipmentDetails,
    setEquipmentDetails,
    equipmentPopOverOpen,
    setEquipmentPopOverOpen,
    isEventID,
    hideFooter,
  } = useContext(ReservationPopoverContext);
  const { openDialog, ...dialogProps } = useDialog();
  const { openDialog: openCancelDialog, ...cancelDialogProps } = useDialog();
  const [loading, setLoading] = useState(null);
  const operationRef = useRef("");
  const timeRanges = getTimeRanges(details);

  const { handleReservedCancel, handleReservedEdit, handleCancel, handleAdd } = useHandlers({
    removePendingReservation,
    details,
    handleClose,
    addPendingReservation,
    setLoading,
    operationRef,
    client,
    email,
    openDialog,
    value: reasonValue,
  });

  const bookingCallback = useCallback(
    (data) => {
      bookingCallbackFn({
        data,
        operationRef,
        details,
        removeReservation,
        handleClose,
        updateReservation,
        setLoading,
      });
    },
    [handleClose, details, removeReservation, updateReservation]
  );
  const { widgetName } = useContext(WidgetContext);
  const isWidget = widgetName === CALENDAR_WIDGET;
  const isCluster = details?.instrument?.entryType === entryType.cluster;
  return (
    <>
      {details && (
        <>
          <ReservationPopoverContents
            details={details}
            handleClose={handleClose}
            timeRanges={timeRanges}
            dataToDisplay={dataToDisplay}
            allowReservationEdit={allowReservationEdit}
            cancelDialogProps={cancelDialogProps}
            handleReservedCancel={handleReservedCancel}
            openCancelDialog={openCancelDialog}
            loading={loading}
            handleReservedEdit={handleReservedEdit}
            handleCancel={handleCancel}
            handleAdd={handleAdd}
            bookingCallback={bookingCallback}
            dialogProps={dialogProps}
            handleOpen={handleOpen}
            anchorEl={anchorEl}
            setLoading={setLoading}
            operationRef={operationRef}
            client={client}
            email={email}
            isEquipmentDetails={isEquipmentDetails}
            setEquipmentDetails={setEquipmentDetails}
            equipmentPopOverOpen={equipmentPopOverOpen}
            setEquipmentPopOverOpen={setEquipmentPopOverOpen}
            isEventID={isEventID}
            hideFooter={hideFooter}
            users={users}
            isCluster={isCluster}
            isWidget={isWidget}
          />
        </>
      )}
    </>
  );
};

export default compose(
  connect(null, {
    addPendingReservation: addPendingReservationAction,
    removeReservation: removeReservationAction,
    removePendingReservation: removePendingReservationAction,
    updateReservation: updateReservationAction,
  }),
  withApollo
)(ReservationPopover);
