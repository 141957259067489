import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getAllMasterFilterData } from "./LoadEquipmentService";
import EquipmentMainPage from "./EquipmentMainPage";
import { withApollo } from "react-apollo";
import { useSelector } from "react-redux";
import { DEFAULT_COLUMNS, DEFAULT_FILTER, DEFAULT_PAGE_SIZE } from "../../constants";
import { useContext } from "react";
import { CustomFilterContext } from "../booking-instruments/booking-instruments-custom-filter/context";

const LoadEquipmentInfo = ({
  client,
  setEquipmentDetailList,
  isStepEnable,
  setNewEquipmentIds,
  setLimitExceedStatus,
  limitExceedStatus,
}) => {
  const [masterDataFilters, setMasterDataFilters] = useState({});
  const [myLabFilter, setMyLabFilter] = useState(null);
  const [lastFavFilter, setLastFavFilter] = useState(DEFAULT_FILTER);
  const [userFilters, setUserFilters] = useState([]);
  const [userDisplayColumn, setUserDisplayColumn] = useState([]);
  const [lastDisplayColumns, setLastDisplayColumns] = useState(DEFAULT_COLUMNS);
  const [lastPageSize, setLastPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [megaClusterIDList, setMegaClusterIDList] = useState([]);

  const { setActiveTab, setSelectedCheckedRows } = useContext(CustomFilterContext);
  const storeUser = useSelector((store) => store.user);
  useEffect(() => {
    setSelectedCheckedRows([]);
    loadFilterMasterData();
    setActiveTab(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadFilterMasterData = async () => {
    const { masterFiltersList, myLabFilterMaster, userDetail, userDisplayColumnMaster, megaClusterIds } =
      await getAllMasterFilterData({
        client: client,
        site: storeUser?.site,
        userID: storeUser?.id,
      });
    setMyLabFilter(() => myLabFilterMaster);
    setMasterDataFilters(() => masterFiltersList);
    setUserFilters(() => userDetail?.userFilters);
    setLastFavFilter(() => userDetail?.lastFavFilter);
    setUserDisplayColumn(() => userDisplayColumnMaster);
    setLastDisplayColumns(() => userDetail?.lastDisplayColumns);
    setMegaClusterIDList(megaClusterIds);
  };

  return (
    Object?.keys(masterDataFilters)?.length > 0 && (
      <>
        <EquipmentMainPage
          masterDataFilters={masterDataFilters}
          myLabFilter={myLabFilter}
          lastFavFilter={lastFavFilter}
          setLastFavFilter={setLastFavFilter}
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          userDisplayColumn={userDisplayColumn}
          setUserDisplayColumn={setUserDisplayColumn}
          lastDisplayColumns={lastDisplayColumns}
          setLastDisplayColumns={setLastDisplayColumns}
          lastPageSize={lastPageSize}
          setLastPageSize={setLastPageSize}
          megaClusterIDList={megaClusterIDList}
          setEquipmentDetailList={setEquipmentDetailList}
          isStepEnable={isStepEnable}
          setNewEquipmentIds={setNewEquipmentIds}
          setLimitExceedStatus={setLimitExceedStatus}
          limitExceedStatus={limitExceedStatus}
        />
      </>
    )
  );
};
export default withApollo(LoadEquipmentInfo);
