import CustomChip from "../../components/shared/CustomChip";
import SelectThemeColor from "../../components/shared/SelectThemeColor";
import { qualificationCalibrationStatus } from "../../constants";

const QualificationCalibrationStatusRender = (params) => {
  const getTextColor = (status) => {
    switch (status) {
      case qualificationCalibrationStatus.qualified:
        return SelectThemeColor.availability.color.qualified;
      case qualificationCalibrationStatus.not_qualified:
        return SelectThemeColor.availability.color.not_qualified;
      case qualificationCalibrationStatus.calibrated:
        return SelectThemeColor.availability.color.calibrated;
      case qualificationCalibrationStatus.not_calibrated:
        return SelectThemeColor.availability.color.not_calibrated;
      case qualificationCalibrationStatus.NA:
        return SelectThemeColor.availability.color.NA;
      default:
        return SelectThemeColor.availability.color.unknown;
    }
  };

  const getBGColor = (status) => {
    switch (status) {
      case qualificationCalibrationStatus.qualified:
        return SelectThemeColor.availability.background.qualified;
      case qualificationCalibrationStatus.not_qualified:
        return SelectThemeColor.availability.background.not_qualified;
      case qualificationCalibrationStatus.calibrated:
        return SelectThemeColor.availability.background.calibrated;
      case qualificationCalibrationStatus.not_calibrated:
        return SelectThemeColor.availability.background.not_calibrated;
      case qualificationCalibrationStatus.NA:
        return SelectThemeColor.availability.background.NA;
      default:
        return SelectThemeColor.availability.background.unknown;
    }
  };
  if (params?.data?.display_qualification_status) {
    const statusText = params?.data?.display_qualification_status[0];
    const textColor = getTextColor(statusText);
    const bgColor = getBGColor(statusText);
    if (statusText) {
      return <CustomChip text={statusText} color={textColor} bgColor={bgColor} bold />;
    } else {
      return "";
    }
  } else {
    return "";
  }
};
export default QualificationCalibrationStatusRender;
