import { OwcIcon, OwcTypography } from "@one/react";
import { useHistory } from "react-router-dom";
import styles from "./EquipmentMainPage.module.scss";
const ScanCodeButton = () => {
  const history = useHistory();
  return (
    <span
      data-testid="book-instrument-button"
      onClick={() => history.push("/scan")}
      className={styles.scanButtonContainer}
    >
      <OwcIcon name="barcode" type="legacy" className={styles.iconSpace} />
      <OwcTypography variant="button" className={styles.scanButton}>
        Scan code
      </OwcTypography>
    </span>
  );
};
export default ScanCodeButton;
