import React from "react";
import SelectItemWrapper from "../../components/shared/SelectItemWrapper";
import NotificationsButton from "./NotificationsButton";
import Dialog from "./Dialog";

const BookingInstrumentNotifications = ({ instrument }) => {
  return (
    <SelectItemWrapper>
      <NotificationsButton instrument={instrument} />
      <Dialog />
    </SelectItemWrapper>
  );
};

export default BookingInstrumentNotifications;
