import { OwcBackdrop, OwcButton, OwcProgressSpinner } from "@one/react/dist";
import React, { useContext, useState } from "react";
import { snackbarService } from "@one/web-components";
import { useFormikContext } from "formik";
import { connect, useSelector } from "react-redux";
import { withApollo, compose } from "react-apollo";
import differenceBy from "lodash/differenceBy";
import _ from "underscore";
import { isEmpty } from "lodash";
import { CREATE_BOOKING_COMMAND, UPDATE_BOOKING } from "../../gql/bookingapi/mutations";
import {
  DURATION,
  UPDATE_REQUEST,
  CANCEL_REQUEST,
  BOOKING_REQUEST,
  BOOKING_ADMIN,
  BACKDROP_ZINDEX,
} from "../../constants";
import { CustomFilterContext } from "../booking-instruments/booking-instruments-custom-filter/context";
import BookingNotificationStatus from "../booking-calendars/booking-notification-status/BookingNotificationStatus";
import { timeoutCallbackFactory } from "../booking-calendars/booking-notification-status/timeoutCallbackFactory";
import { loadPendingReservations as loadPendingReservationsAction } from "../booking-calendars/redux/actions";
import { useHistory } from "react-router-dom";
import { BookingContext } from "../booking/context";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import { hasAllRequiredRoles } from "../../utils/hooks/withRoles";

const BookEquipmentAction = ({
  client,
  disabledSelection,
  setSubscriptionStatus,
  selectedStartDate,
  selectedEndDate,
  setPopupEnableStatus,
}) => {
  const formik = useFormikContext();
  const { selectedList } = useContext(CustomFilterContext);
  const { pendingReservations } = useSelector((state) => state.reservations);
  const storeUser = useSelector((store) => store.user);
  const [fetching, setFetching] = useState(false);
  let pendingReservationsArray = [...pendingReservations];

  const {
    isEditMode,
    editBookingData,
    isRedirectToAdmin,
    dispatchAction: bookingDispatchAction,
  } = useContext(BookingContext);

  const [reasonValue, setReasonValue] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigation = useHistory();

  const onReasonChanged = (reason) => {
    setReasonValue(reason);
  };

  const handleOnclose = () => {
    setIsDialogOpen(false);
    setReasonValue("");
    return true;
  };
  const handlerOnCancelDeleteBooking = () => {
    setIsDialogOpen(false);
    setReasonValue("");
  };

  const handleSend = async () => {
    setFetching(true);
    let pendingReservationsList = [
      ...pendingReservationsArray.map(({ active, color, type, fcolor, id, overlap, ...rest }) => {
        return rest;
      }),
    ];
    pendingReservationsList.forEach((object) => {
      object.dateFrom = object["start"];
      object.dateTo = object["end"];
      object.inventoryId = object["resource"];
      object.bookingEntryId = object["bookingId"];
    });
    let pendingReservationsID = [
      ...pendingReservationsList.map(({ start, end, resource, bookingId, title, ...rest }) => {
        return rest;
      }),
    ];

    const variables = {
      createdBy: storeUser.user,
      createdByEmail: storeUser.email,
      createdByName: storeUser.name,
      entries: pendingReservationsID,
      type: formik.values.bookingType,
      description: formik.values.description,
      project: formik.values.project,
      dateFrom: moment(selectedStartDate).utc().format(),
      dateTo: moment(selectedEndDate).utc().format(),
      site: storeUser.site,
      reservedForEmails: formik.values.bookingFor.map((k) => k.email),
      reservedForUsers: formik.values.bookingFor.map((k) => k.userId),
      elnId: formik.values.elnId,
      force: false,
    };

    try {
      const item = await client.mutate({
        mutation: CREATE_BOOKING_COMMAND,
        fetchPolicy: "no-cache",
        variables,
      });
      if (item.data.createBookingCommand !== null) {
        formik.setValues({
          ...formik.values,
          description: "",
          project: "",
        });
      }
    } catch (error) {
      snackbarService.show({
        message: `Booking created failed!`,
        type: "error",
        duration: DURATION,
      });
    }
  };
  const handleUpdate = async () => {
    setFetching(true);
    const tempEventArray = [];
    editBookingData?.bookingEntries.forEach((item) => {
      const rowFound = pendingReservations.find((x) => x.id === item.id);
      if (rowFound === undefined) {
        tempEventArray.push({
          allDay: false,
          dateFrom: item?.start,
          dateTo: item?.end,
          bookingEntryId: item?.id,
          remark: reasonValue,
          inventoryId: item?.inventoryId,
          commandType: CANCEL_REQUEST,
        });
      }
    });
    pendingReservations.forEach((item) => {
      const rowFound = editBookingData?.bookingEntries.find((x) => x.id === item.id);
      if (rowFound !== undefined) {
        tempEventArray.push({
          allDay: false,
          dateFrom: item?.start,
          dateTo: item?.end,
          inventoryId: item?.inventoryId,
          bookingEntryId: item?.id,
          remark: reasonValue,
          commandType: UPDATE_REQUEST,
        });
      } else {
        tempEventArray.push({
          allDay: false,
          dateFrom: item?.start,
          dateTo: item?.end,
          inventoryId: item?.resource,
          commandType: BOOKING_REQUEST,
        });
      }
    });
    const variables = {
      commandType: UPDATE_REQUEST,
      createdBy: storeUser.user,
      createdByEmail: storeUser.email,
      createdByName: storeUser.name,
      entries: tempEventArray,
      type: formik.values.bookingType,
      description: formik.values.description,
      project: formik.values.project,
      remark: reasonValue,
      bookingId: editBookingData?.id,
      dateFrom: moment(selectedStartDate).utc().format(),
      dateTo: moment(selectedEndDate).utc().format(),
      site: storeUser.site,
      reservedForEmails: formik.values.bookingFor.map((k) => k.email),
      reservedForUsers: formik.values.bookingFor.map((k) => k.userId),
      force: hasAllRequiredRoles(storeUser.roles, [BOOKING_ADMIN]),
    };

    try {
      const item = await client.mutate({
        mutation: UPDATE_BOOKING,
        fetchPolicy: "no-cache",
        variables,
      });
      bookingDispatchAction({
        type: "resetBookingData",
      });
      if (item.data.createBookingCommand !== null) {
        formik.setValues({
          ...formik.values,
          description: "",
          project: "",
        });
      }
    } catch (error) {
      snackbarService.show({
        message: `Booking created failed!`,
        type: "error",
        duration: DURATION,
      });
    }
  };

  const handlerOnAddBooking = async () => {
    // Track click on button
    // trackEvent({ category: "Booking Added", action: "click-event" });
    setPopupEnableStatus(false);
    handleSend();
  };

  const handlerOnEditBooking = async () => {
    setPopupEnableStatus(false);
    handleUpdate();
  };
  const handleOpen = async () => {
    setIsDialogOpen(true);
  };
  const compareValues = () => {
    let valueOne = omitDeep(formik?.values, "bookingFor");
    let valueTwo = omitDeep(formik?.initialValues, "bookingFor");
    const formikDiff = differenceBy(formik?.values?.bookingFor, formik?.initialValues?.bookingFor, "userId");

    const formikDiffres = differenceBy(formik?.initialValues?.bookingFor, formik?.values?.bookingFor, "userId");
    let editReserDateTo = differenceBy(pendingReservations, editBookingData?.bookingEntries, "id", "end");
    let editReserDiff = differenceBy(pendingReservations, editBookingData?.bookingEntries, "id");
    let reservationDiff = differenceBy(editBookingData?.bookingEntries, pendingReservations, "id");
    let editReserDateFrom = differenceBy(pendingReservations, editBookingData?.bookingEntries, "id", "start");
    const checkedEquip = editBookingData?.bookingEntries?.filter((k) =>
      selectedList.length === 1 ? k.inventoryId.indexOf(selectedList) === -1 : !selectedList.includes(k.inventoryId)
    );

    if (editReserDateFrom.length > 0) {
      editReserDateFrom = editReserDateFrom.filter((i) => selectedList.find((k) => k === i.resource));
    }
    if (editReserDateTo.length > 0) {
      editReserDateTo = pendingReservations.filter((i) => selectedList.find((k) => k === i.resource));
    }
    if (editReserDiff.length > 0) {
      editReserDiff = editReserDiff.filter((i) => selectedList.find((k) => k === i.resource));
    }

    return (
      _.isEqual(valueOne, valueTwo) &&
      !formikDiff.length &&
      !formikDiffres.length &&
      !editReserDateTo.length &&
      !editReserDateFrom.length &&
      !editReserDiff.length &&
      !checkedEquip.length &&
      !reservationDiff.length
    );
  };

  return (
    <>
      <>
        {fetching && (
          <OwcBackdrop opacity={0.3} style={{ zIndex: BACKDROP_ZINDEX }}>
            <OwcProgressSpinner />
          </OwcBackdrop>
        )}
        <BookingNotificationStatus
          onTimeoutCallback={() => {
            setFetching(false);
            timeoutCallbackFactory(
              "Problems might have been occurred with booking, please reload the page to see if the bookings has been created."
            );
          }}
          tryTimes={3}
          timeout={5 * 60 * 1000}
          onBookingCallback={(data) => {
            setFetching(false);
            setSubscriptionStatus(data?.data?.onUpdateBookingCommand);
            if (isRedirectToAdmin) {
              navigation.push("/admin");
            } else {
              navigation.push("/bookings");
            }
          }}
        />
        {!isEditMode && (
          <OwcButton
            style={{ float: "right", marginRight: "20px" }}
            data-testid="whom-tab-confrim-button"
            variant="primary"
            onClick={handlerOnAddBooking}
            disabled={!isEmpty(formik.errors) || disabledSelection || pendingReservations.length === 0}
          >
            Confirm
          </OwcButton>
        )}
        {isEditMode && (
          <OwcButton
            style={{ float: "right", marginRight: "20px" }}
            data-testid="whom-tab-confrim-button"
            variant="primary"
            onClick={handleOpen}
            disabled={
              !isEmpty(formik.errors) || disabledSelection || pendingReservations.length === 0 || compareValues(formik)
            }
          >
            Save
          </OwcButton>
        )}
      </>
      <ConfirmDialog
        approveText="Save"
        approveColor="primary"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        onApprove={() => handlerOnEditBooking()}
        title="Edit booking"
        content={`Please select a reason for editing this booking.`}
        showReasonDD={true}
        reasonValue={reasonValue}
        onReasonChanged={onReasonChanged}
        disableBooking={!reasonValue}
        close={handleOnclose}
        open={isDialogOpen}
        onCancel={handlerOnCancelDeleteBooking}
        fullWidth={false}
        maxWidth="sm"
      />
    </>
  );
};

export default compose(
  connect(null, {
    loadPendingReservations: loadPendingReservationsAction,
  }),
  withApollo
)(BookEquipmentAction);
