import * as types from "./actionTypes";
import initialState from "./initialState";

export default function reducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case types.LOAD_USERS:
      return { ...state, users: [...payload] };
    default:
      return state;
  }
}
