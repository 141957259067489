import React from "react";
import { BOOKING_ADMIN } from "../../constants";
import withRoles from "../../utils/hooks/withRoles";
import { OwcIcon, OwcListItem } from "@one/react";

const AdminLinkItem = ({ item, handleRedirect, ...props }) => {
  return (
    <OwcListItem selected={item.isActive} onClick={() => handleRedirect(item)} noBorder {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {typeof item.icon === "string" ? <OwcIcon name={item.icon} type="legacy" /> : <div>{item.icon}</div>}

        <span style={{ marginLeft: 15 }}>{item.text}</span>
      </div>
    </OwcListItem>
  );
};

export default withRoles({
  requiredRoles: [BOOKING_ADMIN],
  ErrorComponent: null,
})(AdminLinkItem);
