import { EN_DASH } from "@digitallab/grid-common-components";

const BooleanRender = (params) => {
  const boolean_fields = ["is_bookable", "is_visualized", "check_in_required"];
  for (const field of boolean_fields) {
    if (params?.column?.colId === field) {
      return <div>{params?.data?.[field]?.[0] ?? EN_DASH}</div>;
    }
  }

  return <div>{"BooleanRender.jsx component is not updated!"}</div>;
};

export default BooleanRender;
