import gql from "graphql-tag";

export const ON_UPDATE_BOOKING_COMMAND = gql`
  subscription ON_UPDATE_BOOKING_COMMAND($createdBy: String) {
    onUpdateBookingCommand(createdBy: $createdBy) {
      processingResult
      id
      createdBy
    }
  }
`;

export const ON_UPDATE_BOOKING_NOTIFICATION_COMMNAD = gql`
  subscription onUpdateBookingNotificationCommand($createdBy: String) {
    onUpdateBookingNotificationCommand(createdBy: $createdBy) {
      id
      inventoryId
      site
      body
      dateFrom
      dateTo
      createdBy
      createdByEmail
      status
    }
  }
`;
