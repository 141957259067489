import { OwcTypography } from "@one/react";
import ScanCodeButton from "./booking-list/ScanCodeButton";
import BookingTabContainer from "./BookingTabContainer";
import { useContext } from "react";
import { BookingContext } from "../booking/context";
import CustomFilterWrapper from "../booking-instruments/booking-instruments-custom-filter/CustomFilterWrapper";
import { useSelector } from "react-redux";
import { BOOKING_POP_UP_WRAPPER_INITALIZE } from "../../constants";
import BookingPopUpWrapper from "../booking/BookingPopUpWrapper";
import { uniq } from "lodash";

const BookEquipmentMainPage = ({ setSubscriptionStatus, setPopupEnableStatus }) => {
  const { isEditMode, editBookingData } = useContext(BookingContext);

  const store = useSelector((store) => store.user);

  return (
    <CustomFilterWrapper
      initialValues={{ site: store?.site }}
      preSelectedList={isEditMode ? uniq(editBookingData?.bookingEntries.map((x) => x.inventoryId)) : []}
    >
      <BookingPopUpWrapper initialValues={BOOKING_POP_UP_WRAPPER_INITALIZE}>
        <div
          style={{
            width: "100%",
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 10px 0px 10px",
            }}
          >
            <OwcTypography variant="title5">{isEditMode ? "Edit event" : "Book equipment"}</OwcTypography>
            <ScanCodeButton />
          </div>
          <BookingTabContainer
            setSubscriptionStatus={setSubscriptionStatus}
            setPopupEnableStatus={setPopupEnableStatus}
          />
        </div>
      </BookingPopUpWrapper>
    </CustomFilterWrapper>
  );
};

export default BookEquipmentMainPage;
