import { useEffect, useState } from "react";
import styled from "styled-components";
import CustomList from "../../../components/shared/List";
import { sortData } from "../../../utils/helpers/text";

export const FixTableMargin = styled.div`
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -17px;
`;

const InstalledTestTable = ({ installedTests }) => {
  const [orderBy, setOrderBy] = useState("name");
  const [isReverseOrder, setIsReverseOrder] = useState(false);
  const [sortedInstalledTests, setSortedInstalledTests] = useState([]);

  useEffect(() => {
    const sorted = sortData(installedTests, [orderBy], isReverseOrder);
    setSortedInstalledTests(sorted);
  }, [installedTests, orderBy, isReverseOrder]);

  const onRequestSort = (key, reverse) => {
    setOrderBy(key);
    setIsReverseOrder(reverse);
  };
  return (
    <FixTableMargin>
      <CustomList
        orderBy={orderBy}
        isReverseOrder={isReverseOrder}
        onRequestSort={onRequestSort}
        data={sortedInstalledTests}
        meta={{
          fields: {
            name: {
              sortable: true,
              text: "Test name",
              headProps: {
                style: {
                  flex: "0 1 80%",
                },
              },
              cellProps: {
                style: {
                  flex: "0 1 80%",
                },
              },
            },
            version: {
              text: "Version of test",
              headProps: {
                style: {
                  flex: "0 1 20%",
                  textAlign: "right",
                },
              },
              cellProps: {
                style: {
                  flex: "0 1 20%",
                  textAlign: "right",
                },
              },
            },
          },
        }}
      />
    </FixTableMargin>
  );
};

export default InstalledTestTable;
