import { get, omit } from "underscore";
import { entryType, equipmentStatus } from "../../constants";
import {
  GET_DIGITAL_LAB_BOOKING_INSTRUMENTS,
  LIST_DIGITAL_LAB_BOOKING_INSTRUMENTS,
  REPO_GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
} from "../../gql/bookingapi/queries";
import { configureAwsAppSync } from "./configureAwsAppSync";
import { API } from "aws-amplify";

const checkObjectInvalidity = (obj) => typeof obj !== "object" || obj === null || Array.isArray(obj);

const getOrFilter = (arrayFilterKeys, arrayFilters) => {
  return arrayFilterKeys
    ?.reduce(
      (acc, key) =>
        arrayFilters[key].length
          ? [
              ...acc,
              arrayFilters[key]?.reduce(
                (acc_i, val) => (typeof val === "string" ? [...acc_i, getGQLFilterObj({ [key]: val })] : acc_i),
                []
              ),
            ]
          : acc,
      []
    )
    .filter((arr) => arr.length);
};
export const createGQLFilterObj = (obj) => {
  if (checkObjectInvalidity(obj)) {
    return null;
  }
  const objKeys = Object.keys(obj);
  if (!objKeys.length) return null;
  const arrayFilters = objKeys.reduce(
    (acc, curr) => (Array.isArray(obj[curr]) ? { ...acc, [curr]: obj[curr] } : acc),
    {}
  );
  const arrayFilterKeys = Object.keys(arrayFilters);
  if (arrayFilterKeys.length) {
    const OrFilter = getOrFilter(arrayFilterKeys, arrayFilters);
    if (!OrFilter.length) return getGQLFilterObj(omit(obj, arrayFilterKeys));
    const simpleFilter = getGQLFilterObj(omit(obj, arrayFilterKeys));
    if (!simpleFilter)
      return {
        and: [...OrFilter?.map((arr) => ({ or: arr }))],
      };
    return {
      and: [...OrFilter?.map((arr) => ({ or: arr })), simpleFilter],
    };
  }
  return getGQLFilterObj(obj);
};

export const getGQLFilterObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) return null;
  const result = Object.keys(obj).reduce((acc, cur) => {
    if (obj?.[cur] === null || obj?.[cur] === undefined) return acc;
    if (typeof obj?.[cur] === "string" && (obj?.[cur]?.trim() === "" || obj?.[cur]?.trim() === "all")) return acc;
    return { ...acc, [cur]: { eq: obj[cur] } };
  }, {});
  return Object.keys(result).length === 0 ? null : result;
};
const checkShouldKeepFetching = (drillData, items, limit, customizedItems) => {
  if (customizedItems) {
    return items.length <= customizedItems;
  }
  return drillData ? drillData : items.length < limit;
};

const returnNextToken = (_nextToken) => {
  return typeof _nextToken === "string" && _nextToken ? _nextToken : null;
};

const get_NextToken = (result, dataPath) => {
  return get(result, [...dataPath, "nextToken"]);
};

const getItems = (page, items) => {
  return Array.isArray(page) ? [...items, ...page] : items;
};

const getPage = (result, dataPath) => {
  return get(result, [...dataPath, "items"]) ?? [];
};

export const getAllData = async ({
  client,
  query,
  fetchPolicy = "network-only",
  variables = {},
  dataPath,
  drillData = false,
  customizedItems = false, // Loop the query for a customized item
  awsAppSyncConfig = null,
}) => {
  const emptyResponse = {
    items: [],
    nextToken: null,
    error: null,
  };
  if (!client || !query || !variables) return emptyResponse;
  if (!Array.isArray(dataPath)) return emptyResponse;
  if (dataPath.length === 0) return emptyResponse;
  try {
    let items = [];
    let nextToken = variables?.nextToken ?? null;
    let shouldKeepFetching = true;
    const { limit = 10 } = variables;
    do {
      let result = [];
      if (awsAppSyncConfig) {
        configureAwsAppSync(awsAppSyncConfig);
        result = await API.graphql({
          client,
          query,
          variables: {
            ...variables,
            nextToken,
          },
        });
      } else {
        result = await client.query({
          query,
          fetchPolicy,
          variables: {
            ...variables,
            nextToken,
          },
        });
      }
      const page = getPage(result, dataPath);
      items = getItems(page, items);
      const _nextToken = get_NextToken(result, dataPath);
      nextToken = returnNextToken(_nextToken);
      shouldKeepFetching = checkShouldKeepFetching(drillData, items, limit, customizedItems);
    } while (nextToken !== null && shouldKeepFetching);
    return {
      items,
      nextToken,
      error: null,
    };
  } catch (err) {
    console.error(err.message);
    return { ...emptyResponse, error: err };
  }
};

export const getAllBookingInstrumentsList = async ({ client, user }) => {
  try {
    const { items } = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_BOOKING_INSTRUMENTS,
      drillData: true,
      fetchPolicy: "no-cache",
      variables: {
        limit: 1000,
        nextToken: null,
        filter: {
          and: [
            { siteName: { eq: user.site } },
            {
              or: [
                { entryType: { eq: entryType?.cluster } },
                { entryType: { eq: entryType?.standaloneEquipment } },
                { entryType: { eq: entryType?.clusterSubequipment } },
              ],
            },
          ],
        },
      },
      dataPath: ["data", "listDigitalLabBookingInstruments"],
    });
    return items;
  } catch (err) {
    console.error(err.message);
    return { error: err };
  }
};

export const getBookingInstrumentsList = async ({ client, user }) => {
  try {
    const { items } = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_BOOKING_INSTRUMENTS,
      drillData: true,
      fetchPolicy: "no-cache",
      variables: {
        limit: 1000,
        nextToken: null,
        filter: {
          and: [
            { siteName: { eq: user.site } },
            { isBookable: { eq: true } },
            {
              or: [
                { entryType: { eq: entryType?.cluster } },
                { entryType: { eq: entryType?.standaloneEquipment } },
                { entryType: { eq: entryType?.clusterSubequipment } },
              ],
            },
            { status: { eq: equipmentStatus?.active?.key } },
          ],
        },
      },
      dataPath: ["data", "listDigitalLabBookingInstruments"],
    });
    return items;
  } catch (err) {
    console.error(err.message);
    return { error: err };
  }
};
export const getFilteredInstrumentList = async ({ client, user, variables }) => {
  try {
    const { items } = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_BOOKING_INSTRUMENTS,
      drillData: true,
      fetchPolicy: "no-cache",
      variables,
      dataPath: ["data", "listDigitalLabBookingInstruments"],
    });
    return items;
  } catch (err) {
    console.error(err.message);
    return { error: err };
  }
};

/**
 * getContainerHeight - Get the dynamic value of containter height
 * @param {string} currentEnv - If env banner is open
 * @param {number} remainingHeight - height of remaining elements in page
 * @return {any} container height : windowSize - remainingHeight
 */
export const getContainerHeight = (currentEnv, remainingHeight) => {
  return currentEnv
    ? window.innerHeight - (126 + remainingHeight) // envBanner + header + footer + pageHeader
    : window.innerHeight - (75 + remainingHeight); //  header + footer + pageHeader
};

export const getContainerHeightPercentage = (currentEnv) => {
  const containerHeight = ((window.innerHeight - (currentEnv ? 80 : 40)) / window.innerHeight) * 100;
  return containerHeight;
};

export const getClusterDetailsById = async ({ client, clusterId = "e8559bd4-ae43-4155-9d45-4104ec523243" }) => {
  const REPO_config = {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_REPO_AWS_GRAPHQL_ENDPOINT,
  };

  configureAwsAppSync(REPO_config);
  try {
    const subEquipment = await API.graphql({
      query: REPO_GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
      client,
      variables: {
        id: clusterId,
      },
    });

    return subEquipment.data.getDigitalLabInstrumentRepositoryEntry.subEquipment.items;
  } catch (err) {
    console.error(err.message);
    return { error: err };
  }
};

export const getEquipment = async ({ inventoryId, client }) => {
  try {
    const result = await client.query({
      query: GET_DIGITAL_LAB_BOOKING_INSTRUMENTS,
      fetchPolicy: "no-cache",
      variables: {
        inventoryId: inventoryId,
      },
    });
    if (result) {
      return result?.data?.getDigitalLabBookingInstrument;
    }
  } catch (err) {
    console.log("booking data check error", err);
  }
};
