import React, { useContext } from "react";
import { AssignGroupContext } from "./context";
import { useFormikContext } from "formik";
import { Tooltip } from "@mui/material";
import { OwcIconButton } from "@one/react";
const Actions = ({ item }) => {
  const { setDialogOpen, setIsEditMode, setLastEditGroup } = useContext(AssignGroupContext);
  const formik = useFormikContext();

  return (
    <div style={{ width: "100%", textAlign: "right" }}>
      <Tooltip
        data-testid={"equipment-edit-button-tooltip" + item.groupName}
        title={"Edit group assign"}
        placement="left"
        arrow
      >
        <OwcIconButton
          key={`booking-admin-table-edit-instrument-button-${item.groupName}`}
          data-testid={`booking-admin-table-edit-instrument-button-${item.groupName}`}
          onClick={() => {
            setIsEditMode(true);
            setLastEditGroup(item);
            formik.resetForm();
            formik.setFieldValue("group", item.groupName, true);
            formik.setFieldValue(
              "equipment",
              item.equipment?.equipmentId ? item.equipment?.equipmentId : item.equipment?.serialNumber,
              true
            );

            setDialogOpen(true);
          }}
          icon="edit"
          type="legacy"
          flat
        />
      </Tooltip>
    </div>
  );
};
export default Actions;
