import { snackbarService } from "@one/web-components";
import { DURATION } from "../../../constants";

export const timeoutCallbackFactory = (
  message = "Problems might have been occurred with booking, please reload the page to see if the changes has been applied."
) => {
  return () => {
    snackbarService.show({
      message: message,
      type: "error",
      duration: DURATION,
    });
  };
};
