import { capitalize } from "lodash";

export const getBookingCategory = (bookingType) => capitalize(bookingType?.split("_")?.[0]) ?? bookingType ?? "";

export const changeActionTypeToDisplay = (actionName) => {
  const actionNameList = actionName?.split("_") ?? [];
  actionNameList.shift();

  return actionNameList
    .map((word) => capitalize(word))
    .join(" ")
    .replace("Of", "of")
    .replace("Tests", "test(s)")
    .replace("Removal Disabling", "Removal/Disabling")
    .replace("Quality Support", "Quality/Support");
};

export const isActionType = (type) => !["Booking", "Reservation", "Run"].includes(type);
