import React, { useState, useEffect, useContext } from "react";
import { getAllData } from "../../../../utils/helpers/fetching";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { PaginationContext } from "../../../../components/shared/pagination/PaginationContext";
import { AssignGroupContext } from "./context";
import { GroupTableMeta } from "./GroupMeta";
import TopBar from "./TopBar";
import { Formik } from "formik";
import { groupValidationSchema } from "./validationSchema";
import { OwcPagination } from "@one/react";
import GroupDialog from "./GroupDialog";
import {
  LIST_BOOKING_ACCESS_CONFIGURATION_BY_EQUIPMENT,
  LIST_BOOKING_ACCESS_CONFIG_BY_EQUIP,
  LIST_BOOKING_GROUP_CONFIGURATION,
} from "../../../../gql/bookingapi/queries";

import {
  CREATE_BOOKING_ACCESS_CONFIGURATION,
  UPDATE_BOOKING_ACCESS_CONFIGURATION,
} from "../../../../gql/bookingapi/mutations";
import CustomList from "../../../../components/shared/List";
import { GroupPaginationStyled } from "./GroupMainPageStyle";
import {
  loadBookingInstruments as loadBookingInstrumentsAction,
  loadUnfilteredBookingInstruments as loadUnfilteredBookingInstrumentsAction,
} from "../../../booking-instruments/redux/actions";
import { snackbarService } from "@one/web-components";
import { DURATION } from "../../../../constants";

const GroupContent = ({ client, user, siteName, loadBookingInstruments, loadUnfilteredBookingInstruments }) => {
  const { dispatchAction, fetching, limit, orderBy, sortDirection, nextData, refreshData } =
    useContext(PaginationContext);
  const { dialogOpen, setDialogOpen, isEditMode, lastEditGroup } = useContext(AssignGroupContext);
  const [mainList, setMainList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [showPerPage, setShowPerPage] = useState(5);
  const [selectedEquipment, setSelectedEquipment] = useState(null);

  const getMainList = async () => {
    let variables = {
      modelName: "BOOKINGACCESSCONFIG",
    };
    if (searchGroup !== "") {
      variables = {
        ...variables,
        filter: { groupName: { contains: searchGroup } },
      };
    }
    const { items } = await getAllData({
      client,
      query: LIST_BOOKING_ACCESS_CONFIGURATION_BY_EQUIPMENT,
      variables,
      fetchPolicy: "network-only",
      dataPath: ["data", "listBookingAccessConfigurationByEquipment"],
      drillData: false,
    });

    items.map((item) => {
      const userList = item.groups?.items[0]?.users.map((x) =>
        x?.givenName && x?.familyName ? x.givenName + " " + x.familyName : x.email
      );
      item.users = userList?.join(", ") || "";
      return item;
    });

    setTotalCount(items.length);
    const lowIndex = pageNo === 1 ? 0 : (pageNo - 1) * showPerPage;
    const highIndex = pageNo === 1 ? showPerPage : lowIndex + showPerPage;
    const paginationData = items.slice(lowIndex, highIndex);
    setMainList(paginationData);
  };
  const getGroupList = async () => {
    const { items } = await getAllData({
      client,
      query: LIST_BOOKING_GROUP_CONFIGURATION,
      fetchPolicy: "network-only",
      dataPath: ["data", "listBookingGroupConfigurations"],
      drillData: true,
      variables: {
        limit: 1000,
      },
    });
    const list = items.filter((item) => item.groupName !== "");
    setGroupList(list);
  };

  const submit = async (data) => {
    //TODO: need to check the functional of the below

    if (!isEditMode) {
      if (selectedEquipment === null) {
        snackbarService.show({
          message: "Invalid equipment.",
          type: "error",
          duration: DURATION,
        });
        return;
      }
      let variablesValidation = { inventoryId: selectedEquipment?.inventoryId };
      const { items } = await getAllData({
        client,
        query: LIST_BOOKING_ACCESS_CONFIG_BY_EQUIP,
        variables: variablesValidation,
        fetchPolicy: "network-only",
        dataPath: ["data", "listBookingAccessConfigurationByEquipment"],
        drillData: true,
      });
      if (items?.length > 0) {
        snackbarService.show({
          message: `Equipment is already assigned to a group.`,
          type: "error",
          duration: DURATION,
        });
        return;
      }
    }
    let variables = {
      inventoryId: isEditMode ? lastEditGroup?.equipment?.inventoryId : selectedEquipment?.inventoryId,
      modelName: "BOOKINGACCESSCONFIG",
      groupName: data.group,
    };
    if (isEditMode) {
      variables = {
        ...variables,
        id: lastEditGroup?.id,
      };
    }

    try {
      await client.mutate({
        mutation: isEditMode ? UPDATE_BOOKING_ACCESS_CONFIGURATION : CREATE_BOOKING_ACCESS_CONFIGURATION,
        variables,
        fetchPolicy: "no-cache",
      });
      dispatchAction({
        type: "clearAll",
      });
      setDialogOpen(false);
      snackbarService.show({
        message: isEditMode ? `Assign group updated successfully! ` : `Assign group created successfully! `,
        type: "success",
        duration: DURATION,
      });
      return;
    } catch (error) {
      setDialogOpen(true);
      snackbarService.show({
        text: isEditMode ? `Assign group updated failed!` : `Assign group created failed! `,
        type: "error",
        duration: DURATION,
      });
    }
  };
  useEffect(() => {
    if (dialogOpen) {
      getGroupList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  useEffect(() => {
    getMainList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchGroup, orderBy, sortDirection, limit, nextData, refreshData, pageNo, showPerPage]);

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Formik
        validationSchema={groupValidationSchema}
        initialValues={{ equipment: "", group: "" }}
        isInitialValid={false}
        validateOnMount={false}
      >
        <>
          <GroupDialog
            open={dialogOpen}
            onCancel={closeDialog}
            isEditMode={isEditMode}
            submit={submit}
            groupList={groupList}
            setSelectedEquipment={setSelectedEquipment}
          />
          <TopBar setSearchGroup={setSearchGroup} />
          <CustomList
            meta={GroupTableMeta}
            data={mainList}
            fieldArray={[]}
            onRequestSort={false}
            isReverseOrder={true}
            orderBy={"name"}
            loading={fetching}
          />
        </>
      </Formik>
      <GroupPaginationStyled>
        <OwcPagination
          onPageChange={(e) => {
            setShowPerPage(e.detail.rowsPerPage);
            setPageNo(e.detail.page);
          }}
          options={[5, 10, 20, 50]}
          rowsPerPage={showPerPage}
          total={Math.ceil(totalCount / showPerPage)}
        >
          <span slot="rows-per-page">Rows per page</span>
        </OwcPagination>
      </GroupPaginationStyled>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
  siteName: state.user.site,
});

export default compose(
  connect(mapStateToProps, {
    loadBookingInstruments: loadBookingInstrumentsAction,
    loadUnfilteredBookingInstruments: loadUnfilteredBookingInstrumentsAction,
  }),
  withApollo
)(GroupContent);
