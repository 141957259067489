import React, { useContext } from "react";
import { ConfirmDialog } from "../../../../components/shared";
import { useFormikContext } from "formik";
import { AssignGroupContext } from "./context";
import styled from "styled-components";
import SuggesterField from "../../../../components/shared/suggester/SuggesterField";
import { EquipmentSearchMeta } from "../../../../constants";
import { useSelector } from "react-redux";
import { OwcButton, OwcEditableDropdown, OwcListItem } from "@one/react";

export const CreateFieldStyled = styled.div`
  display: flex;
  justify-content: left;
  padding-top: 8px;
  padding-bottom: 32px;
  margin-bottom: 20px;
  & > .selectBox .MuiFilledInput-root {
    height: 48px;
  }
  & > .selectBox,
  .TextFieldInput {
    margin: 0;
    margin-right: 20px;
    width: 50%;
    max-width: 100%;
  }
  & > .searchTextMultiSelect {
    width: 50%;
  }
`;

const GroupDialog = ({ onCancel, submit, groupList, setSelectedEquipment }) => {
  const formik = useFormikContext();
  const { dialogOpen, isEditMode } = useContext(AssignGroupContext);

  const bookingInstruments = useSelector((state) => state.bookingInstruments.bookingInstruments);

  const checkIncludes = (serialNumber, userInput) => {
    if (!serialNumber) return false;
    return serialNumber.toLowerCase().includes(userInput.toLowerCase());
  };

  const itmesOnSearchFieldFilter = (items, userInput) => {
    const nonEmptyItems = items.filter((item) => item?.equipmentNickName !== "" && item?.equipmentNickName !== null);

    const sortedItems = nonEmptyItems.sort((a, b) => {
      if (a.equipmentNickName?.toLowerCase() > b.equipmentNickName?.toLowerCase()) {
        return 1;
      } else {
        if (b.equipmentNickName?.toLowerCase() > a.equipmentNickName?.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      }
    });

    return sortedItems.filter((option) => {
      return checkIncludes(option?.serialNumber, userInput) || checkIncludes(option?.equipmentId, userInput);
    });
  };

  const selectSuggestionFn = (instrument) => {
    setSelectedEquipment(instrument);
    formik.setValues({
      ...formik.values,
      equipment:
        instrument?.equipmentId && instrument?.equipmentId !== "null"
          ? instrument?.equipmentId
          : instrument.serialNumber,
    });
  };
  console.log(formik, "formik");
  return (
    <ConfirmDialog
      open={dialogOpen}
      close={onCancel}
      approveText={isEditMode ? "Update" : "Assign"}
      approveColor="primary"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      onCancel={onCancel}
      isDivider
      maxWidth="sm"
      fullWidth
      testid={"import-file-dialog"}
      title={isEditMode ? "Edit assign group" : "Assign equipment"}
      renderActions={({ cancelColor, cancelVariant, approveColor, approveVariant, cancelText, approveText }) => (
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <OwcButton
            data-testid="confirm-dialog-actions-button-cancel"
            onClick={onCancel}
            variant="secondary"
            style={{ marginRight: 32 }}
          >
            {cancelText}
          </OwcButton>
          <OwcButton
            data-testid="confirm-dialog-actions-button-approve"
            onClick={() => {
              submit(formik.values);
            }}
            variant="primary"
            disabled={formik.values?.equipment === "" || formik.values?.group === ""}
          >
            {approveText}
          </OwcButton>
        </div>
      )}
    >
      <CreateFieldStyled>
        <SuggesterField
          meta={EquipmentSearchMeta}
          formik={formik}
          label="Enter serial no or equipment ID"
          name="equipment"
          isDisabled={isEditMode}
          selectSuggestionFn={selectSuggestionFn}
          searchItemList={bookingInstruments}
          itmesOnSearchFieldFilter={itmesOnSearchFieldFilter}
          isEditMode={isEditMode}
        />
        <OwcEditableDropdown
          required={true}
          style={{ width: 250 }}
          name="group"
          label="Select booking group name"
          value={formik.values?.group || null}
          key={formik.values?.group}
          data-testid="booking-filter-admin-reserved-for-email"
          onSelectChange={(e) => {
            let selected = "";
            if (e.detail !== null) {
              selected = groupList.find((x, index) => index === e.detail);
            }
            console.log(e.detail, "selectedValue");
            console.log(selected, "selectedValue");
            formik.setFieldValue("group", selected.groupName, true);
          }}
        >
          {groupList?.map((item, index) => {
            const option = item.groupName;
            return (
              <>
                <OwcListItem value={option} key={option}>
                  {option}
                </OwcListItem>
              </>
            );
          })}
        </OwcEditableDropdown>
      </CreateFieldStyled>
    </ConfirmDialog>
  );
};

export default GroupDialog;
