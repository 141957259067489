import * as yup from "yup";
// const getCustomNullable = (schema, changeTo = "") =>
//   schema.nullable().transform((value) => value ?? changeTo);
export const reasonValidationSchema = yup.object({
  configText: yup
    .string("Enter reason name")
    .max(100, "Maximum 100 characters are allowed")
    .required("Reason is required")
    .typeError("Invalid type")
    .nullable(),
});
