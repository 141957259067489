import React from "react";
import { OwcIconButton, OwcInput } from "@one/react";

const Search = ({ testid = "search", searchQuery, fetching, onChange, onClear, label, placeholder, compact }) => {
  return (
    <>
      <OwcInput
        data-testid={testid}
        label={label}
        placeholder={placeholder}
        id="searchInstrument"
        color="primary"
        variant="filled"
        value={searchQuery}
        disabled={fetching}
        onInputChange={onChange}
        compact={compact}
      >
        <OwcIconButton type="outlined" slot="prefix" icon="search" flat />
        <div slot="suffix">
          {searchQuery && (
            <OwcIconButton type="filled" icon="circle_clear" className="pointer" flat onClick={onClear} />
          )}
        </div>
      </OwcInput>
    </>
  );
};

export default Search;
