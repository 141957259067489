import React, { useState } from "react";
import { AssignGroupContext } from "./context";

const GroupWapper = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [lastEditGroup, setLastEditGroup] = useState(null);

  return (
    <AssignGroupContext.Provider
      value={{
        dialogOpen,
        setDialogOpen,
        isEditMode,
        setIsEditMode,
        lastEditGroup,
        setLastEditGroup,
      }}
    >
      {children}
    </AssignGroupContext.Provider>
  );
};

export default GroupWapper;
