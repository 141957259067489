import React from "react";

const RESUBSCRIBE_AFTER = 15 * 60 * 1000;

class ConnectSubscription extends React.Component {
  unsubscribeRef = null;
  subscribedCount = 0;

  doSubscribe() {
    const {
      documentSubscription,
      dataCallback,
      timeoutCallback,
      tryTimes = 1,
      timeout = RESUBSCRIBE_AFTER,
    } = this.props;
    if (
      !documentSubscription ||
      typeof timeoutCallback !== "function" ||
      typeof dataCallback !== "function" ||
      typeof this.props.refetch !== "function"
    ) {
      return;
    }
    this.subscribedCount++;
    this.unsubscribeRef = this.props.subscribeToMore({
      document: documentSubscription,
      variables: { ...this.props.variables },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        return dataCallback(subscriptionData);
      },
    });

    this.timeoutId = setTimeout(() => {
      this.doUnsubscribe();
      if (this.subscribedCount >= tryTimes) {
        timeoutCallback();
        return;
      }
      this.props.refetch();
      this.doSubscribe();
    }, timeout);
  }

  doUnsubscribe() {
    if (typeof this.unsubscribeRef === "function") {
      this.unsubscribeRef();
    }
  }

  cancelTimeout() {
    clearTimeout(this.timeoutId);
  }

  componentDidMount() {
    this.doSubscribe();
  }
  componentWillUnmount() {
    this.doUnsubscribe();
    this.cancelTimeout();
  }

  render() {
    return null;
  }
}

export default ConnectSubscription;
