import React, { useCallback, useState } from "react";
import { debounce } from "lodash";
import { DEBOUNCE_DELAY } from "../../constants";
import { OwcIconButton, OwcInput } from "@one/react";

const Search = ({ setQuery, label, testid = "search", fetching = false }) => {
  const [searchQuery, setSearchQuery] = useState("");

  /* eslint-disable react-hooks/exhaustive-deps */

  const delayedQuery = useCallback(
    debounce((q) => {
      setQuery(q);
    }, DEBOUNCE_DELAY), // updated for 1 sec

    [setQuery]
  );

  const onChange = (e) => {
    setSearchQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  const onClear = () => {
    setQuery("");
    setSearchQuery("");
  };

  return (
    <OwcInput
      data-testid={testid}
      label={label}
      placeholder={label}
      id="searchInstrument"
      color="primary"
      variant="filled"
      compact
      value={searchQuery}
      disabled={fetching}
      onInputChange={onChange}
    >
      <OwcIconButton type="outlined" slot="prefix" icon="search" flat />
      <div slot="suffix">
        {searchQuery && (
          <OwcIconButton
            data-testid="clear-search-button"
            type="filled"
            icon="circle_clear"
            flat
            onClick={() => onClear()}
          />
        )}
      </div>
    </OwcInput>
  );
};

export default Search;
