export const LOAD_RESERVATIONS = "[RESERVATIONS] LOAD_RESERVATIONS";

export const REMOVE_RESERVATION = "[RESERVATIONS] REMOVE_RESERVATION";

export const LOAD_PENDING_RESERVATIONS = "[RESERVATIONS] LOAD_PENDING_RESERVATIONS";

export const ADD_PENDING_RESERVATION = "[RESERVATIONS] ADD_PENDING_RESERVATION";

export const REMOVE_PENDING_RESERVATION = "[RESERVATIONS] REMOVE_PENDING_RESERVATION";

export const UPDATE_PENDING_RESERVATION = "[RESERVATIONS] UPDATE_PENDING_RESERVATION";

export const UPDATE_RESERVATION = "[RESERVATIONS] UPDATE_RESERVATION";

export const BULK_UPDATE_PENDING_RESERVATIONS = "[RESERVATIONS] BULK_UPDATE_PENDING_RESERVATIONS";

export const REPLACE_PENDING_RESERVATIONS_FOR_INSTRUMENT = "[RESERVATIONS] REPLACE_PENDING_RESERVATIONS_FOR_INSTRUMENT";
