import { useContext } from "react";
import styled from "styled-components";
import { CalendarTypeContext } from "../../../booking-calendars/calendar-type-context/context";
import TodayButton from "../../../booking-calendars/calendars-main-page/TodayButton";
import BookingInstrumentNotifications from "../../../booking-instrument-notifications/BookingInstrumentNotifications";
import MonthSwitcher from "./MonthSwitcher";

const TopBarForDetailedInfoCalendarStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexBox = styled.div`
  display: flex;
`;
const ActionsFlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
  & button:first-of-type {
    margin-right: 0.5rem;
  }
`;

const TopBarForDetailedInfoCalendar = ({ instrument }) => {
  const { setDate } = useContext(CalendarTypeContext);

  return (
    <TopBarForDetailedInfoCalendarStyled data-testid="detailed-info-for-instrument-top-bar-for-calendar-section">
      <FlexBox>
        <TodayButton setDate={setDate} />
        <MonthSwitcher />
      </FlexBox>
      <ActionsFlexBox>
        <BookingInstrumentNotifications instrument={instrument} />
      </ActionsFlexBox>
    </TopBarForDetailedInfoCalendarStyled>
  );
};

export default TopBarForDetailedInfoCalendar;
