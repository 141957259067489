import React from "react";
import { OwcChip } from "@one/react";

const SlotDetailCellRenderer = ({ data, node, api }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 16,
        padding: "16px 0",
        paddingLeft: 40,
        border: "none",
        backgroundColor: "#F5F5F2",
        backgroundImage: "conic-gradient(at 30px 10%, #FFFFFF 180deg, #F5F5F2 180deg)",
      }}
    >
      {data.bookedSlots.map((x) => (
        <OwcChip outlined round>
          {x}
        </OwcChip>
      ))}
    </div>
  );
};

export default SlotDetailCellRenderer;
