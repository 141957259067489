import React from "react";
import { OwcButton } from "@one/react";

export const ClearAll = ({ onClickCallback, disabled }) => {
  return (
    <OwcButton
      variant="secondary"
      data-testid="booking-filter-clear-all-button"
      onClick={onClickCallback}
      disabled={disabled}
    >
      Reset all
    </OwcButton>
  );
};

export default ClearAll;
