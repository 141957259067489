import { useState } from "react";

const useDialog = (isOpen = false) => {
  const [open, setOpen] = useState(isOpen);

  const close = () => {
    setOpen(false);
  };
  const openDialog = () => {
    setOpen(true);
  };

  return {
    open,
    close,
    openDialog,
  };
};
export default useDialog;
