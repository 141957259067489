const BookEquipmentIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_143_13510)">
      <path
        d="M6.66751 5.32004V7.29004M8.41376 2.88879H20.71C20.9362 2.88863 21.1602 2.93302 21.3693 3.01943C21.5783 3.10585 21.7683 3.23259 21.9284 3.39242C22.0884 3.55225 22.2155 3.74205 22.3022 3.95097C22.3889 4.15989 22.4336 4.38384 22.4338 4.61004V7.29004H6.69001V4.61004C6.69018 4.38384 6.73489 4.15989 6.82161 3.95097C6.90832 3.74205 7.03534 3.55225 7.1954 3.39242C7.35547 3.23259 7.54545 3.10585 7.75449 3.01943C7.96354 2.93302 8.18756 2.88863 8.41376 2.88879Z"
        stroke="#706B69"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.445 9.06254V7.44379C22.445 7.35879 22.395 7.29004 22.3325 7.29004H1.66749C1.60499 7.29004 1.55499 7.35879 1.55499 7.44379V16.9575C1.55499 17.0425 1.60499 17.1113 1.66749 17.1113H8.37499"
        stroke="#706B69"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip1_143_13510)">
        <path
          d="M11 12L12 12M11 15H12"
          stroke="#706B69"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 15L12 15M11 18H12"
          stroke="#706B69"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 12L20 12M15 15H20"
          stroke="#706B69"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 15L20 15M15 18H20"
          stroke="#706B69"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_143_13510">
        <rect width="24" height="24" fill="white" />
      </clipPath>
      <clipPath id="clip1_143_13510">
        <rect width="13.5" height="13.5" fill="white" transform="translate(10 9.25)" />
      </clipPath>
    </defs>
  </svg>
);
export default BookEquipmentIcon;
