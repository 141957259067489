import { useState } from "react";
import { TabsForDetailedInfoContext } from "./context";

const TabsForDetailedInfoWrapper = ({ children }) => {
  const [tabView, setTabView] = useState("overView");

  return (
    <TabsForDetailedInfoContext.Provider
      value={{
        tabView,
        setTabView,
      }}
    >
      {children}
    </TabsForDetailedInfoContext.Provider>
  );
};

export default TabsForDetailedInfoWrapper;
