import styled from "styled-components";
import { SerialNumberRow } from "../booking-calendars/describe-task-page/InfoAboutInstrumentRow";

const WhiteCard = styled.div`
  background: #ffffff;
  border-radius: 4px;
  margin: 1rem;
  margin-bottom: 24px;
  padding: 24px;
`;
const TitleForTable = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  width: 100%;
  margin: 21px 16px;
  margin-bottom: 5px;
`;
const SerialNumberRowForDetailed = styled(SerialNumberRow)`
  margin-left: 0;
  margin-bottom: 24px;
`;
const FrameForInfo = styled.div`
  border: 1px solid #d3d3d3;
`;

const SkeletonForTestAndDocs = ({ shortIdentifier, titleForTable, nameForDataTestid, children }) => {
  return (
    <WhiteCard data-testid={`detailed-info-for-instrument-${nameForDataTestid}-card`}>
      <SerialNumberRowForDetailed data-testid={`detailed-info-for-instrument-${nameForDataTestid}-card-serial-number`}>
        {shortIdentifier}
      </SerialNumberRowForDetailed>
      <FrameForInfo>
        <TitleForTable data-testid={`detailed-info-for-instrument-${nameForDataTestid}-card-title-for-table`}>
          {titleForTable}
        </TitleForTable>
        {children}
      </FrameForInfo>
    </WhiteCard>
  );
};

export default SkeletonForTestAndDocs;
