import { useState } from "react";
import { SelectItemContext } from "../../contexts";

export const SelectItemWrapper = ({ children, initalItem = null }) => {
  const [item, setItem] = useState(initalItem);

  const [disableBooking, setDisableBooking] = useState(true);

  const [reasonValue, setReasonValue] = useState("");

  return (
    <SelectItemContext.Provider
      value={{
        setItem,
        item,
        disableBooking,
        setDisableBooking,
        reasonValue,
        setReasonValue,
      }}
    >
      {children}
    </SelectItemContext.Provider>
  );
};

export default SelectItemWrapper;
