export const initalState = {
  availability: "",
  description: "",
  project: "",
  reservedForEmail: "",
  reservedForUser: "",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "availability":
      return { ...state, availability: action.payload };
    case "description":
      return { ...state, description: action.payload };
    case "project":
      return { ...state, project: action.payload };
    case "reservedForEmail":
      return { ...state, reservedForEmail: action.payload };
    case "reservedForUser":
      return { ...state, reservedForUser: action.payload };
    case "setAll":
      return {
        availability: action.payload?.availability ?? "",
        description: action.payload?.description ?? "",
        project: action.payload?.project ?? "",
        reservedForUser: action.payload?.reservedForUser ?? "",
        reservedForEmail: action.payload?.reservedForEmail ?? "",
      };
    default:
      return state;
  }
};
