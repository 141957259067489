import * as R from "ramda";
// run statusess
export const RunStatusesError = {
  error: "error",
  aborted: "aborted",
};

export const RunStatusesSuccess = {
  normal: "normal",
  finished: "finished",
};

// detailed statusess
export const ErrorStatusesDetailed = {
  error: "error",
  stopping: "stopping",
};
export const NormalStatusesDetailed = {
  startingup: "startingup",
  initializing: "initializing",
  standby: "standby",
  preparing: "preparing",
  ready: "ready",
  processing: "processing",
};

export const ServiceStatusesDetailed = {
  service: "service",
  maintenance: "maintenance",
};

export const UnknownStatusesDetailed = {
  poweroff: "poweroff",
  shutdown: "shutdown",
  shuttingdown: "shuttingdown",
  poweredoff: "poweredoff",
};

// Result statuses
export const STATUSES = {
  ERROR: "ERROR",
  SERVICE: "SERVICE",
  NORMAL: "NORMAL",
  UNKNOWN: "UNKNOWN",
};

export const getStatus = (statuses = []) => {
  const errors = [...Object.keys(ErrorStatusesDetailed)];
  const unknownStatuses = [...Object.keys(UnknownStatusesDetailed)];
  const normalStatuses = [...Object.keys(NormalStatusesDetailed)];
  const serviceStatuses = [...Object.keys(ServiceStatusesDetailed)];

  if (getIntersection(statuses, errors).length) return STATUSES.ERROR;
  if (getIntersection(statuses, unknownStatuses).length) return STATUSES.UNKNOWN;

  if (getIntersection(statuses, normalStatuses).length) return STATUSES.NORMAL;
  if (getIntersection(statuses, serviceStatuses).length) return STATUSES.SERVICE;
  return STATUSES.UNKNOWN;
};

const getData = (arr) => R.compose(R.map(R.compose(R.trim, R.toLower)))(arr);

export const getIntersection = (values = [], statuses = []) => {
  if (!Array.isArray(values) || !Array.isArray(statuses)) return [];
  return R.intersection(getData(values), getData(statuses));
};
