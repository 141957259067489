import React, { useReducer, useState } from "react";
import moment from "moment";
import { CustomFilterContext } from "./context";
import { DEFAULT_SITE } from "../../../constants";

export const initialState = {
  dateFrom: moment().startOf("isoWeek").format("YYYY-MM-DD"),
  dateTo: moment().endOf("isoWeek").subtract(2, "days").format("YYYY-MM-DD"),
  site: DEFAULT_SITE,
  bookType: "",
  reservedForEmail: "",
  equipmentModel: "",
  active: "",
  room: "",
  building: "",
};

export function reducer(state = initialState, action = { type: "", payload: "" }) {
  switch (action.type) {
    case "setDateFrom":
      return {
        ...state,
        dateFrom: action.payload,
      };
    case "setDateTo":
      return {
        ...state,
        dateTo: action.payload,
      };
    case "setDateRange":
      return {
        ...state,
        dateFrom: action.payload?.dateFrom,
        dateTo: action.payload?.dateTo,
        sortDirection: action.payload.sortDirection ? "ASC" : "DESC",
      };
    case "setSite":
      return {
        ...state,
        site: action.payload,
      };
    case "setBookType":
      return {
        ...state,
        bookType: action.payload,
      };
    case "setReservedForEmail":
      return {
        ...state,
        reservedForEmail: action.payload,
      };
    case "setInstrumentType":
      return {
        ...state,
        equipmentModel: action.payload,
      };
    case "setActive":
      return {
        ...state,
        active: action.payload,
      };
    case "setRoom":
      return {
        ...state,
        room: action.payload,
      };
    case "setBuilding":
      return {
        ...state,
        building: action.payload,
      };
    case "clearAll":
      return {
        ...initialState,
        site: state.site,
      };
    default:
      return state;
  }
}

const CustomFilterWrapper = ({ children, initialValues, preSelectedList = [] }) => {
  const [hideFilter, setHideFilter] = useState(false);
  const [selectedCheckedRows, setSelectedCheckedRows] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [equipmentLoading, setEquipmentLoading] = useState(false);
  const [hideStepper, setHideStepper] = useState(false);
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues,
  });
  const [fetching, setFetching] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [showSummaryTitle, setShowSummaryTitle] = useState(false);
  const [selectedList, setSelectedList] = useState(preSelectedList);

  const handleNextTab = () => {
    setActiveTab((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <CustomFilterContext.Provider
      value={{
        ...state,
        dispatchAction,
        hideFilter,
        setHideFilter,
        selectedCheckedRows,
        setSelectedCheckedRows,
        activeTab,
        setActiveTab,
        equipmentLoading,
        setEquipmentLoading,
        handleNextTab,
        hideStepper,
        setHideStepper,
        fetching,
        setFetching,
        showDateRange,
        setShowDateRange,
        showSummaryTitle,
        setShowSummaryTitle,
        selectedList,
        setSelectedList,
      }}
    >
      {children}
    </CustomFilterContext.Provider>
  );
};

export default CustomFilterWrapper;
