const NextArrowButton = (props) => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_9929_93852)">
      <g clipPath="url(#clip1_9929_93852)">
        <path d="M17.6984 14L16.2984 15.4L20.8984 20L16.2984 24.6L17.6984 26L23.6984 20L17.6984 14Z" fill="#737373" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9929_93852">
        <rect width="40.5387" height="40" fill="white" transform="translate(0.0336914)" />
      </clipPath>
      <clipPath id="clip1_9929_93852">
        <rect width="24" height="24" fill="white" transform="translate(8.03375 8)" />
      </clipPath>
    </defs>
  </svg>
);
export default NextArrowButton;
