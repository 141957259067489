import { compareInstrument } from "../../booking-instruments/booking-instruments-selected-context/BookingInstrumentsSelectedWrapper";
import * as types from "./actionTypes";
import initialState from "./initialState";

function updatePendingReservation(reservations, payload) {
  return reservations.map((reservation) => {
    if (
      compareInstrument(reservation, payload) &&
      reservation.dateFrom === payload.dateFrom &&
      reservation.dateTo === payload.dateTo
    ) {
      return payload;
    }
    return reservation;
  });
}

function removePendingReservation(reservations, payload) {
  return reservations.reduce((acc, reservation) => {
    const sameInstrument = compareInstrument(reservation, payload);
    if (!sameInstrument || doesNotOverlap(reservation, payload)) {
      acc.push(reservation);
      return acc;
    }
    if (isInsideRemoval(reservation, payload)) {
      return acc;
    }
    if (doesOverlapFromLeft(reservation, payload)) {
      acc.push({
        ...reservation,
        dateTo: payload.dateFrom,
      });
      return acc;
    }
    if (doesOverlapFromRight(reservation, payload)) {
      acc.push({
        ...reservation,
        dateFrom: payload.dateTo,
      });
      return acc;
    }
    if (isInsideReservation(reservation, payload)) {
      acc.push({
        ...reservation,
        dateTo: payload.dateFrom,
      });
      acc.push({
        ...reservation,
        dateFrom: payload.dateTo,
      });
      return acc;
    }
    console.warn("unexpected?");
    return acc;
  }, []);
}

const handleLoadReservations = (state, payload) => {
  return { ...state, reservations: payload };
};

const handleRemoveReservation = (state, payload) => {
  return {
    ...state,
    reservations: state.reservations.filter((reservation) => {
      return !(
        compareInstrument(reservation, payload) &&
        reservation.dateFrom === payload.dateFrom &&
        reservation.dateTo === payload.dateTo
      );
    }),
  };
};

const handleLoadPendingReservations = (state, payload) => {
  return { ...state, pendingReservations: payload };
};

const handleAddPendingReservation = (state, payload) => {
  return {
    ...state,
    pendingReservations: [...state.pendingReservations, payload],
  };
};

const handleRemovePendingReservation = (state, payload) => {
  return {
    ...state,
    pendingReservations: removePendingReservation(state.pendingReservations, payload),
  };
};

const handleUpdatePendingReservation = (state, payload) => {
  return {
    ...state,
    pendingReservations: updatePendingReservation(state.pendingReservations, payload),
  };
};

const handleUpdateReservation = (state, payload) => {
  return {
    ...state,
    reservations: state.reservations.map((reservation) => {
      if (
        compareInstrument(reservation, payload) &&
        reservation.dateFrom === payload.dateFrom &&
        reservation.dateTo === payload.dateTo
      ) {
        return { ...payload };
      }
      return reservation;
    }),
  };
};

const handleReplacePendingReservationsForInstrument = (state, payload) => {
  const firstReservationForInstrumentIndex = state.pendingReservations.findIndex((reservation) =>
    compareInstrument(reservation, payload.instrument)
  );
  const newReservations = state.pendingReservations.filter(
    (reservation) => !compareInstrument(reservation, payload.instrument)
  );
  newReservations.splice(firstReservationForInstrumentIndex, 0, ...payload.reservations);
  return {
    ...state,
    pendingReservations: newReservations,
  };
};

const handleBulkUpdatePendingReservations = (state, payload) => {
  let newPendingReservations = state.pendingReservations;

  if (payload.update?.length > 0) {
    newPendingReservations = payload.update.reduce(
      (acc, toUpdate) => updatePendingReservation(acc, toUpdate),
      newPendingReservations
    );
  }
  if (payload.remove?.length > 0) {
    newPendingReservations = payload.remove.reduce(
      (acc, toRemove) => removePendingReservation(acc, toRemove),
      state.pendingReservations
    );
  }
  if (payload.add?.length > 0) {
    newPendingReservations.push(...payload.add);
  }

  return {
    ...state,
    pendingReservations: newPendingReservations,
  };
};

function isInsideReservation(reservation, payload) {
  return reservation.dateFrom < payload.dateFrom && reservation.dateTo > payload.dateTo;
}

function doesOverlapFromRight(reservation, payload) {
  return reservation.dateTo > payload.dateTo && reservation.dateFrom >= payload.dateFrom;
}

function doesOverlapFromLeft(reservation, payload) {
  return reservation.dateFrom < payload.dateFrom && reservation.dateTo <= payload.dateTo;
}

function isInsideRemoval(reservation, payload) {
  return reservation.dateFrom >= payload.dateFrom && reservation.dateTo <= payload.dateTo;
}

function doesNotOverlap(reservation, payload) {
  return reservation.dateFrom > payload.dateTo || reservation.dateTo < payload.dateFrom;
}

export default function reducer(state = initialState, { type, payload } = {}) {
  switch (type) {
    case types.LOAD_RESERVATIONS:
      return handleLoadReservations(state, payload);
    case types.REMOVE_RESERVATION:
      return handleRemoveReservation(state, payload);
    case types.LOAD_PENDING_RESERVATIONS:
      return handleLoadPendingReservations(state, payload);
    case types.ADD_PENDING_RESERVATION:
      return handleAddPendingReservation(state, payload);
    case types.REMOVE_PENDING_RESERVATION:
      return handleRemovePendingReservation(state, payload);
    case types.UPDATE_PENDING_RESERVATION:
      return handleUpdatePendingReservation(state, payload);
    case types.UPDATE_RESERVATION:
      return handleUpdateReservation(state, payload);
    case types.BULK_UPDATE_PENDING_RESERVATIONS:
      return handleBulkUpdatePendingReservations(state, payload);
    case types.REPLACE_PENDING_RESERVATIONS_FOR_INSTRUMENT:
      return handleReplacePendingReservationsForInstrument(state, payload);
    default:
      return state;
  }
}
