import { parse, setHours } from "date-fns";
import { groupBy, uniq } from "underscore";
import { CALENDAR_DAY_VIEW_END_HOUR, CALENDAR_DAY_VIEW_START_HOUR } from "../../../../constants";
import { allInRange } from "../../../booking-calendars/week-calendar/helpers";
import { changeDateFormat } from "../../../../utils/helpers/text";

export const reservationsToEvents = (reservations) => {
  const reservationsWithAllDay = [...reservations];
  const groupedReservations = groupBy(reservations, "date");
  const allDayReservationsDates = [];

  Object.entries(groupedReservations).forEach(([date, dayReservations]) => {
    if (uniq(dayReservations, false, (res) => res.availability).length > 1) {
      return;
    }

    if (uniq(dayReservations, false, (res) => res.reservedForEmail).length > 1) {
      return;
    }

    const dateFrom = setHours(parse(date, "yyyy-MM-dd", new Date()), CALENDAR_DAY_VIEW_START_HOUR);
    const dateTo = setHours(parse(date, "yyyy-MM-dd", new Date()), CALENDAR_DAY_VIEW_END_HOUR);

    if (allInRange(dayReservations, changeDateFormat(dateFrom, "ISO"), changeDateFormat(dateTo, "ISO"))) {
      allDayReservationsDates.push(date);
      dayReservations.slice(1).forEach((reservation) => {
        reservationsWithAllDay.splice(reservationsWithAllDay.indexOf(reservation), 1);
      });
    }
  });

  return reservationsWithAllDay.map((reservation) => {
    const start = changeDateFormat(reservation.dateFrom, "ISOToDate");
    const end = changeDateFormat(reservation.dateTo, "ISOToDate");
    return {
      start,
      end,
      id: `${reservation.dateFrom}${reservation.dateTo}${reservation.materialNumber}${reservation.serialNumber}`,
      allDay: allDayReservationsDates.includes(reservation.date),
      description: reservation.description,
      project: reservation.project,
      resource: {
        id: reservation.id,
        availability: reservation.availability,
        reservedForEmail: reservation.reservedForEmail,
        date: reservation.date,
      },
    };
  });
};
