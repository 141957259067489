import CustomChip from "../../components/shared/CustomChip";
import SelectThemeColor from "../../components/shared/SelectThemeColor";
import { Availability } from "../booking-instruments/InstrumentAvailability";
import { capitalizeStr } from "../../utils/helpers/text";

const InstrumentAvailabilityRender = (params) => {
  const getDescription = (instrumentAvailability) => {
    const description = instrumentAvailability.split("_")[0];
    if (Object.keys(Availability).includes(description)) {
      return <>{capitalizeStr(description)}</>;
    }
    return <>{"Unknown state"}</>;
  };

  const getTextColor = (instrumentAvailability) => {
    const description = instrumentAvailability.split("_")[0];
    if (Object.keys(Availability).includes(description)) {
      switch (description) {
        case Availability.available:
          return SelectThemeColor.availability.new.color.available;
        case Availability.booking:
        case Availability.run:
          return SelectThemeColor.availability.new.color.active;
        default:
          return SelectThemeColor.availability.new.color.others;
      }
    }
    return SelectThemeColor.availability.color.unknown;
  };

  const getBGColor = (instrumentAvailability) => {
    const description = instrumentAvailability.split("_")[0];
    if (Object.keys(Availability).includes(description)) {
      switch (description) {
        case Availability.available:
          return SelectThemeColor.availability.new.background.available;
        case Availability.booking:
        case Availability.run:
          return SelectThemeColor.availability.new.background.active;
        default:
          return SelectThemeColor.availability.new.background.others;
      }
    }
    return SelectThemeColor.availability.background.unknown;
  };

  const statusText = params?.data?.instrument_availability?.[0]?.toLowerCase() ?? "available";
  const bookedBy = params?.data?.booked_by ? params?.data?.booked_by[0] : "";
  const desc = getDescription(statusText, bookedBy);
  const textColor = getTextColor(statusText);
  const bgColor = getBGColor(statusText);
  if (statusText) {
    return <CustomChip text={desc} color={textColor} bgColor={bgColor} bold />;
  } else {
    return "";
  }
};
export default InstrumentAvailabilityRender;
