import { useContext, useEffect, useState } from "react";
import InstalledTestsMainPage from "./Installed-tests/InstalledTestsMainPage";
import LinkedDocumentsMainPage from "./linked-documents/LinkedDocumentsMainPage";
import BreadcrumbBar from "./BreadcrumbBar";
import OverviewMainPage from "./overview/OverviewMainPage";
import { TabsForDetailedInfoContext } from "./tabs-for-detailed-info-context/context";
import TabForDetailedInfo from "./TabForDetailedInfo";
import styled from "styled-components";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { getEquipment } from "../../utils/helpers/fetching";
import { withApollo } from "react-apollo";
import { OwcProgressSpinner } from "@one/react";
import { CustomFilterContext } from "../booking-instruments/booking-instruments-custom-filter/context";

const InstrumentDetailedInfoMainPageStyled = styled.div`
  width: 100%;
  flex-grow: 1;
`;

const InstrumentDetailedInfoMainPage = ({ client }) => {
  const location = useLocation();
  let { instrument: instrumentParam } = location.state || {};
  const { tabView } = useContext(TabsForDetailedInfoContext);
  const { inventoryId } = useParams();
  const [instrument, setInstrument] = useState({});
  const { setEquipmentLoading, equipmentLoading } = useContext(CustomFilterContext);
  useEffect(() => {
    getBookingInstrumentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getBookingInstrumentList = async () => {
    setEquipmentLoading(true);
    const instrumenData = await getEquipment({
      inventoryId: inventoryId || instrumentParam.inventoryId,
      client,
    });

    if (instrumenData === null) {
      return <Redirect to="/" />;
    }

    setInstrument(instrumenData);
    setEquipmentLoading(false);
  };
  return (
    <InstrumentDetailedInfoMainPageStyled data-testid="detailed-info-for-instrument-main-page">
      <TabForDetailedInfo />
      <BreadcrumbBar shortIdentifier={instrument?.shortIdentifier} />
      {equipmentLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OwcProgressSpinner diameter={100} />
        </div>
      )}
      {tabView === "overView" && <OverviewMainPage instrument={instrument} />}
      {tabView === "installedTests" && <InstalledTestsMainPage instrument={instrument} />}
      {tabView === "linkedDocuments" && <LinkedDocumentsMainPage instrument={instrument} />}
    </InstrumentDetailedInfoMainPageStyled>
  );
};

export default withApollo(InstrumentDetailedInfoMainPage);
