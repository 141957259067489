import React from "react";
import { DEFAULT_LIMIT } from "../../../../constants";
import styled from "styled-components";
import GroupWapper from "./GroupWapper";
import PaginationWrapper from "../../../../components/shared/pagination/PaginationWrapper";
import GroupContent from "./GroupContent";
import { Grid } from "@mui/material";

// import GroupContent from "./GroupContent";
const MainContainer = styled.div`
  margin-top: 0.25rem;
  width: 100%;
`;

const GroupMainPage = () => {
  const initialValues = {
    fetching: false,
    prevTokens: [],
    nextToken: null,
    nextData: 0,
    limit: DEFAULT_LIMIT,
    data: [],
    sortDirection: "ASC",
    orderBy: "groupName",
    refreshData: 0,
  };

  return (
    <MainContainer>
      <GroupWapper>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <PaginationWrapper initialValues={initialValues}>
              <GroupContent />
            </PaginationWrapper>
          </Grid>
        </Grid>
      </GroupWapper>
    </MainContainer>
  );
};

export default GroupMainPage;
