import styled from "styled-components";
import CalendarSectionForDetailedInfo from "./detailed-calendar/CalendarSectionForDetailedInfo";
import InfoColumnForDetailedInfo from "./info-column/InfoColumnForDetailedInfo";

const OverViewMainPageStyled = styled.div`
  background: #ffffff;
  border-radius: 4px;
  margin: 1rem;
  margin-bottom: 24px;
  padding: 24px;
  display: flex;
`;

const OverviewMainPage = ({ instrument }) => {
  return (
    <OverViewMainPageStyled data-testid="detailed-info-for-instrument-overview-card">
      <InfoColumnForDetailedInfo instrument={instrument} />
      <CalendarSectionForDetailedInfo instrument={instrument} />
    </OverViewMainPageStyled>
  );
};

export default OverviewMainPage;
