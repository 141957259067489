import React, { useState, useContext } from "react";
import styled from "styled-components";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TogglerContext } from "../../../contexts";
import { INVALID_BARCODE_MESSAGE, CAMERA_TIMEOUT_MESSAGE, equipmentStatus } from "../../../constants";
import { getFilteredInstrumentList } from "../../../utils/helpers/fetching";
import { withApollo } from "react-apollo";

export const CreateFieldStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 32px;
  margin-bottom: 20px;
  background: #ffffff;

  .bordered.square {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 355px;
    width: 476px;
  }
  .bordered {
    background-image: linear-gradient(to right, black 10%, transparent 10%),
      linear-gradient(to right, black 10%, transparent 10%), linear-gradient(to bottom, black 10%, transparent 10%),
      linear-gradient(to bottom, black 10%, transparent 10%);
    background-size: 91% 1px, 91% 1px, 1px 91%, 1px 91%;
    background-position: 0% 0%, 0% 100%, 0% 0%, 100% 0%;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  }
`;

const BookingInstrumentScanMainPage = ({ client }) => {
  const { setListMessage, instrumentData, setInstrumentData } = useContext(TogglerContext);
  const [timeOutFlag, setTimeOutFlag] = useState(true);
  const timeOut = setTimeout(() => {
    if (instrumentData === null) {
      setTimeOutFlag(false);
    }
  }, 60000);
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const getEquipmentDetails = async (scanData) => {
    let instrument = false;
    if (scanData.search(" ") > -1) {
      const splitedData = scanData.split(" ");
      instrument = await getInstrumentList(splitedData[1]);
    } else {
      instrument = await getInstrumentList(scanData);
    }
    return instrument ? instrument[0] : null;
  };

  const getInstrumentList = async (equipmentId) => {
    const variables = {
      limit: 1000,
      nextToken: null,
      filter: {
        and: [
          { siteName: { eq: user.site } },
          { isBookable: { eq: true } },
          { status: { eq: equipmentStatus?.active?.key } },
          { equipmentId: { eq: equipmentId } },
        ],
      },
    };
    const allBookingEquipmentData = await getFilteredInstrumentList({
      client,
      user,
      variables,
    });
    return allBookingEquipmentData;
  };

  return (
    <CreateFieldStyled>
      <div className="bordered square">
        {timeOutFlag ? (
          <BarcodeScannerComponent
            className="bordered square"
            width={400}
            height={400}
            delay={"2000"}
            torch={true}
            onUpdate={async (err, result) => {
              if (result) {
                const instrument = await getEquipmentDetails(result.text);
                if (instrument) {
                  setInstrumentData(instrument);
                  clearTimeout(timeOut);
                  history.push(`/equipmentList/${instrument?.inventoryId}`);
                  // history.push("/instrumentDetailedInfo", {
                  //   instrument: {
                  //     ...instrument,
                  //     materialNumber: instrument?.materialNumber
                  //       ? instrument?.materialNumber
                  //       : null,
                  //     serialNumber: instrument?.serialNumber
                  //       ? instrument?.serialNumber
                  //       : null,
                  //   },
                  // });
                } else {
                  setListMessage(INVALID_BARCODE_MESSAGE);
                  history.push("/");
                }
              }
            }}
          />
        ) : (
          (setListMessage(CAMERA_TIMEOUT_MESSAGE), history.push("/"))
        )}
      </div>
    </CreateFieldStyled>
  );
};

export default withApollo(BookingInstrumentScanMainPage);
