import { useEffect, useState } from "react";

const REFETCH_EVERY = 60 * 1000;

const Refetch = ({ refetch }) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const doPeriodicRefetch = () => {
    setTimeoutId(
      setTimeout(() => {
        refetch();
        doPeriodicRefetch();
      }, REFETCH_EVERY)
    );
  };

  useEffect(() => {
    doPeriodicRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return null;
};

export default Refetch;
