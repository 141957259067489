import { UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE } from "../../gql/bookingapi/mutations";

export const saveLastFilter = async (client, user) => {
  await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_BOOKING_USER_PROFILE,
    fetchPolicy: "no-cache",
    variables: {
      id: user.id,
      email: user.email,
      lastFilter: user.lastFilter,
    },
  });
};
