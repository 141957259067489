const IconForApp = (props) => (
  <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 18H42" stroke="#1482FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7 10H41C42.1046 10 43 10.8954 43 12V40C43 41.1046 42.1046 42 41 42H7C5.89543 42 5 41.1046 5 40V12C5 10.8954 5.89543 10 7 10Z"
      stroke="#0B41CD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13 14V6M35 14V6" stroke="#0B41CD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M24 38C28.4183 38 32 34.4183 32 30C32 25.5817 28.4183 22 24 22C19.5817 22 16 25.5817 16 30C16 34.4183 19.5817 38 24 38Z"
      stroke="#1482FA"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M24 27V33M27 30H21" stroke="#1482FA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default IconForApp;
