import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";

export const TOOL_PANELS = {
  columns: "columns",
  filters: "filters",
  saveConfiguration: "save_configuration",
};

export const entryType = {
  cluster: "CLUSTER",
  standaloneEquipment: "STANDALONE-EQUIPMENT",
  clusterSubequipment: "CLUSTER-SUBEQUIPMENT",
};

export const MY_LAB = "My lab";

export const SOP_RT = "SOP RT0020";

export const YES = "Yes";

export const filterKeyMapping = {
  serialNumber: DATA_MODEL_TABLE?.serialNumber?.key,
  materialNumber: DATA_MODEL_TABLE?.materialNumber?.key,
  equipmentId: DATA_MODEL_TABLE?.equipmentId?.key,
  tags: DATA_MODEL_TABLE?.tags?.key,
  linkedInstance: DATA_MODEL_TABLE?.linkedInstance?.key,
  site: DATA_MODEL_TABLE?.siteName?.key,
  group: DATA_MODEL_TABLE?.belongingToGroup?.key,
  manufacturer: DATA_MODEL_TABLE?.manufacturer?.key,
  category: DATA_MODEL_TABLE?.equipmentCategory?.key,
  responsiblePerson: DATA_MODEL_TABLE?.responsiblePerson?.key,
  equipmentAdministrator: DATA_MODEL_TABLE?.equipmentAdministrator?.key,
  dateOfNextMaintanance: DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  dateFrom: "dateFrom",
  dateTo: "dateTo",
  inventoryIds: "_id",
};

export const GXPemptyInstrumentsOptions = {
  csv: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" },
  ],
  electronicRecord: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" },
  ],
  electronicSignatures: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" },
  ],
  gxpRelevant: [
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" },
  ],
  qualificationStatus: [
    { key: "CHANGED_COMPONENT_ONLY", value: "Changed component only" },
    { key: "NA", value: "NA" },
    { key: "NOT_QUALIFIED", value: "Not qualified" },
    { key: "QUALIFIED", value: "Qualified" },
  ],
  controlledEquipmentStatus: [
    { key: "CALIBRATED", value: "Calibrated" },
    { key: "NA", value: "NA" },
    { key: "NOT_CALIBRATED", value: "Not calibrated" },
  ],
  dateOfNextMaintanance: null,
  dateOfLastMaintanance: null,
};
