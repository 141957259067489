import React, { useContext, useEffect, useState } from "react";
import BookingFilter from "./BookingFilter";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import BookingList from "./BookingList";
import DeleteBooking from "../../DeleteBooking";
import MyBookingMultiDeleteDialog from "../../booking-instruments/my-bookings/my-booking-list-view/my-booking-multi-delete-button/MyBookingMultiDeleteDialog";
import BookingNotificationStatus from "../../booking-calendars/booking-notification-status/BookingNotificationStatus";
import { timeoutCallbackFactory } from "../../booking-calendars/booking-notification-status/timeoutCallbackFactory";
import { DURATION } from "../../../constants";
import { snackbarService } from "@one/web-components";
import { debounce } from "lodash";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { getWeekStartAndEndInISOString } from "../../../utils/helpers/text";
import { useSelector } from "react-redux";

const bookingCallbackFactory = ({
  dispatchAction,
  setOpenMultiDeleteDialog,
  setStatus,
  setIsDropBooking,
  setIsDialogOpen,
  setTempRowData,
}) => {
  return debounce((data) => {
    if (data?.data?.onUpdateBookingCommand?.processingResult !== "CHECK_IN_SUCCEEDED") {
      const result =
        data?.data?.onUpdateBookingCommand?.processingResult === "ALL_SUCCEEDED"
          ? {
              type: "success",
              message: "Booking has been cancelled successfully !",
              duration: DURATION,
            }
          : {
              type: "error",
              message: "There were problems with booking, please check detailed statuses.",
              duration: DURATION,
            };
      snackbarService.show(result);
      setStatus(false);
      setOpenMultiDeleteDialog(false);
      setIsDropBooking(false);
      setIsDialogOpen(false);
      setTempRowData([]);
      dispatchAction({
        type: "clearAll",
      });
    }
  }, 2500);
};
function DropBooking({ setSubscriptionStatus, subscriptionStatus, setPopupEnableStatus }) {
  const { trackPageView } = useMatomo();
  const user = useSelector((store) => store.user);
  const [resetAll, setResetAll] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [disableResetAll, setDisableResetAll] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState([
    getWeekStartAndEndInISOString("start", user.timezone),
    getWeekStartAndEndInISOString("end", user.timezone),
  ]);
  const [tempRowData, setTempRowData] = useState([]);
  const [isDropBooking, setIsDropBooking] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [openMultiDeleteDialog, setOpenMultiDeleteDialog] = useState(false);
  const [rowEquipmentId, setRowEquipmentId] = useState(null);
  const { dispatchAction } = useContext(PaginationContext);
  const [status, setStatus] = useState(false);
  useEffect(() => {
    setTempRowData([]);
    trackPageView({
      documentTitle: "Admin Panel/Drop booking",
      href: `${window.location?.origin}/AdminPanel/DropBooking`,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (subscriptionStatus) {
      let result;
      if (subscriptionStatus?.processingResult === "ALL_SUCCEEDED") {
        result = {
          type: "success",
          message: "Booking confirmed successfully!",
          duration: DURATION,
        };
      } else if (
        subscriptionStatus?.processingResult === "SOME_FAILED" ||
        subscriptionStatus?.processingResult === "ALL_FAILED"
      ) {
        result = {
          type: "warning",
          message: `There was an issue with your equipment booking timeslots(s). Please check "My bookings" for more information.`,
          duration: DURATION,
        };
      } else {
        result = {
          type: "error",
          message: "There were problems with bookings, please check detailed statuses.",
          duration: DURATION,
        };
      }
      snackbarService.show(result);
      setSubscriptionStatus(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionStatus]);

  const bookingCallback = bookingCallbackFactory({
    dispatchAction,
    setOpenMultiDeleteDialog,
    setStatus,
    setIsDropBooking,
    setIsDialogOpen,
    setTempRowData,
  });

  return (
    <>
      <div>
        <BookingFilter
          resetAll={resetAll}
          setResetAll={setResetAll}
          setSelectedEquipment={setSelectedEquipment}
          showAdvanceFilter={showAdvanceFilter}
          setShowAdvanceFilter={setShowAdvanceFilter}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          tempRowData={tempRowData}
          setTempRowData={setTempRowData}
          disableResetAll={disableResetAll}
          setDisableResetAll={setDisableResetAll}
        />
        <BookingList
          showAdvanceFilter={showAdvanceFilter}
          selectedDateRange={selectedDateRange}
          setSelectedEquipment={setSelectedEquipment}
          selectedEquipment={selectedEquipment}
          setSelectedDateRange={setSelectedDateRange}
          resetAll={resetAll}
          setIsDropBooking={setIsDropBooking}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setIsDialogOpen={setIsDialogOpen}
          setOpenMultiDeleteDialog={setOpenMultiDeleteDialog}
          setRowEquipmentId={setRowEquipmentId}
          tempRowData={tempRowData}
          setTempRowData={setTempRowData}
          setDisableResetAll={setDisableResetAll}
          setResetAll={setResetAll}
          setPopupEnableStatus={setPopupEnableStatus}
        />
      </div>
      <BookingNotificationStatus
        tryTimes={3}
        timeout={5 * 60 * 1000}
        onTimeoutCallback={timeoutCallbackFactory()}
        onBookingCallback={bookingCallback}
      />

      {isDropBooking && (
        <DeleteBooking
          setIsDropBooking={setIsDropBooking}
          setIsDialogOpen={setIsDialogOpen}
          isDialogOpen={isDialogOpen}
          force={true}
          item={rowEquipmentId}
          status={status}
          setStatus={setStatus}
        />
      )}

      {openMultiDeleteDialog && (
        <MyBookingMultiDeleteDialog
          openMultiDeleteDialog={openMultiDeleteDialog}
          setOpenMultiDeleteDialog={setOpenMultiDeleteDialog}
          force={true}
          selectedEquipment={selectedEquipment}
          status={status}
          setStatus={setStatus}
          setSelectedEquipment={setSelectedEquipment}
        />
      )}
    </>
  );
}

export default DropBooking;
