import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import styles from "./BooleanButtons.module.scss";
import { GRID_VIEW, LIST_VIEW } from "../../constants";

const BooleanButtons = ({ trueLabel, falseLabel, trueProps, falseProps, value, onChange, ...props }) => {
  return (
    <>
      <div className={styles.booleanButton}>
        <button
          onClick={() => onChange(true)}
          style={
            value === LIST_VIEW
              ? {
                  backgroundColor: "var(--one-color-interaction-active-brand-4)",
                  color: "var(--one-color-interaction-focus-brand-1)",
                }
              : {}
          }
        >
          <FormatListBulletedIcon style={{ width: 45 }} />
        </button>
        <div className={styles.verticalLine}></div>
        <button
          onClick={() => onChange(false)}
          style={
            value === GRID_VIEW
              ? {
                  backgroundColor: "var(--one-color-interaction-active-brand-4)",
                  color: "var(--one-color-interaction-focus-brand-1)",
                }
              : {}
          }
        >
          <CalendarTodayIcon style={{ width: 45 }} />
        </button>
      </div>
    </>
  );
};

export default BooleanButtons;
