import React, { useState } from "react";

import SlotDetailCellRenderer from "./SlotDetailCellRenderer";
import { OwcChip, OwcTypography } from "@one/react";
import { valueOrEmpty } from "../../utils/helpers/text";
import DLabGrid from "../DLabGrid/DLabGrid";
import { changeActionTypeToDisplay, isActionType } from "./helpFunctions";

const BookingDetailRenderer = ({ data, node, api }) => {
  const [rowData] = useState(data?.bookingEntries ?? []);
  const rowStyle = { background: "var(--one-color-cobas-accent-gray-100)" };
  const [columnDefs] = useState([
    {
      field: "",
      text: "",
      cellRenderer: "agGroupCellRenderer",
      width: 80,
      maxWidth: 80,
      pinned: "left",
    },
    {
      field: "Model",
      headerName: "Model",
      valueFormatter: (params) => valueOrEmpty(params?.data?.equipment?.equipmentModel, false, "-"),
    },
    {
      field: "SerialNumber",
      headerName: "Serial number",
      valueFormatter: (params) => valueOrEmpty(params?.data?.equipment?.serialNumber, false, "-"),
    },
    {
      field: "Equipment ID",
      headerName: "Equipment ID",
      valueFormatter: (params) => valueOrEmpty(params?.data?.equipment?.equipmentId, false, "-"),
    },
    {
      field: "Nickname",
      headerName: "Nickname",
      valueFormatter: (params) => valueOrEmpty(params?.data?.equipment?.equipmentNickName, false, "-"),
    },
    {
      field: "Location",
      headerName: "Location",
      valueFormatter: (params) => valueOrEmpty(params?.data?.equipment?.location, false, "-"),
    },
    {
      field: "Software version",
      headerName: "Software version",
      valueFormatter: (params) => valueOrEmpty(params?.data?.equipment?.softwareVersion, false, "-"),
    },
    {
      field: "Group",
      headerName: "Group",
      valueFormatter: (params) => valueOrEmpty(params?.data?.equipment?.belongingToGroup, false, "-"),
    },
  ]);

  const defaultColDef = {
    flex: 1,
    resizable: true,
    suppressMenu: true,
  };

  let attr = {
    columnDefs: columnDefs,
    animateRows: true,
    rowExport: false,
    defaultToolPanel: "filters",
    suppressContextMenu: true,
    hiddenByDefault: true,
    rowData: rowData,
  };

  return (
    <div style={{ marginLeft: "100px" }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <OwcTypography variant="caption" style={{ padding: "10px 0" }}>
            Project
          </OwcTypography>
          <OwcTypography variant="subtitle2">{valueOrEmpty(data?.project, false, "-")}</OwcTypography>
        </div>
        {isActionType(data?.type) && (
          <div>
            <OwcTypography variant="caption" style={{ padding: "10px 0" }}>
              Action type
            </OwcTypography>
            <OwcTypography variant="subtitle2">
              {valueOrEmpty(changeActionTypeToDisplay(data?.type), false, "-")}
            </OwcTypography>
          </div>
        )}
      </div>
      <OwcTypography variant="caption" style={{ padding: "10px 0" }}>
        Description
      </OwcTypography>
      <OwcTypography variant="subtitle2">{valueOrEmpty(data?.description, false, "-")}</OwcTypography>

      <OwcTypography variant="caption" style={{ padding: "10px 0" }}>
        Booking for
      </OwcTypography>
      <div style={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
        {data.bookedUsers.map((x) => (
          <OwcChip icon={"user"} iconType="outlined" outlined round>
            {x ? `${x?.givenName ?? ""}  ${x?.familyName ?? ""}  (${x?.name ?? x?.email})` : "-"}
          </OwcChip>
        ))}
      </div>

      <OwcTypography variant="caption" style={{ padding: "10px 0px 10px 0px" }}>
        Equipment details
      </OwcTypography>
      <div className="ag-theme-alpine">
        <div className="sub-table-grid">
          <DLabGrid
            {...attr}
            pagination={false}
            suppressPaginationPanel={false}
            defaultColDef={defaultColDef}
            masterDetail={true}
            detailRowAutoHeight={true}
            detailCellRenderer={SlotDetailCellRenderer}
            autoGroupColumnDef={"autoGroupColumnDef"}
            rowStyle={rowStyle}
            domLayout={"autoHeight"}
            height={"100%"}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingDetailRenderer;
