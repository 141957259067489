import { THEME } from "@digitallab/grid-common-components";
import { OwcListItem, OwcSelectDropdown } from "@one/react";
import React from "react";
import { useSelector } from "react-redux";
import { changeActionTypeToDisplay, getBookingCategory } from "./helpFunctions";

const createCategories = (actionTypes) => [...new Set(actionTypes.map((item) => getBookingCategory(item)))];

export const ActionTypeSelect = ({ formik, name }) => {
  const actionTypes = Object.values(useSelector((store) => store.actionTypes));

  return (
    <OwcSelectDropdown
      style={{ width: "100%" }}
      limit={10}
      required
      name={name}
      label="Action Type*"
      requiredText="Action type is required"
      onSelectChange={async (event) => {
        await setTimeout(() => {}, 0); //Dropdown has some bug that event?.target?.value is passed with delay
        formik.setFieldValue(name, event?.target?.value?.[0]);
      }}
    >
      {createCategories(actionTypes).map((actionCategory) => (
        <React.Fragment key={actionCategory}>
          <OwcListItem
            disabled
            style={{
              color: THEME["one-color-cobas-gray-700"],
              backgroundColor: THEME["one-color-cobas-gray-100"],
              borderBottom: "1px solid",
              borderColor: THEME["one-color-cobas-gray-400"],
              fontSize: THEME["one-text-font-size-px-14"],
            }}
          >
            {actionCategory}
          </OwcListItem>
          {actionTypes
            .filter((action) => getBookingCategory(action) === actionCategory)
            .map((actionType) => (
              <OwcListItem
                size={"dense"}
                key={actionType}
                value={actionType}
                indent="1"
                style={{
                  fontSize: THEME["one-text-font-size-px-12"],
                }}
              >
                {changeActionTypeToDisplay(actionType)}
              </OwcListItem>
            ))}
        </React.Fragment>
      ))}
    </OwcSelectDropdown>
  );
};
