import { stringifyFilter } from "../../../utils/store/lastFilter";
import * as types from "./actionTypes";

export const loadUserInfo = (userInfo) => ({
  type: types.LOAD_USER_INFO,
  payload: userInfo,
});

export const updateFilter = (filterDetails) => ({
  type: types.UPDATE_FILTER,
  payload: stringifyFilter(filterDetails),
});
