export const setCookieData = (key, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  }
  const host = window.location.host;
  const domainPartsArray = host.split(".");
  if (domainPartsArray.length === 1) {
    document.cookie = key + "=" + value + expires + "; path=/";
  } else {
    let domain = domainPartsArray[domainPartsArray.length - 2] + "." + domainPartsArray[domainPartsArray.length - 1];
    document.cookie = key + "=" + value + expires + "; path=/; domain=" + domain;
  }
};
export const getCookieData = (key) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  let cookieArray = decodedCookie.split(";");
  const cookieObjStr = cookieArray.find((cookieStr) => {
    return cookieStr.search(key) !== -1;
  });
  return cookieObjStr ? cookieObjStr?.split("=")[1] : null;
};
export const eraseCookieData = (key) => {
  console.log("erase cookie call");
  setCookieData(key, null, -1);
};
