import React, { useContext } from "react";
import styled from "styled-components";
import ClearAll from "./ClearAll";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { Datepicker, momentTimezone } from "@mobiscroll/react";
import { useSelector } from "react-redux";
import { OwcIcon, OwcIconButton, OwcTooltip, OwcTypography } from "@one/react";
import { SHOW_ADVANCE_FILTER_TOOLTIP } from "../../../constants";
import Search from "./Search";
import moment from "moment-timezone";

// setup Mobiscroll Moment plugin
momentTimezone.moment = moment;
const BookingFilterStyled = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-left: 10px;
  flex-wrap: wrap;
`;

const BookingFilter = ({
  setResetAll,
  showAdvanceFilter,
  setShowAdvanceFilter,
  selectedDateRange,
  setSelectedDateRange,
  searchQuery,
  setSearchQuery,
  setTempRowData,
  disableResetAll,
  setDisableResetAll,
}) => {
  const user = useSelector((state) => state.user);
  const { fetching, dispatchAction } = useContext(PaginationContext);
  const dataTimeZone = "utc";
  const displayTimeZone = user.timezone;
  return (
    <>
      <div style={{ padding: 15 }}>
        <OwcTypography variant="button">All bookings</OwcTypography>
      </div>
      <BookingFilterStyled data-testid="booking-filter-admin">
        <Search
          onChange={(e) => setSearchQuery(e.detail)}
          onClear={() => setSearchQuery("")}
          label="Search all columns "
          placeholder="Search all columns"
          testid={"common-search-drop-booking"}
          fetching={fetching}
          compact={false}
          searchQuery={searchQuery}
        ></Search>

        <>
          <OwcTooltip anchor={"advance-filter"} placement="top">
            {SHOW_ADVANCE_FILTER_TOOLTIP}
          </OwcTooltip>
          <OwcIconButton
            id="advance-filter"
            data-testid="advance-filter-button"
            style={{ marginRight: 8 }}
            icon=" parameter_set"
            type="filled"
            onClick={() => {
              setShowAdvanceFilter((prev) => !prev);
            }}
            flat
          />
        </>
        {showAdvanceFilter && (
          <>
            <div style={{ padding: "0 15px 0 5px" }} className="date-picker-my-booking">
              <Datepicker
                id="datepicker-admin-panel"
                controls={["calendar"]}
                value={selectedDateRange}
                inputStyle="box"
                style={{ height: "5px" }}
                select="range"
                display="anchored"
                inRangeInvalid={true}
                rangeEndInvalid={true}
                startIcon={<OwcIcon name="date" type="outlined" />}
                headerText="Experiment date(range)"
                label={"Date range"}
                touchUi={"auto"}
                dateFormat="D-MMM-YYYY"
                onClose={(param) => {
                  if (param?.value) {
                    dispatchAction({
                      type: "clearAll",
                    });
                    setTempRowData([]);
                    setDisableResetAll(false);
                    setSelectedDateRange(param?.value);
                  }
                }}
                timezonePlugin={momentTimezone}
                dataTimezone={dataTimeZone}
                displayTimezone={displayTimeZone}
                buttons={["cancel", "set"]}
                compact
                exclusiveEndDates={false}
              />
            </div>
            <ClearAll
              onClickCallback={() => {
                setTempRowData([]);
                setResetAll((prev) => !prev);
                setDisableResetAll(true);
              }}
              disabled={disableResetAll && searchQuery === ""}
            />
          </>
        )}
      </BookingFilterStyled>
    </>
  );
};

export default BookingFilter;
