import { useSelector } from "react-redux";
import { withApollo } from "react-apollo";
import { useContext, useState } from "react";
import { DROP_BOOKING } from "../../../../../gql/bookingapi/mutations";
import ConfirmDialog from "../../../../../components/shared/ConfirmDialog";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { PaginationContext } from "../../../../../components/shared/pagination/PaginationContext";

const handleCancelAllFactory = ({
  client,
  user,
  reasonValue,
  force,
  selectedEquipment,
  setStatus,
  dispatchAction,
  setSelectedEquipment,
}) => {
  return async () => {
    try {
      setStatus(true);
      dispatchAction({
        type: "fetching",
        payload: true,
      });
      await Promise.all(
        selectedEquipment.map(async (id) =>
          client.mutate({
            mutation: DROP_BOOKING,
            variables: {
              createdBy: user.user,
              remark: reasonValue,
              force: force,
              bookingId: id,
            },
          })
        )
      );
      setSelectedEquipment([]);
    } catch (e) {
      setStatus(false);
      dispatchAction({
        type: "clearAll",
      });
    }
  };
};

const MyBookingMultiDeleteDialog = ({
  client,
  openMultiDeleteDialog = false,
  setOpenMultiDeleteDialog,
  force = false,
  selectedEquipment,
  status,
  setStatus,
  setSelectedEquipment,
}) => {
  const { dispatchAction } = useContext(PaginationContext);
  const [reasonValue, setReasonValue] = useState(null);

  const user = useSelector((store) => store.user);
  const { trackEvent } = useMatomo();

  const handleCancelAll = handleCancelAllFactory({
    client,
    user,
    reasonValue,
    force,
    selectedEquipment,
    setStatus,
    dispatchAction,
    setSelectedEquipment,
  });

  const handleOnclose = () => {
    setOpenMultiDeleteDialog(false);
    setReasonValue("");
    return true;
  };
  const onReasonChanged = (reason) => {
    setReasonValue(reason);
  };

  const handlerOnDeleteBooking = async () => {
    // Track click on button
    trackEvent({ category: "Delete Bookings", action: "click-event" });
    await handleCancelAll();
    setReasonValue("");
  };

  const handlerOnCancelDeleteBooking = () => {
    setOpenMultiDeleteDialog(false);
    setReasonValue("");
  };

  return (
    <ConfirmDialog
      approveText="Confirm"
      approveColor="error"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      title={`Cancel Booking`}
      content={`Are you sure to cancel bookings?`}
      close={handleOnclose}
      onApprove={async () => handlerOnDeleteBooking()}
      loading={status}
      open={openMultiDeleteDialog}
      onCancel={handlerOnCancelDeleteBooking}
      showReasonDD={true}
      reasonDropdowmLabel={`Please add reason for cancel booking`}
      reasonValue={reasonValue}
      onReasonChanged={onReasonChanged}
      disableBooking={!reasonValue}
      fullWidth={false}
      maxWidth="sm"
    />
  );
};
export default withApollo(MyBookingMultiDeleteDialog);
