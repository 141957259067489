import React, { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import DialogActionsRow from "./DialogActionsRow";
import DialogContentForEditReservation from "./DialogContentForEditReservation";
import { reducer, initalState } from "./modal-reducer";
import { OwcModalDialog } from "@one/react";

const ModalToEditReservation = ({
  item,
  open,
  close,
  onSave,
  onCancel,
  allowEditReservedFor = true,
  loading = false,
}) => {
  const users = useSelector((theState) => theState.users.users);
  const [state, dispatch] = useReducer(reducer, initalState);
  const handleClose = () => {
    dispatch({
      type: "setAll",
      payload: {
        description: item?.description,
        availability: item?.availability,
        project: item?.project,
        reservedForEmail: item?.reservedForEmail,
        reservedForUser: item?.reservedForUser,
      },
    });
    close();
  };

  useEffect(() => {
    dispatch({
      type: "setAll",
      payload: {
        description: item?.description,
        availability: item?.availability,
        project: item?.project,
        reservedForEmail: item?.reservedForEmail,
        reservedForUser: item?.reservedForUser,
      },
    });
  }, [item]);

  const handleChange = (type) => (event) => {
    const value = event.target.value;
    if (value.length && !value.trim()) return;
    dispatch({
      type,
      payload: value,
    });
  };

  return (
    <div>
      <OwcModalDialog visible={open} data-testid="booking-calendar-edit-modal" className="owcmodalZIndex">
        <div slot="header" data-testid="booking-calendar-edit-modal-title">
          Edit booking
        </div>
        <div data-testid="booking-calendar-edit-modal-content">
          <DialogContentForEditReservation
            item={item}
            allowEditReservedFor={allowEditReservedFor}
            state={state}
            users={users}
            handleChange={handleChange}
          />
        </div>
        <div slot="actions">
          <DialogActionsRow
            handleClose={handleClose}
            onCancel={onCancel}
            state={state}
            loading={loading}
            onSave={onSave}
          />
        </div>
      </OwcModalDialog>
    </div>
  );
};

export default ModalToEditReservation;
