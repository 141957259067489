import React from "react";
import { IconStatus } from "../../../constants";
import { OwcIcon, OwcTypography } from "@one/react";
import styles from "../../../components/shared/CustomEventCalendar.module.scss";

const BookingScheduleStatus = () => {
  return (
    <div className={styles.customEventStatus}>
      {IconStatus.map(
        (item) =>
          !["Available", "Booking", "Reservation"].includes(item.text) && (
            <div className={styles.customEventIconText}>
              <OwcIcon
                type="outlined"
                name={item.icon}
                className={styles.customEventStatusIcon}
                style={{
                  backgroundColor: item.backgroundColor,
                  color: item.color,
                }}
              />
              <OwcTypography variant="button">{item.text}</OwcTypography>
            </div>
          )
      )}
    </div>
  );
};

export default BookingScheduleStatus;
