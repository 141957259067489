export const Availability = {
  available: "available",
  reservation: "reservation",
  run: "run",
  booking: "booking",
  maintenance: "maintenance",
  mixed: "mixed",
  selected: "selected",
  install: "install",
  service: "service",
  decommission: "decommission",
  relocation: "relocation",
  other: "other",
};
