import * as yup from "yup";

export const ForWhomMainPageSchema = yup.object().shape({
  bookingType: yup.string("Enter Booking type").required("Booking type is required").nullable(),
  bookingFor: yup
    .array()
    .of(
      yup.object({
        value: yup.string("Enter Booking for").required("Booking for is required"),
      })
    )
    .required("Booking for is required")
    .nullable(),
  project: yup.string("Enter description").typeError("Invalid type").nullable(),
  description: yup.string("Enter description").required("Description is required").typeError("Invalid type").nullable(),
});
