import { OwcIconButton, OwcListItem, OwcMenu } from "@one/react";
import moment from "moment";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

const CoverMenuItem = styled.div`
  height: 0.973480224609375px;
  width: 100%;
  border-radius: 0;
  background: var(--one-color-gray-300);
`;
const BookingMenuAction = ({ params, deleteEquipment, editBooking, setRowEquipmentId, setPopupEnableStatus }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const user = useSelector((store) => store.user);
  const currentTimeString = moment.tz(user.timezone).utc().format();

  const handleSettingClose = () => {
    setAnchorEl(false);
  };
  const handleSettingClick = (event, params) => {
    setRowEquipmentId(params?.data?.id);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <OwcIconButton
        icon="more_vertical"
        type="legacy"
        flat
        onClick={(event) => {
          handleSettingClick(event, params);
        }}
        id={params?.data?.id}
      />
      {anchorEl &&
        ReactDOM.createPortal(
          <OwcMenu
            data-testid="simple-menu"
            anchor={params?.data?.id}
            visible={anchorEl}
            onClickOutside={handleSettingClose}
            disablePortal={true}
            disabled={!params?.data?.active}
          >
            <OwcListItem
              data-testid="simple-menu-filter"
              onClick={() => {
                setAnchorEl(null);
                setPopupEnableStatus(true);
                editBooking(params?.data);
              }}
              disabled={
                !params?.data?.active ||
                params?.data?.checkInDone ||
                (params?.data?.checkInRequired && params.data.checkInStartsAt <= new Date().getTime() / 1000) ||
                params?.data?.dateTo < currentTimeString
              }
            >
              Edit
            </OwcListItem>
            <CoverMenuItem />
            <OwcListItem
              data-testid="simple-menu-filter"
              onClick={() => {
                setAnchorEl(null);
                deleteEquipment({
                  id: params?.data?.id,
                });
              }}
              disabled={
                !params?.data?.active ||
                params?.data?.dateFrom < currentTimeString ||
                params?.data?.dateTo < currentTimeString
              }
            >
              Cancel
            </OwcListItem>
            <CoverMenuItem />
          </OwcMenu>,
          document.body
        )}
    </>
  );
};
export default BookingMenuAction;
