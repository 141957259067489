import { useState } from "react";
import { useEffect } from "react";
import { withApollo } from "react-apollo";
import styled from "styled-components";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { getClusterDetailsById } from "../../../../utils/helpers/fetching";
import { replaceNullWithHyphen, getEnumValue, subEquipmentSorting } from "../../../../utils/helpers/text";
import { GXP_DROP_CHECK, entryType } from "../../../../constants";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { OwcIconButton, OwcTypography } from "@one/react";

const TableStyled = styled.div`
  margin-bottom: 15px;
  max-height: 180px;
  overflow: scroll;
  ::-webkit-scrollbar {
    background: transparent !important;
  }
  & th {
    padding: 5px;
    border-bottom: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
  }

  & tr > td {
    padding: 5px;
    border-bottom: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
    &:first-child {
      width: 20px;
    }
  }

  tbody {
    font-size: 14px;
  }

  thead,
  tbody tr {
    margin: 5px 0;
  }
`;

const TabularColumnForDetailInfo = ({ equipment, client }) => {
  const [subEquipment, setSubEquipment] = useState([]);
  let [isClusterFlag, setIsClusterFlag] = useState(false);
  const column = ["equipmentModel", "variant", "equipmentNickName", "qualificationStatus", "softwareVersion"];
  useEffect(() => {
    getClusterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getClusterData = async () => {
    const result = await getClusterDetailsById({
      client,
      clusterId: equipment?.inventoryId,
    });

    if (result.length > 0) {
      setSubEquipment(subEquipmentSorting(result));
      result.map((item) => {
        if (item?.entryType === entryType?.cluster) {
          setIsClusterFlag(true);
        }
        return column.map((x) => item[x]);
      });
    }
  };

  const replaceNullWithValue = (field, value) => {
    if (GXP_DROP_CHECK.includes(field)) {
      return getEnumValue(field, value);
    }
    return replaceNullWithHyphen(value);
  };

  return (
    <div>
      <OwcTypography variant="subtitle1" data-testid="detailed-info-for-instrument-info-section-details-info-title">
        Cluster components
      </OwcTypography>
      <TableStyled>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {isClusterFlag && <TableCell> </TableCell>}
              {column.map((columnInfo) => (
                <Tooltip title={DATA_MODEL_TABLE[columnInfo].value}>
                  <TableCell>{DATA_MODEL_TABLE[columnInfo].value}</TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {subEquipment.map((item, index) => (
              <TableRow>
                <TableCell>{index + 1}</TableCell>
                {item?.entryType === entryType?.cluster ? (
                  <TableCell>
                    <OwcIconButton type="legacy" icon="rack" flat />
                  </TableCell>
                ) : isClusterFlag ? (
                  <TableCell></TableCell>
                ) : (
                  <></>
                )}
                {column.map((columnInfo) => (
                  <Tooltip title={replaceNullWithValue(columnInfo, item[columnInfo])}>
                    <TableCell>{replaceNullWithValue(columnInfo, item[columnInfo])}</TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableStyled>
    </div>
  );
};

export default withApollo(TabularColumnForDetailInfo);
