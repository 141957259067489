import React from "react";
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import locale from "date-fns/locale/en-US";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { makeStyles } from "@material-ui/core";
import { RocheInputAdornment } from "@one/react-kit";
import { IconButton } from "@mui/material";

const HoverStyle = {
  "&:hover": {
    cursor: "pointer",
  },
};

const useStyles = makeStyles(() => ({
  root: {
    padding: "5px 8px 6px 8px",
    border: "1px solid #D3D3D3",
    borderRadius: 4,
    background: "#FFFFFF",
    marginRight: 12,
    ...HoverStyle,
  },
  underline: {
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    ...HoverStyle,
  },
  icon: {
    marginTop: -16,
    color: "#737373",
  },
  input: {
    color: "#333333",
    padding: 0,
    paddingLeft: 8,
    fontSize: 16,
    lineHeight: "19px",
    width: "auto",
    ...HoverStyle,
  },
  inputLabel: {
    color: "#737373",
    fontSize: 16,
    lineHeight: "19px",
    marginLeft: 41,
    top: 5,
    ...HoverStyle,
  },
}));

function DatePicker({
  date,
  setDate,
  clearable = false,
  label = "Date",
  testId = "date-picker-for-range-in-calendar",
  ...rest
}) {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={{
        ...locale,
        options: {
          ...locale.options,
          weekStartsOn: 1,
        },
      }}
    >
      <MuiDatePicker
        classes={{ root: classes.root }}
        label={label}
        value={date}
        onChange={setDate}
        autoOk
        variant="inline"
        disableToolbar={true}
        data-testid={testId}
        id={testId}
        format="dd-MMM-yyyy"
        InputLabelProps={{
          classes: { root: classes.inputLabel },
        }}
        InputProps={{
          startAdornment: <DateRangeIcon classes={{ root: classes.icon }} />,
          classes: { input: classes.input, underline: classes.underline },
          endAdornment:
            clearable && date ? (
              <RocheInputAdornment style={{ marginTop: "-1em" }}>
                <IconButton
                  data-testid="clear-search-button"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setDate(null);
                  }}
                >
                  <i className="one-icons">clear</i>
                </IconButton>
              </RocheInputAdornment>
            ) : null,
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
