import * as yup from "yup";

export const groupValidationSchema = yup.object({
  equipment: yup
    .string("Enter serial no or equipment ID")
    .required("Enter serial no or equipment ID")
    .typeError("Invalid type")
    .nullable(),
  group: yup.string("Enter group").nullable().required("Group is required"),
});
