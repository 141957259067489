import { SvgIcon } from "@mui/material";

const NotificationEditIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M17.58 6.25L19.35 8.02L14.37 13H12.6V11.23L17.58 6.25ZM20.85 5.81L19.79 4.75C19.59 4.55 19.28 4.55 19.08 4.75L18.23 5.6L20 7.37L20.85 6.52C21.05 6.32 21.05 6 20.85 5.81ZM18 12.2V17H20V19H4V17H6V10C6 7.21 7.91 4.86 10.5 4.2V3.5C10.5 2.67 11.17 2 12 2C12.83 2 13.5 2.67 13.5 3.5V4.2C14.32 4.41 15.07 4.79 15.71 5.29L14.28 6.72C13.6144 6.24659 12.8167 5.99469 12 6C9.79 6 8 7.79 8 10V17H16V14.2L18 12.2ZM10 20H14C14 21.1 13.1 22 12 22C10.9 22 10 21.1 10 20Z"
      fill="#0066CC"
    />
  </SvgIcon>
);

export default NotificationEditIcon;
