import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { useContext } from "react";
import { connect, useSelector } from "react-redux";
import { reservationsToEvents } from "./helpers";
import { compareInstrument } from "../../../booking-instruments/booking-instruments-selected-context/BookingInstrumentsSelectedWrapper";
import { compose, withApollo } from "react-apollo";
import { loadReservations as loadReservationsAction } from "../../../booking-calendars/redux/actions";
import CalendarEventComponent from "./CalendarEventComponent";
import ReservationPopoverWrapper from "../../../booking-calendars/reservation-popover/ReservationPopoverWrapper";
import { useFilteredReservations } from "../../../../utils/hooks/useFilteredReservations";
import { useUpdateBookingData } from "../../../../utils/hooks/useUpdateBookingData";
import { CalendarTypeContext } from "../../../booking-calendars/calendar-type-context/context";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  getDay,
  locales,
  format,
  parse,
  startOfWeek: (date, options) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
});

const CalendarForDetailedInfo = ({ instrument, client, loadReservations }) => {
  const reservations = useSelector((state) =>
    state.reservations.reservations.filter((reservation) => compareInstrument(instrument, reservation))
  );

  const { date } = useContext(CalendarTypeContext);
  const filters = { active: true, inventoryId: instrument.inventoryId };
  useFilteredReservations(client, loadReservations, filters);
  useUpdateBookingData();

  return (
    <ReservationPopoverWrapper>
      <Calendar
        popup
        className="roche-calendar"
        date={date}
        localizer={localizer}
        events={reservationsToEvents(reservations)}
        view={"month"}
        startAccessor="start"
        endAccessor="end"
        toolbar={false}
        formats={{
          weekdayFormat: "E",
        }}
        components={{
          event: ({ event }) => <CalendarEventComponent event={event} instrument={instrument} />,
        }}
        eventPropGetter={(event) => {
          return {
            className: `availability-${event.resource?.availability}`,
          };
        }}
      />
    </ReservationPopoverWrapper>
  );
};

export default compose(
  connect(null, { loadReservations: loadReservationsAction }),
  withApollo
)(CalendarForDetailedInfo);
