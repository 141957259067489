import React from "react";
import styled from "styled-components";
const CheckInIconWrapper = styled.div`
  width: 21px;
  height: 20px;
  position: relative;
`;
/**
 * Represents the CheckinIcon component.
 * @param {Object} props - The props object containing the color property.
 * @returns {JSX.Element} - The rendered CheckinIcon component.
 */
export const CheckinIcon = (props) => {
  const { color } = props;
  return (
    <CheckInIconWrapper>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 5H9.5L9.5 11L13.5 13.5L14.25 12.27L11 10.25L11 5Z" fill={color} />
        <path
          d="M10.49 0C4.97 0 0.5 4.48 0.5 10C0.5 15.52 4.97 20 10.49 20L10.5 18C6.08 18 2.5 14.42 2.5 10C2.5 5.58 6.08 2 10.5 2C14.92 2 18.5 5.58 18.5 10C18.5 10.6907 18.4126 11.3608 18.2482 12L20.1845 12.5C20.3905 11.7009 20.5 10.8631 20.5 10C20.5 4.48 16.02 0 10.49 0Z"
          fill={color}
        />
      </svg>
      <svg
        style={{
          position: "absolute",
          top: "11px",
          left: "9px",
        }}
        width="13"
        height="11"
        viewBox="0 0 13 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.5 4L5.5 8L3.5 6" fill={color} strokeWidth="1.5" strokeLinecap="square" />
      </svg>
    </CheckInIconWrapper>
  );
};
