import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import Search from "../../../../components/shared/Search";
import { AssignGroupContext } from "./context";
import { useFormikContext } from "formik";
import { PaginationContext } from "../../../../components/shared/pagination/PaginationContext";
import { OwcButton } from "@one/react";

export const TopBarStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopBar = ({ setSearchGroup }) => {
  const { setDialogOpen, setIsEditMode } = useContext(AssignGroupContext);
  const { dispatchAction } = useContext(PaginationContext);
  const [searchQuery, setSearchQuery] = useState("");
  const formik = useFormikContext();
  useEffect(() => {
    dispatchAction({
      type: "clearAll",
    });
    setSearchGroup(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <>
      <TopBarStyle>
        <Search setQuery={setSearchQuery} label={"Search for booking group "} />
        <OwcButton
          variant="primary"
          key="create-reason-button"
          onClick={() => {
            formik.resetForm();
            setIsEditMode(false);
            setDialogOpen(true);
          }}
        >
          Assign group
        </OwcButton>
      </TopBarStyle>
    </>
  );
};

export default compose(connect(null), withApollo)(TopBar);
