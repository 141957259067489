import styled from "styled-components";
import { entryType } from "../../../../constants";
import DetailsColumnForDetailedColumn from "./DetailsColumnForDetailedColumn";
import MainInfoColumnForDetailedInfo from "./MainInfoColumnForDetailedInfo";
import TabularColumnForDetailInfo from "./TabularColumnForDetailInfo";

const InfoColumnForDetailedInfoStyled = styled.div`
  padding-right: 20px;
`;

const InfoColumnForDetailedInfo = ({ instrument }) => {
  return (
    <InfoColumnForDetailedInfoStyled data-testid="detailed-info-for-instrument-info-section">
      <MainInfoColumnForDetailedInfo
        serialNumber={instrument?.serialNumber}
        materialNumber={instrument?.materialNumber}
        instrumentStatus={instrument.addInfo?.instrumentStatus}
        lastMaintanace={instrument?.dateOfLastMaintanance}
        nextMaintanace={instrument?.dateOfNextMaintanance}
        displayImage={instrument?.displayImage}
        shortIdentifier={instrument?.shortIdentifier}
        equipmentModel={instrument?.equipmentModel}
      />
      {instrument?.entryType === entryType?.cluster && <TabularColumnForDetailInfo equipment={instrument} />}
      <DetailsColumnForDetailedColumn equipment={instrument} />
    </InfoColumnForDetailedInfoStyled>
  );
};
export default InfoColumnForDetailedInfo;
