import React, { useContext } from "react";
import { TabsForDetailedInfoContext } from "./tabs-for-detailed-info-context/context";
import DATA_LABEL_TABLE from "../../utils/constants/dataLabelTable";
import { OwcTab, OwcTabs } from "@one/react";

const TabForDetailedInfo = () => {
  const { tabView, setTabView } = useContext(TabsForDetailedInfoContext);
  const handleChange = (e) => {
    setTabView(e.detail);
  };

  return (
    <OwcTabs value={tabView} onTabChange={handleChange} style={{ width: "100%" }}>
      <OwcTab
        data-testid="detailed-info-for-instrument-tabs-overview-tab"
        value={"overView"}
        label={DATA_LABEL_TABLE.EQUIPMENT_LIST_PAGE.overview_tab_label}
      >
        {DATA_LABEL_TABLE.EQUIPMENT_LIST_PAGE.overview_tab_label}
      </OwcTab>
      <OwcTab
        data-testid="detailed-info-for-instrument-tabs-installed-tests-tab"
        value={"installedTests"}
        label={DATA_LABEL_TABLE.EQUIPMENT_LIST_PAGE.installed_tests_label}
      >
        {DATA_LABEL_TABLE.EQUIPMENT_LIST_PAGE.installed_tests_label}
      </OwcTab>
      <OwcTab
        data-testid="detailed-info-for-instrument-tabs-linked-documents-tab"
        value={"linkedDocuments"}
        label={DATA_LABEL_TABLE.EQUIPMENT_LIST_PAGE.linked_documents_label}
      >
        {DATA_LABEL_TABLE.EQUIPMENT_LIST_PAGE.linked_documents_label}
      </OwcTab>
    </OwcTabs>
  );
};
export default TabForDetailedInfo;
