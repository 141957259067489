import { format } from "date-fns";
import moment from "moment";
import * as R from "ramda";
import { CALENDAR_AFTERNOON_START_HOUR } from "../../../constants";

const getByDay = R.curry((day, data) =>
  data.filter((r) => {
    if (r.dateFrom instanceof Date) {
      return format(r.dateFrom, "yyyy-MM-dd") === day;
    }
    return r.dateFrom.substring(0, r.dateFrom.indexOf("T")) === day;
  })
);

export const getReservations = (filtered) => {
  const halfDayInHour = CALENDAR_AFTERNOON_START_HOUR;
  return filtered.reduce(
    (acc, curr) => {
      // const startHour = new Date(curr?.dateFrom).getHours();
      // const endHour = new Date(curr?.dateTo).getHours();
      const startHour = getHours(curr?.dateFrom);
      const endHour = getHours(curr?.dateTo);
      if (startHour < halfDayInHour && endHour > halfDayInHour)
        return {
          morning: [...acc.morning, { ...curr, morning: true }],
          afternoon: [...acc.afternoon, { ...curr, afternoon: true }],
        };
      if (startHour < halfDayInHour)
        return {
          ...acc,
          morning: [...acc.morning, { ...curr, morning: true }],
        };
      return {
        ...acc,
        afternoon: [...acc.afternoon, { ...curr, afternoon: true }],
      };
    },
    { morning: [], afternoon: [] }
  );
};

export const getReservationsPerDay = (day, reservations) => {
  return R.compose(getReservations, getByDay(day))(reservations);
};

export const allInRange = (reservations, dateFrom, dateTo) => {
  const result = reservations
    .sort((a, b) => a.dateFrom.localeCompare(b.dateFrom))
    .reduce(
      (acc, reservation) => {
        if (acc.finished) {
          return acc;
        }
        if (acc.dateFrom >= acc.dateTo) {
          return {
            ...acc,
            finished: true,
          };
        }
        if (reservation.dateFrom <= acc.dateFrom) {
          return {
            hasGaps: acc.hasGaps,
            dateFrom: reservation.dateTo,
            dateTo: acc.dateTo,
            finished: reservation.dateTo >= acc.dateTo,
          };
        }
        return {
          ...acc,
          hasGaps: true,
          dateFrom: reservation.dateTo,
        };
      },
      {
        hasGaps: false,
        finished: false,
        dateFrom,
        dateTo,
      }
    );

  return result.finished && !result.hasGaps;
};

export const getDays = (startWeek = moment().startOf("week"), endWeek = moment().endOf("week")) => {
  const days = [];
  for (let m = moment(startWeek); m.isBefore(endWeek); m.add(1, "days")) {
    days.push(m.format("YYYY-MM-DD"));
  }
  return days;
};

export const getHours = (inputISO) => {
  let timeVal = inputISO.split("T");
  let time = timeVal[1].split(":");
  return time[0];
};
