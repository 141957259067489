import * as types from "./actionTypes";

export const loadReservations = (reservations) => ({
  type: types.LOAD_RESERVATIONS,
  payload: reservations,
});

export const removeReservation = (reservation) => ({
  type: types.REMOVE_RESERVATION,
  payload: reservation,
});

export const loadPendingReservations = (reservations) => ({
  type: types.LOAD_PENDING_RESERVATIONS,
  payload: reservations,
});

export const addPendingReservation = (reservation) => ({
  type: types.ADD_PENDING_RESERVATION,
  payload: reservation,
});

export const removePendingReservation = (reservation) => ({
  type: types.REMOVE_PENDING_RESERVATION,
  payload: reservation,
});

export const updatePendingReservation = (reservation) => ({
  type: types.UPDATE_PENDING_RESERVATION,
  payload: reservation,
});

export const updateReservation = (reservation) => ({
  type: types.UPDATE_RESERVATION,
  payload: reservation,
});

export const bulkUpdatePendingReservations = ({ remove, add, update }) => ({
  type: types.BULK_UPDATE_PENDING_RESERVATIONS,
  payload: { remove, add, update },
});

export const replacePendingReservationsForInstrument = ({ instrument, reservations }) => ({
  type: types.REPLACE_PENDING_RESERVATIONS_FOR_INSTRUMENT,
  payload: { instrument, reservations },
});
