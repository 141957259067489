import React, { useState, useCallback } from "react";
import { TogglerContext } from "../../contexts";

const TogglerContextWrapper = ({ children, initialState }) => {
  const [open, setOpen] = useState(initialState);
  const [listMessage, setListMessage] = useState(null);
  const [instrumentData, setInstrumentData] = useState(null);
  const [isMini, setIsMini] = useState(false);
  const [leaveStatus, setLeaveStatus] = useState(false);

  const toggle = useCallback(() => {
    setOpen((val) => !val);
  }, []);
  return (
    <TogglerContext.Provider
      value={{
        open,
        toggle,
        listMessage,
        setListMessage,
        instrumentData,
        setInstrumentData,
        isMini,
        setIsMini,
        leaveStatus,
        setLeaveStatus,
      }}
    >
      {children}
    </TogglerContext.Provider>
  );
};

export default TogglerContextWrapper;
