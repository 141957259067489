import React, { useCallback, useMemo, useRef } from "react";
import DLabGrid from "../../DLabGrid/DLabGrid";
// import SlotDetailCellRenderer from "./SlotDetailCellRenderer";
import { OwcTypography } from "@one/react";
import { convertCloudToGraphQLObj, valueOrEmpty } from "../../../utils/helpers/text";
import { CloudSearchConfig } from "../CloudSearchConfig";
import { useSelector } from "react-redux";
import { ENTRY_TYPE, POSITION_IN_CLUSTER, entryType, PopOverTableMeta, equipmentStatus } from "../../../constants";

const PopOverClusterAgGridTable = ({ data }) => {
  const user = useSelector((store) => store.user);
  const gridRef = useRef();
  const rowStyle = {
    background: "var(--one-color-cobas-accent-gray-100)",
  };
  const defaultColDef = {
    flex: 1,
    resizable: true,
    suppressMenu: true,
  };
  const isServerSideGroup = useCallback((dataItem) => {
    return dataItem.entryType === ENTRY_TYPE.cluster ? dataItem.id : "";
  }, []);

  const getServerSideGroupKey = useCallback((dataItem) => {
    return dataItem.entryType === ENTRY_TYPE.cluster ? dataItem.id : "";
  }, []);

  const dLabColumnDef = useMemo(() => {
    let defaultObj;
    let colDef = [];

    for (const key in PopOverTableMeta.fields) {
      defaultObj = {
        field: key,
        headerName: PopOverTableMeta.fields[key].text,
        valueFormatter: (params) => valueOrEmpty(params?.data[key], false, "-"),
      };

      colDef.push(defaultObj);
    }
    return colDef;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PopOverTableMeta.fields]);

  let attr = {
    rowModelType: "serverSide",
    rowQuickSearch: true,
    treeData: true,
    columnDefs: dLabColumnDef,
    animateRows: true,
    rowExport: false,
    defaultToolPanel: "filters",
    suppressContextMenu: true,
    hiddenByDefault: true,
    isServerSideGroup,
    getServerSideGroupKey,
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Model",
      field: "equipmentModel",
      pinned: "left",
      lockPinned: true,
      cellStyle: (params) => {
        return params?.node?.level > 0 &&
          (params?.data?.entry_type === entryType?.cluster ||
            params?.data?.entry_type === entryType?.clusterSubequipment)
          ? {
              borderBottom: "1px solid #d3d3d3 !important",
              backgroundColor: "#F5F5F2",
              backgroundImage: "conic-gradient(at 7% 90%, #FFFFFF 180deg, #F5F5F2 180deg)",
            }
          : {
              marginLeft: "none !important",
            };
      },
      cellRendererParams: {
        innerRenderer: (params) => {
          return valueOrEmpty(params?.data?.equipmentModel, false, "-");
        },
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServerSideDatasource = () => {
    return {
      getRows: async (params) => {
        const { startRow, groupKeys } = params.request;
        let activeSiteName = user?.site;
        let clusterId = groupKeys.length > 0 ? groupKeys[params.parentNode.level] : data[0].id;
        // let filterQueryString = ` (or site_name:'${user.siteName}') (or status:'${equipmentStatus?.active?.value}') (or entry_type:'${ENTRY_TYPE.cluster}' entry_type:'${ENTRY_TYPE.standaloneEquipment}')  `;
        let searchValueString = ` (cluster_id:'${clusterId}') AND (site_name:'${activeSiteName}')`;

        let filterQueryString = ` (and (or status:'${equipmentStatus?.active?.value}' status:'${equipmentStatus?.pending?.value}'  status:'${equipmentStatus?.deleted?.value}'))`;

        let options = {
          start: startRow,
          size: 100,
          sort: "asc",
          sortBy: POSITION_IN_CLUSTER,
          searchValue: searchValueString,
          filterQuery: filterQueryString,
        };

        const result = await CloudSearchConfig(options);

        const modifiedData = result.data.map((x) => convertCloudToGraphQLObj(x));
        params.success({
          rowData: modifiedData,
          rowCount: result.count,
        });
      },
    };
  };
  const onGridReady = useCallback(async (params) => {
    const datasource = getServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <OwcTypography variant="button" style={{ marginBottom: 16 }}>
        Sub components
      </OwcTypography>

      <div className="ag-theme-alpine">
        <div className="sub-components-row">
          <div className={"sub-table-grid"}>
            <DLabGrid
              {...attr}
              pagination={false}
              suppressPaginationPanel={false}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              rowStyle={rowStyle}
              gridRef={gridRef}
              onGridReady={onGridReady}
              detailRowAutoHeight={true}
              domLayout="autoHeight"
              height={"100%"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopOverClusterAgGridTable;
