import { OwcChip } from "@one/react";

const CustomChip = ({ text, color, bgColor, bold }) => {
  return (
    <OwcChip
      round
      style={{
        color: color,
        backgroundColor: bgColor,
        fontWeight: (bold ? "bold" : "normal")
      }}
    >
      {text}
    </OwcChip>
  );
};
export default CustomChip;
