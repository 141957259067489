import React, { useReducer } from "react";
import { PaginationContextReason } from "./PaginationContextReason";

const handleSetLimit = (state, action) => {
  return {
    ...initialState,
    sortDirection: state.sortDirection,
    orderBy: state.orderBy,
    limit: action.payload,
  };
};

const handleClearAll = (state) => {
  return {
    ...{
      ...initialState,
      limit: state.limit,
      sortDirection: state.sortDirection,
    },
    nextData: state.nextData + 1,
  };
};

const handleRefresh = (state) => {
  return {
    ...state,
    refreshData: state.refreshData + 1,
  };
};

const handleSortDirection = (state, action) => {
  return {
    ...{ ...initialState, limit: state.limit },
    orderBy: action.payload.orderBy,
    sortDirection: action.payload.sortDirection ? "ASC" : "DESC",
    nextData: state.nextData + 1,
  };
};

const handleNextToken = (state, action) => {
  return {
    ...state,
    fetching: false,
    data: action.payload?.data ?? [],
    nextToken: action.payload.token ?? null,
  };
};

const handlePrevData = (state) => {
  return {
    ...state,
    prevTokens: state.prevTokens.slice(0, -1),
    nextToken: state.prevTokens.slice(0, -1).slice(-1)[0] ?? null,
    nextData: state.nextData - 1,
  };
};

const handleFetching = (state, action) => {
  return {
    ...state,
    fetching: action.payload,
  };
};

const handleNextData = (state, action) => {
  return {
    ...state,
    prevTokens: state.nextToken ? [...state.prevTokens, state.nextToken] : state.prevTokens,
    nextToken: action?.payload ? null : state.nextToken,
    ...(action?.payload
      ? {
          ...initialState,
          limit: state.limit,
          sortDirection: state.sortDirection,
        }
      : {}),
    nextData: state.nextData + 1,
  };
};

const handleRefreshToken = (state, action) => {
  return {
    ...state,
    nextToken: action.payload.nextToken,
    prevTokens: action.payload.prevTokens,
  };
};

export const initialState = {
  fetching: false,
  prevTokens: [],
  nextToken: null,
  nextData: 0,
  limit: 20,
  data: [],
  sortDirection: "DESC",
  orderBy: "name",
  refreshData: 0,
};

export function reducer(state = initialState, action = { type: "", payload: "" }) {
  switch (action.type) {
    case "fetching":
      return handleFetching(state, action);
    case "nextData":
      return handleNextData(state, action);
    case "prevData":
      return handlePrevData(state);
    case "nextToken":
      return handleNextToken(state, action);
    case "sortDirection":
      return handleSortDirection(state, action);
    case "refresh":
      return handleRefresh(state);
    case "refreshToken":
      return handleRefreshToken(state, action);
    case "clearAll":
      return handleClearAll(state);
    case "setLimit":
      return handleSetLimit(state, action);
    default:
      return state;
  }
}

const PaginationWrapperReason = ({ children, initialValues = {} }) => {
  const [state, dispatchAction] = useReducer(reducer, {
    ...initialState,
    ...initialValues,
  });
  return (
    <PaginationContextReason.Provider
      value={{
        ...state,
        dispatchAction,
      }}
    >
      {children}
    </PaginationContextReason.Provider>
  );
};

export default PaginationWrapperReason;
